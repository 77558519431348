const Desc = `documentOperations: Change text content to Hello. ${new Error('To get file path').stack.split('\n')[1].match(/Object(.+)\(https/)[1] || ''}`

const Payload = {
    inputs: [
        {
            href: 'https://skycity-asset.adobedice.com/testing/pits/input/pegasus_pattern.psd',
            storage: 'external'
        }
    ],
    options: {
        layers: [
            {
                edit: {},
                id: 2,
                text: { content: 'Hello' }
            }
        ]
    },
    outputs: [
        {
            storage: 'adobe',
            type: 'image/png',
            overwrite: true,
            width: 500,
            compression: 'small'
        }
    ]
}

module.exports = { Payload, Desc }
