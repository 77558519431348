import React, { useState } from 'react'
import Progress from '@react/react-spectrum/Progress'

export default function SkycityProgress ({
    caption,
    upprogress
}) {
    const [wait] = useState(true)
    return (
        <React.Fragment>
            {wait &&
          <div className="adobe-skycity-input-files-progress">
              <Progress
                  label={caption}
                  showPercent
                  labelPosition="top"
                  value={parseInt(upprogress)} />
          </div>}
        </React.Fragment>
    )
}
