import React from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'

import { error } from '@react/react-spectrum/Toast'
import Button from '@react/react-spectrum/Button'

import gitIcon from '../../asset/git.png'

import FeatureNav from '../Shared/FeatureNav'

import {
    goToUrlTarget
} from '../../lib/skycity.js'

export default function Main () {
    try {
        return <div className="adobe-skycity-main-pane">
            <div className="adobe-skycity-main">
                <div className="adobe-skycity-main-column">
                    <div className="adobe-skycity-main-feature">
                        <FeatureNav />
                        <div className="adobe-skycity-center-middle adobe-skycity-flex-column">
                            <div className="adobe-skycity-content-padding"><iframe title="pssmartobject" className="adobe-skycity-youtube-size"
                                src="https://www.youtube.com/embed/vOvyOM6ka98"
                                frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                            <div className="adobe-skycity-content-padding">
                                <Button
                                    onClick={() => { goToUrlTarget(process.env.REACT_APP_CONFIG_URL_EASYBAKE_REFLOW, '_blank') }}
                                    variant="cta">See Demo</Button>
                            </div>
                            <div className="adobe-skycity-content-padding">
                                <Button
                                    variant="tool"
                                    icon={<img src={gitIcon} width="50px" alt="tool"/>}
                                    onClick={() => { goToUrlTarget(__('git_url_reflow'), '_blank') }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
