const Desc = `imagecutout: cc JPEG, soft. ${new Error('To get file path').stack.split('\n')[1].match(/Object(.+)\(https/)[1] || ''}`

const Payload = {
    input: {
        storage: 'adobe',
        href: 'files/_skycity_smoketest/dancer.jpg'
    },
    output: {
        storage: 'adobe',
        type: 'image/png',
        overwrite: true,
        width: 300,
        compression: 'large',
        mask: {
            format: 'soft'
        }
    }
}

module.exports = { Payload, Desc }
