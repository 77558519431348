const Desc = `smartObjectV2: replace nested smart object layer, should be scaled to fit the document size. ${new Error('To get file path').stack.split('\n')[1].match(/Object(.+)\(https/)[1] || ''}`

const Payload = {
    inputs: [
        {
            href: 'files/_skycity_smoketest/nested_scale.psd',
            storage: 'adobe'
        }
    ],
    options: {
        layers: [
            {
                name: 'SmartObject1',
                smartObject: {
                    layers: [
                        {
                            name: 'SmartObject2',
                            input: {
                                href: 'https://as1.ftcdn.net/jpg/03/47/64/14/500_F_347641428_ndoV1rlUqcIbmfOa7d1qznnP9GLbi8pL.jpg',
                                storage: 'external'
                            }
                        }
                    ]
                }
            }
        ]
    },
    outputs: [
        {
            storage: 'adobe',
            type: 'image/png',
            overwrite: true,
            width: 300,
            compression: 'large'
        }
    ]
}

module.exports = { Payload, Desc }
