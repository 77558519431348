const Desc = `smartObjectV2: try to replace ai, should do nothing. ${new Error('To get file path').stack.split('\n')[1].match(/Object(.+)\(https/)[1] || ''}`

const Payload = {
    inputs: [
        {
            href: 'files/_skycity_smoketest/4so.psd',
            storage: 'adobe'
        }
    ],
    options: {
        layers: [
            {
                id: 2,
                input: {
                    href: 'files/_skycity_smoketest/Cutting2.ai',
                    storage: 'adobe'
                }
            }
        ]
    },
    outputs: [
        {
            storage: 'adobe',
            type: 'image/jpeg',
            overwrite: true,
            width: 0,
            quality: 7
        }
    ]
}

module.exports = { Payload, Desc }
