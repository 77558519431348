import React from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'

import { error } from '@react/react-spectrum/Toast'

import '../../style/Home.css'
import imgSampleImagecutoutOriginal from '../../asset/samples/imagecutout/imagecutout_original.jpg'
import imgSampleImagecutoutBg from '../../asset/samples/imagecutout/imagecutout_addbackgroundcolor.jpg'
import imgSampleImagecutoutTp from '../../asset/samples/imagecutout/imagecutout_removebackground.png'
import imgSampleAutotoneBefore from '../../asset/samples/autotone/autotone_before.png'
import imgSampleAutotoneAfter from '../../asset/samples/autotone/autotone_after.png'
import imgSamplePresetBefore from '../../asset/samples/preset/preset_original.jpg'
import imgSamplePresetProcess from '../../asset/samples/preset/preset_cross_process.jpg'
import imgSamplePresetAged from '../../asset/samples/preset/preset_aged_photo.jpg'
import imgSamplePresetMatte from '../../asset/samples/preset/preset_matte.jpg'
import imgSampleSoOriginal from '../../asset/samples/smartobject/smartobject_original.png'
import imgSampleSoCircles from '../../asset/samples/smartobject/smartobject_circles.png'
import imgSampleSoPink from '../../asset/samples/smartobject/smartobject_pink.png'
import imgSampleSoScratch from '../../asset/samples/smartobject/smartobject_scratch.png'
import imgSampleTextOriginal from '../../asset/samples/textlayer/textlayer_original.jpg'
import imgSampleTextColor from '../../asset/samples/textlayer/textlayer_color.jpg'
import imgSampleTextFont from '../../asset/samples/textlayer/textlayer_font.jpg'
import imgSampleTextSize from '../../asset/samples/textlayer/textlayer_size.jpg'
import imgSamplePsActionsOriginal from '../../asset/samples/psactions/psactions_original.jpg'
import imgSamplePsActionsGd from '../../asset/samples/psactions/psactions_gd.jpg'
import imgSampleLrAutoStraightenOriginal from '../../asset/samples/autostraighten/autostraighten_original.jpg'
import imgSampleLrAutoStraightenAfter from '../../asset/samples/autostraighten/autostraighten_after.jpg'

export default function Main () {
    function getSampleImage () {
        return <div className="adobe-skycity-home-content">
            <div className="adobe-skycity-sample-images">
                <a href="?ref=pssmartobject">
                    <div>
                        <figcaption className="adobe-skycity-sample-images-caption"><p>{__('home_ps_pssmartobject_caption')}</p></figcaption>
                    </div>
                    <div>
                        <img className="adobe-skycity-sample-images-original" src={imgSampleSoOriginal} alt="preview" />
                    </div>
                    <div id="adobe-skycity-sample-images-crossfade">
                        <img className="adobe-skycity-sample-images-edited" src={imgSampleSoCircles} alt="preview" />
                        <img className="adobe-skycity-sample-images-edited" src={imgSampleSoPink} alt="preview" />
                        <img className="adobe-skycity-sample-images-edited" src={imgSampleSoScratch} alt="preview" />
                    </div>
                </a>
            </div>
            <div className="adobe-skycity-sample-images">
                <a href="?ref=ssimagecutout">
                    <div>
                        <figcaption className="adobe-skycity-sample-images-caption"><p>{__('home_sensei_ico_caption')}</p></figcaption>
                    </div>
                    <div>
                        <img className="adobe-skycity-sample-images-original" src={imgSampleImagecutoutOriginal} alt="preview" />
                    </div>
                    <div id="adobe-skycity-sample-images-crossfade">
                        <img className="adobe-skycity-sample-images-edited" src={imgSampleImagecutoutBg} alt="preview" />
                        <img className="adobe-skycity-sample-images-edited" src={imgSampleImagecutoutTp} alt="preview" />
                    </div>
                </a>
            </div>
            <div className="adobe-skycity-sample-images">
                <a href="?ref=lrapplypreset">
                    <figcaption className="adobe-skycity-sample-images-caption"><p>{__('home_lr_preset_caption')}</p></figcaption>
                    <div>
                        <img className="adobe-skycity-sample-images-original" src={imgSamplePresetBefore} alt="preview" />
                    </div>
                    <div id="adobe-skycity-sample-images-crossfade">
                        <img className="adobe-skycity-sample-images-edited" src={imgSamplePresetProcess} alt="preview" />
                        <img className="adobe-skycity-sample-images-edited" src={imgSamplePresetAged} alt="preview" />
                        <img className="adobe-skycity-sample-images-edited" src={imgSamplePresetMatte} alt="preview" />
                    </div>
                </a>
            </div>
            <div className="adobe-skycity-sample-images">
                <a href="?ref=lrautotone">
                    <figcaption className="adobe-skycity-sample-images-caption"><p>{__('home_lrautotone_caption')}</p></figcaption>
                    <img className="adobe-skycity-sample-images-original" src={imgSampleAutotoneBefore} alt="preview" />
                    <img className="adobe-skycity-sample-images-edited" src={imgSampleAutotoneAfter} alt="preview" />
                </a>
            </div>
            <div className="adobe-skycity-sample-images">
                <a href="?ref=psedit">
                    <figcaption className="adobe-skycity-sample-images-caption"><p>{__('home_ps_text_edit_caption')}</p></figcaption>
                    <div>
                        <img className="adobe-skycity-sample-images-original" src={imgSampleTextOriginal} alt="preview" />
                    </div>
                    <div id="adobe-skycity-sample-images-crossfade">
                        <img className="adobe-skycity-sample-images-edited" src={imgSampleTextColor} alt="preview" />
                        <img className="adobe-skycity-sample-images-edited" src={imgSampleTextFont} alt="preview" />
                        <img className="adobe-skycity-sample-images-edited" src={imgSampleTextSize} alt="preview" />
                    </div>
                </a>
            </div>
            <div className="adobe-skycity-sample-images">
                <a href="?ref=psactions">
                    <figcaption className="adobe-skycity-sample-images-caption"><p>{__('home_ps_psactions_caption')}</p></figcaption>
                    <div>
                        <img className="adobe-skycity-sample-images-original" src={imgSamplePsActionsOriginal} alt="preview" />
                    </div>
                    <div id="adobe-skycity-sample-images-crossfade">
                        <img className="adobe-skycity-sample-images-edited" src={imgSamplePsActionsGd} alt="preview" />
                    </div>
                </a>
            </div>
            <div className="adobe-skycity-sample-images">
                <figcaption className="adobe-skycity-sample-images-caption"><p>{__('home_ps_banner_ad_reflow_caption')}</p></figcaption>
                <div>
                    {/* <iframe title="pssmartobject" className="adobe-skycity-sample-images" src="https://www.youtube.com/embed/vOvyOM6ka98" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                    <iframe title="psreflowcep" className="adobe-skycity-sample-images" src="https://www.youtube.com/embed/vOvyOM6ka98"
                        frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture">
                    </iframe>
                </div>
            </div>
            <div className="adobe-skycity-sample-images">
                <a href="?ref=lrautostraighten">
                    <figcaption className="adobe-skycity-sample-images-caption"><p>{__('home_lr_autostraighten_caption')}</p></figcaption>
                    <div>
                        <img className="adobe-skycity-sample-images-original" src={imgSampleLrAutoStraightenOriginal} alt="preview" />
                    </div>
                    <div id="adobe-skycity-sample-images-crossfade">
                        <img className="adobe-skycity-sample-images-edited" src={imgSampleLrAutoStraightenAfter} alt="preview" />
                    </div>
                </a>
            </div>
            <div className="adobe-skycity-sample-images">
                <figcaption className="adobe-skycity-sample-images-caption"><p>{__('home_ps_psactions_caption')}</p></figcaption>
                <div>
                    <iframe title="psactions" className="adobe-skycity-sample-images" src="https://www.youtube.com/embed/_iZa1NoWMTg"
                        frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture">
                    </iframe>
                </div>
            </div>
        </div>
    }

    try {
        return (
            <div className="adobe-skycity-main-pane">
                <div className="adobe-skycity-main adobe-skycity-home">
                    {getSampleImage()}
                </div>
            </div>
        )
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
