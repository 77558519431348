const ccParamFiles = [
    'ccParam01.js'
]

const awsParamFiles = [
    'awsParam01.js'
]

export function setCcParams () {
    const params = ccParamFiles.map(file => {
        const { Code } = require(`./${file}`)
        return Code
    })
    return params
}

export function setAwsParams () {
    const params = awsParamFiles.map(file => {
        const { Code } = require(`./${file}`)
        return Code
    })
    return params
}
