import React, { useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import Heading from '@react/react-spectrum/Heading'
import Well from '@react/react-spectrum/Well'
import Slider from '@react/react-spectrum/Slider'

export default function ApplyEditOptions (props) {
    const {
        lrApplyEditOptions,
        dispatch
    } = useContext(SkycityContext)

    function changeValue (e, curOption) {
        const newLrApplyEditOptions = lrApplyEditOptions.map(lrApplyEditOption => {
            if (lrApplyEditOption.type === curOption.type) {
                switch (lrApplyEditOption.type) {
                case 'exposure':
                    lrApplyEditOption.value = parseFloat(e)
                    break
                default:
                    lrApplyEditOption.value = parseInt(e)
                }
            }
            return lrApplyEditOption
        })
        dispatch({ type: 'set-lrApplyEditOptions', payload: { lrApplyEditOptions: newLrApplyEditOptions } })
    }

    function getApplyEditOptions () {
        if (!lrApplyEditOptions || lrApplyEditOptions.length === 0) {
            return null
        }
        const lrApplyEditOptionsComp = lrApplyEditOptions.map(lrApplyEditOption => {
            return <div className="adobe-skycity-option-slider" key={lrApplyEditOption.type}>
                <Slider
                    min={lrApplyEditOption.min}
                    max={lrApplyEditOption.max}
                    step={lrApplyEditOption.step}
                    defaultValue={lrApplyEditOption.value}
                    onChange={ (e) => changeValue(e, lrApplyEditOption)}
                    label={`${lrApplyEditOption.label} (${lrApplyEditOption.min} to ${lrApplyEditOption.max})`}
                    renderLabel />
            </div>
        })
        return <Well>{lrApplyEditOptionsComp}</Well>
    }

    return <div className="adobe-skycity-output-options">
        <Well>
            <Heading variant="pageTitle">{__('skycity_comp_title_lrapplyedit_options')}</Heading>
            <br />
            <div className="adobe-skycity-output-options-file-format">
                {getApplyEditOptions()}
            </div>
        </Well>
    </div>
}
