const Desc = `photoshopActions: cartoonize.atn. ${new Error('To get file path').stack.split('\n')[1].match(/Object(.+)\(https/)[1] || ''}`

const Payload = {
    inputs: [
        {
            href: 'files/_skycity_smoketest/adobe1s.png',
            storage: 'adobe'
        }
    ],
    options: {
        actions: [
            {
                href: 'files/_skycity_smoketest/cartoonize.atn',
                storage: 'adobe'
            }
        ]
    },
    outputs: [
        {
            storage: 'adobe',
            type: 'image/png',
            overwrite: true,
            width: 300,
            compression: 'large'
        }
    ]
}

module.exports = { Payload, Desc }
