import React, { useContext, createContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'

import { SkycityContext } from '../../lib/SkycityProvider'

import { Asset } from '@react/react-spectrum/Asset'
import Dialog from '@react/react-spectrum/Dialog'
import Breadcrumbs from '@react/react-spectrum/Breadcrumbs'
// import Textfield from '@react/react-spectrum/Textfield'
import { Card, CardBody, CardPreview } from '@react/react-spectrum/Card'
import { GridView, GridLayout } from '@react/react-spectrum/GridView'
import { TableView } from '@react/react-spectrum/TableView'
import { error, warning } from '@react/react-spectrum/Toast'
import Folder from '@react/react-spectrum/Icon/Folder'
import FileTxt from '@react/react-spectrum/Icon/FileTxt'
import Wait from '@react/react-spectrum/Wait'

import FileViewOption from './FileViewOption'

import {
    getCcFiles,
    getRendition,
    getFileSize,
    getFileType,
    getDocumentManifest,
    getFilenameWithoutExt,
    getExtensionFromPath
} from '../../lib/skycity.js'

export default function CcBrowser ({
    imageTypes,
    inputType,
    title,
    showCcBrowserPane,
    showCcBrowserPaneFont,
    showCcBrowserPaneSecond,
    showCcBrowserPaneIcc,
    actionType,
    documentManifest,
    multiple
}) {
    const {
        ccFiles,
        dsCcFiles,
        dispatch,
        selectedInputFile,
        inputFiles,
        inputSecondFiles,
        inputFontFiles,
        inputIccFiles,
        fileViewOption,
        wait,
        tableViewColumnsCc
    } = useContext(SkycityContext)

    // const [searchText, setSearchText] = useState("")
    // const [ranges, setRanges] = useState(null)
    let ranges

    const CcBrowserContext = createContext()
    const CcBrowserProvider = ({ children }) => {
        return (
            <CcBrowserContext.Provider>
                {children}
            </CcBrowserContext.Provider>
        )
    }

    // function filtereFiles (searchString) {
    //     if (searchString) {
    //         setSearchText(searchText)
    //         const filteredChildren = ccFiles.children.filter( file => {
    //             const regex = new RegExp(searchString, "i");
    //             if (regex.test( file.path )) {
    //                 console.log(`file.path: ${file.path}`)
    //                 return file;
    //             } else {
    //                 return null;
    //             }
    //         })
    //         dispatch({ type: 'set-ccfiles', payload: { ccFiles: ccFiles, filteredCcFiles: filteredChildren } })
    //     }
    // }

    function getCcFilesByDirectory (path) {
        getCcFiles(path, (ccFiles, res) => {
            if (ccFiles) {
                dispatch({ type: 'set-ccfiles', payload: { ccFiles: ccFiles } })
                dispatch({ type: 'set-wait', payload: { wait: false } })

                // TODO kmikawa debug
                ccFiles.children.map(file => {
                    if (file.type !== 'application/vnd.adobe.directory+json') {
                        const ext = getExtensionFromPath(file.path).toLowerCase()
                        if (ext === 'psd' || ext === 'psb') {
                            console.log(`${ext}\t\t| ${file.type} | ${file.path}`)
                        }
                    }
                })
            } else {
                dispatch({ type: 'set-wait', payload: { wait: false } })
                console.log(__('browser_cc_not_found'))
                error(__('browser_cc_not_found'), {
                    timeout: 0
                })
            }
        })
    }

    function selectionChange (e, id, allowsSelection) {
        if (!allowsSelection) {
            return
        }
        if (!multiple && e && ccFiles.children.find(ccFile => ccFile.checked === true)) {
            warning('You can only select one file', {
                timeout: 0
            })
            return
        }
        const newChildren = ccFiles.children.map(file => {
            if (file.id === id) {
                file.checked = e
            }
            return file
        })
        ccFiles.children = newChildren
        dispatch({ type: 'set-ccfiles', payload: { ccFiles: ccFiles } })
    }

    function renderItem (asset) {
        try {
            let allowsSelection = false
            if (imageTypes.find(type => type === asset.type)) {
                allowsSelection = true
            }
            switch (asset.type) {
            case 'application/vnd.adobe.directory+json':
                return <div>
                    <Card
                        variant="quiet"
                        allowsSelection={false}
                        onClick={ (e) => getCcFilesByDirectory(asset.path, e)} >
                        <CardPreview>
                            <Asset type="folder" />
                        </CardPreview>
                        <CardBody title={asset.path.split('/').pop()} subtitle={getFileType(asset.type)} description={getFileSize(asset.size)} />
                    </Card>
                </div>
            case 'image/png':
            case 'image/jpeg':
            case 'image/tiff':
            case 'image/svg+xml':
            case 'image/vnd.adobe.photoshop':
            case 'application/illustrator':
            case 'application/pdf':
                return <div onClick={ e => selectionChange(!asset.checked, asset.id, allowsSelection) }>
                    <Card
                        size="S"
                        selected={asset.checked}
                        allowsSelection={allowsSelection}
                        onSelectionChange={ e => selectionChange(e, asset.id, allowsSelection) }>
                        <CardPreview>
                            <Asset
                                type="image"
                                cache={true}
                                alt={asset.path}
                                src={getRendition(asset, 300)} />
                        </CardPreview>
                        <CardBody title={asset.path.split('/').pop()} subtitle={getFileType(asset.type)} description={getFileSize(asset.size)} />
                    </Card>
                </div>
            case 'text/plain':
            case 'text/csv':
            case 'application/x-font-otf':
            case 'application/x-font-ttf':
            case 'application/x-font-ttc':
            case 'application/vnd.iccprofile':
            case 'application/octet-stream':
            case 'application/zip':
            case 'application/json':
                return <div onClick={ e => selectionChange(!asset.checked, asset.id, allowsSelection) }>
                    <Card
                        size="S"
                        selected={asset.checked}
                        allowsSelection={allowsSelection}
                        onSelectionChange={ e => selectionChange(e, asset.id, allowsSelection) }>
                        <CardPreview>
                            <Asset
                                type="file"
                                cache={true}
                                alt={asset.path}
                                src={getRendition(asset, 200)} />
                        </CardPreview>
                        <CardBody title={asset.path.split('/').pop()} subtitle={getFileType(asset.type)} description={getFileSize(asset.size)} />
                    </Card>
                </div>
            default:
                return <div>
                    <Card variant="quiet">
                        <CardPreview>
                            <Asset type="file" />
                        </CardPreview>
                        <CardBody title={asset.path.split('/').pop()} subtitle={getFileType(asset.type)} description={getFileSize(asset.size)} />
                    </Card>
                </div>
            }
        } catch (e) {
            console.log(e)
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
        }
    }

    function getCcAssets () {
        try {
            if (fileViewOption === 'ListView') {
                return <div className="adobe-skycity-thumbnail-container">
                    <TableView
                        columns={tableViewColumnsCc}
                        dataSource={dsCcFiles}
                        onSelectionChange={ e => changedSelection(e) }
                        onCellDoubleClick={onListViewCellDoubleClick}
                        renderCell={renderCell} />
                </div>
            }
            return <div className="adobe-skycity-thumbnail-container">
                <GridView
                    allowsMultipleSelection={multiple}
                    layout={GridLayout}
                    dataSource={dsCcFiles}
                    renderItem={renderItem} />
            </div>
        } catch (e) {
            console.log(e)
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
        }
    }

    function changedSelection (data) {
        if (data && data.sectionIndexSets && data.sectionIndexSets.get(0) && data.sectionIndexSets.get(0).ranges) {
            ranges = data.sectionIndexSets.get(0).ranges
        } else {
            ranges = null
        }
    }

    function onListViewCellDoubleClick (column, index) {
        if (dsCcFiles.data[index].type === 'application/vnd.adobe.directory+json') {
            dispatch({ type: 'set-wait', payload: { wait: true } })
            getCcFilesByDirectory(dsCcFiles.data[index].path)
        }
    }

    function updatedSelectedFiles (ranges) {
        const ids = []
        if (ranges && ranges.length > 0) {
            ranges.forEach(range => {
                let i = range.start
                for (i; i <= range.end; i++) {
                    ids.push(ccFiles.children[i].id)
                }
            })
            // console.log(ids.length);
        }
        const newChildren = ccFiles.children.map(file => {
            file.checked = false
            if (ids.find(id => (id === file.id))) {
                file.checked = true
            }
            return file
        })
        ccFiles.children = newChildren
        dispatch({ type: 'set-ccfiles', payload: { ccFiles: ccFiles } })
    }

    function renderCell (column, asset) {
        try {
            if (column.key === 'thumbnail') {
                // let allowsSelection = false
                // if (imageTypes.find(type => type === asset.type)) {
                //     allowsSelection = true
                // }
                switch (asset.type) {
                case 'application/vnd.adobe.directory+json':
                    return <Folder size="M" />
                case 'image/png':
                case 'image/jpeg':
                case 'image/tiff':
                case 'image/svg+xml':
                case 'image/vnd.adobe.photoshop':
                case 'application/illustrator':
                case 'application/pdf':
                    return <Asset
                        type="image"
                        cache={true}
                        src={getRendition(asset, 100)} />
                case 'text/plain':
                case 'text/csv':
                case 'application/x-font-otf':
                case 'application/x-font-ttf':
                case 'application/x-font-ttc':
                case 'application/vnd.iccprofile':
                case 'application/octet-stream':
                case 'application/zip':
                case 'application/json':
                    return <FileTxt size="M" />
                default:
                    return <FileTxt size="M" />
                }
            } else if (column.key === 'size') {
                return <span>{getFileSize(asset[column.key])}</span>
            }
            return <span>{asset[column.key]}</span>
        } catch (e) {
            console.log(e)
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
        }
    }

    function getBreadcrumbs () {
        try {
            const crumbsArray = []
            const curPath = ccFiles.path.split('/')
            const crumbs = curPath.map(path => {
                crumbsArray.push(path)
                return {
                    label: path,
                    value: crumbsArray.join('/')
                }
            })
            return <Breadcrumbs
                items={crumbs}
                onBreadcrumbClick={e => getCcFilesByDirectory(e.value)} />
        } catch (e) {
            console.log(e)
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
        }
    }

    function onConfirm (e) {
        try {
            if (ranges && ranges.length > 0) {
                updatedSelectedFiles(ranges)
            }
            if (ccFiles && ccFiles.children && ccFiles.children.length > 0) {
                const checkedFiles = ccFiles.children.filter(ccFile => ccFile.checked === true)
                const filtered = checkedFiles.map(file => {
                    file.shortName = getFilenameWithoutExt(file.name)
                    file.storage = 'adobe'
                    file.inputType = inputType
                    return file
                })
                // console.log(`filtered: ${filtered}`);
                let newFiles = filtered
                if (actionType === 'set-input' && inputFiles) {
                    newFiles = [...inputFiles, ...filtered]
                }
                if (actionType === 'set-input-second' && inputSecondFiles) {
                    newFiles = [...inputSecondFiles, ...filtered]
                }
                if (actionType === 'set-input-font' && inputFontFiles) {
                    newFiles = [...inputFontFiles, ...filtered]
                }
                if (actionType === 'set-input-icc' && inputIccFiles) {
                    newFiles = [...inputIccFiles, ...filtered]
                }
                dispatch({ type: actionType, payload: { files: newFiles, file: { progress: 0, name: '' } } })
                dispatch({ type: 'toggle-cc-browser-pane', payload: { actionType: actionType, inputType: inputType, visible: false } })

                if (documentManifest) {
                    getDocumentManifest(filtered[0], files => {
                        if (files) {
                            dispatch({ type: actionType, payload: { wait: false, files: files, file: { progress: 0, name: '' } } })
                            if (!selectedInputFile) {
                                if (actionType === 'set-input') {
                                    dispatch({ type: 'set-selected-input', payload: { file: files[0] } })
                                    dispatch({ type: 'toggle-layer-info-pane', payload: { showLayerInfoPane: true } })
                                }
                            }
                        } else {
                            error(__('browser_cc_get_document_manifest_error'), {
                                actionLabel: 'Try again',
                                onClose: () => console.log('close'),
                                onAction: onConfirm(),
                                timeout: 5000
                            })
                        }
                    })
                }
            }
        } catch (e) {
            console.log(e)
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
        }
    }

    // function getSearchOption () {
    //     return <div role="search">
    //         <Textfield value={searchText} onChange={ e => filtereFiles(e) }/>
    //     </div>
    // }

    function getClassName (actionType) {
        switch (actionType) {
        case 'set-input':
            if (showCcBrowserPane) {
                return 'adobe-skycity-cc-browser-wrapper-show'
            } else {
                return 'adobe-skycity-cc-browser-wrapper-hide'
            }
        case 'set-input-font':
            if (showCcBrowserPaneFont) {
                return 'adobe-skycity-cc-browser-wrapper-show'
            } else {
                return 'adobe-skycity-cc-browser-wrapper-hide'
            }
        case 'set-input-second':
            if (showCcBrowserPaneSecond) {
                return 'adobe-skycity-cc-browser-wrapper-show'
            } else {
                return 'adobe-skycity-cc-browser-wrapper-hide'
            }
        case 'set-input-icc':
            if (showCcBrowserPaneIcc) {
                return 'adobe-skycity-cc-browser-wrapper-show'
            } else {
                return 'adobe-skycity-cc-browser-wrapper-hide'
            }
        default:
            return 'adobe-skycity-cc-browser-wrapper-hide'
        }
    }

    function CcBrowser () {
        if (ccFiles && ccFiles.children && ccFiles.children.length > 0 && (showCcBrowserPane || showCcBrowserPaneFont || showCcBrowserPaneSecond || showCcBrowserPaneIcc)) {
            try {
                return <div id={`cc-browser-${actionType}-${inputType}`} className={getClassName(actionType)}>
                    { wait && <div className="adobe-skycity-modal-loading">
                        <Wait size="L" />
                        <div>{__('get_files_in_directory')}</div>
                    </div>
                    }
                    <Dialog
                        title={`Creative Cloud - ${title}`}
                        mode="fullscreen"
                        confirmLabel="OK"
                        cancelLabel="Cancel"
                        onCancel={ e => dispatch({ type: 'toggle-cc-browser-pane', payload: { actionType: actionType, inputType: inputType, visible: false } })}
                        onConfirm={ e => onConfirm(e)}>
                        <div className="adobe-skycity-ccbrowser">
                            <div className="adobe-skycity-flex-row-space-between">
                                { getBreadcrumbs() }
                                <FileViewOption />
                            </div>
                            { getCcAssets(ccFiles.children) }
                        </div>
                    </Dialog>
                </div>
            } catch (e) {
                console.log(e)
                error(`${__('error_msg_title')}: ${e}`, {
                    timeout: 0
                })
            }
        }
        return null
    }

    return <CcBrowserProvider><CcBrowser /></CcBrowserProvider>
}
