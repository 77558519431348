import React, { useContext, useState, createContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'

import { SkycityContext } from '../../lib/SkycityProvider'

import { Asset } from '@react/react-spectrum/Asset'
import Dialog from '@react/react-spectrum/Dialog'
import Breadcrumbs from '@react/react-spectrum/Breadcrumbs'
import Search from '@react/react-spectrum/Search'
import Textfield from '@react/react-spectrum/Textfield'
import { Card, CardBody, CardPreview } from '@react/react-spectrum/Card'
import { GridView, GridLayout } from '@react/react-spectrum/GridView'
import { error, warning } from '@react/react-spectrum/Toast'

import {
    getCloudContentFiles,
    getRendition,
    getFileSize,
    getFileType,
    getDocumentManifest,
    getFilenameWithoutExt
} from '../../lib/skycity.js'

export default function CloudContentBrowser ({
    imageTypes,
    title,
    showCloudContentBrowserPane,
    showCloudContentBrowserPaneSecond,
    showCloudContentBrowserPaneIcc,
    actionType,
    documentManifest,
    multiple
}) {
    const {
        cloudContentFiles,
        dsCloudContentFiles,
        // ccFiles,
        // dsCcFiles,
        dispatch,
        selectedInputFile,
        inputFiles,
        inputSecondFiles,
        inputIccFiles
    } = useContext(SkycityContext)

    const [searchText, setSearchText] = useState('')
    const CcBrowserContext = createContext()
    const CcBrowserProvider = ({ children }) => {
        return (
            <CcBrowserContext.Provider>
                {children}
            </CcBrowserContext.Provider>
        )
    }

    function filtereFiles (searchString) {
        if (searchString) {
            setSearchText(searchText)
            const regex = new RegExp(searchString, 'i')
            // const filteredChildren = cloudContent.children.filter(file => regex.test(file.path))

            const filteredChildren = cloudContentFiles.children.filter(file => {
                const regex = new RegExp(searchString, 'i')
                if (regex.test(file.path)) {
                    console.log(`file.path: ${file.path}`)
                    return file
                }
            })
            dispatch({ type: 'set-cloudContentFiles', payload: { cloudContentFiles: cloudContentFiles, filteredCloudContentFiles: filteredChildren } })
        }
    }

    function getCloudContentFilesByDirectory (path) {
        getCloudContentFiles(path, (cloudContentFiles, res) => {
            if (cloudContentFiles) {
                dispatch({ type: 'set-cloudContentFiles', payload: { cloudContentFiles: cloudContentFiles } })
            } else {
                console.log(__('browser_cc_not_found'))
                error(__('browser_cc_not_found'), {
                    timeout: 0
                })
            }
        })
    }

    function selectionChange (e, id, allowsSelection) {
        if (!allowsSelection) {
            return
        }
        if (!multiple && e && cloudContentFiles.children.find(cloudContentFile => cloudContentFile.checked === true)) {
            warning('You can only select one file', {
                timeout: 0
            })
            return
        }
        const newChildren = cloudContentFiles.children.map(file => {
            if (file.id === id) {
                file.checked = e
            }
            return file
        })
        cloudContentFiles.children = newChildren
        dispatch({ type: 'set-cloudContentFiles', payload: { cloudContentFiles: cloudContentFiles } })
    }

    function renderItem (asset) {
        try {
            let allowsSelection = false
            if (imageTypes.find(type => type === asset.type)) {
                allowsSelection = true
            }
            switch (asset.type) {
            case 'application/vnd.adobe.directory+json':
                return <div>
                    <Card
                        variant="quiet"
                        allowsSelection={false}
                        onClick={ (e) => getCloudContentFilesByDirectory(asset.path, e)} >
                        <CardPreview>
                            <Asset type="folder" />
                        </CardPreview>
                        <CardBody title={asset.path.split('/').pop()} subtitle={getFileType(asset.type)} description={getFileSize(asset.size)} />
                    </Card>
                </div>
            case 'image/png':
            case 'image/jpeg':
            case 'image/tiff':
            case 'image/svg+xml':
            case 'image/vnd.adobe.photoshop':
            case 'document/vnd.adobe.cpsd+dcx':
            case 'application/illustrator':
            case 'application/pdf':
                return <div onClick={ e => selectionChange(!asset.checked, asset.id, allowsSelection) }>
                    <Card
                        size="S"
                        selected={asset.checked}
                        allowsSelection={allowsSelection}
                        onSelectionChange={ e => selectionChange(e, asset.id, allowsSelection) }>
                        <CardPreview>
                            <Asset
                                type="image"
                                cache={true}
                                src={getRendition(asset, 300)} />
                        </CardPreview>
                        <CardBody title={asset.path.split('/').pop()} subtitle={getFileType(asset.type)} description={getFileSize(asset.size)} />
                    </Card>
                </div>
            case 'text/plain':
            case 'application/x-font-otf':
            case 'application/x-font-ttf':
            case 'application/x-font-ttc':
            case 'application/vnd.iccprofile':
            case 'application/octet-stream':
            case 'application/json':
                return <div onClick={ e => selectionChange(!asset.checked, asset.id, allowsSelection) }>
                    <Card
                        size="S"
                        selected={asset.checked}
                        allowsSelection={allowsSelection}
                        onSelectionChange={ e => selectionChange(e, asset.id, allowsSelection) }>
                        <CardPreview>
                            <Asset
                                type="file"
                                cache={true}
                                src={getRendition(asset, 200)} />
                        </CardPreview>
                        <CardBody title={asset.path.split('/').pop()} subtitle={getFileType(asset.type)} description={getFileSize(asset.size)} />
                    </Card>
                </div>
            default:
                return <div>
                    <Card variant="quiet">
                        <CardPreview>
                            <Asset type="file" />
                        </CardPreview>
                        <CardBody title={asset.path.split('/').pop()} subtitle={getFileType(asset.type)} description={getFileSize(asset.size)} />
                    </Card>
                </div>
            }
        } catch (e) {
            console.log(e)
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
        }
    }

    function getCloudContentAssets () {
        try {
            return <div className="adobe-skycity-thumbnail-container">
                <GridView
                    allowsMultipleSelection={multiple}
                    layout={GridLayout}
                    dataSource={dsCloudContentFiles}
                    renderItem={renderItem} />
            </div>
        } catch (e) {
            console.log(e)
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
        }
    }

    function getBreadcrumbs () {
        try {
            const crumbsArray = []
            const curPath = cloudContentFiles.path.split('/')
            const crumbs = curPath.map(path => {
                crumbsArray.push(path)
                return {
                    label: path,
                    value: crumbsArray.join('/')
                }
            })
            return <Breadcrumbs
                items={crumbs}
                onBreadcrumbClick={e => getCloudContentFilesByDirectory(e.value)} />
        } catch (e) {
            console.log(e)
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
        }
    }

    function onConfirm (e) {
        try {
            if (cloudContentFiles && cloudContentFiles.children && cloudContentFiles.children.length > 0) {
                const checkedFiles = cloudContentFiles.children.filter(cloudContentFile => cloudContentFile.checked === true)
                const filtered = checkedFiles.map(file => {
                    file.shortName = getFilenameWithoutExt(file.name)
                    file.storage = 'adobe'
                    return file
                })
                // console.log(`filtered: ${filtered}`);
                let newFiles = filtered
                if (actionType === 'set-input' && inputFiles) {
                    newFiles = [...inputFiles, ...filtered]
                }
                if (actionType === 'set-input-second' && inputSecondFiles) {
                    newFiles = [...inputSecondFiles, ...filtered]
                }
                if (actionType === 'set-input-icc' && inputIccFiles) {
                    newFiles = [...inputIccFiles, ...filtered]
                }
                dispatch({ type: actionType, payload: { files: newFiles, file: { progress: 0, name: '' } } })
                dispatch({ type: 'toggle-cloudContent-browser-pane', payload: { actionType: actionType, visible: false } })

                if (documentManifest) {
                    getDocumentManifest(filtered[0], files => {
                        if (files) {
                            dispatch({ type: actionType, payload: { wait: false, files: files, file: { progress: 0, name: '' } } })
                            if (!selectedInputFile) {
                                if (actionType === 'set-input') {
                                    dispatch({ type: 'set-selected-input', payload: { file: files[0] } })
                                    dispatch({ type: 'toggle-layer-info-pane', payload: { showLayerInfoPane: true } })
                                }
                            }
                        } else {
                            error(__('browser_cc_get_document_manifest_error'), {
                                actionLabel: 'Try again',
                                onClose: () => console.log('close'),
                                onAction: onConfirm(),
                                timeout: 5000
                            })
                        }
                    })
                }
            }
        } catch (e) {
            console.log(e)
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
        }
    }

    function getSearchOption () {
        return <div role="search">

            <Textfield value={searchText} onChange={ e => filtereFiles(e) }/>
        </div>
    }

    function getClassName (actionType) {
        switch (actionType) {
        case 'set-input':
            if (showCloudContentBrowserPane) {
                return 'adobe-skycity-cc-browser-wrapper-show'
            } else {
                return 'adobe-skycity-cc-browser-wrapper-hide'
            }
        case 'set-input-second':
            if (showCloudContentBrowserPaneSecond) {
                return 'adobe-skycity-cc-browser-wrapper-show'
            } else {
                return 'adobe-skycity-cc-browser-wrapper-hide'
            }
        case 'set-input-icc':
            if (showCloudContentBrowserPaneIcc) {
                return 'adobe-skycity-cc-browser-wrapper-show'
            } else {
                return 'adobe-skycity-cc-browser-wrapper-hide'
            }
        default:
            return 'adobe-skycity-cc-browser-wrapper-hide'
        }
    }

    function CloudContentBrowser () {
        if (cloudContentFiles && cloudContentFiles.children && cloudContentFiles.children.length > 0 && (showCloudContentBrowserPane || showCloudContentBrowserPaneSecond || showCloudContentBrowserPaneIcc)) {
            try {
                return <div id={`cloudContent-browser-${actionType}`} className={getClassName(actionType)}>
                    <Dialog
                        title={`Creative Cloud - ${title}`}
                        mode="fullscreen"
                        confirmLabel="OK"
                        cancelLabel="Cancel"
                        onCancel={ e => dispatch({ type: 'toggle-cloudContent-browser-pane', payload: { actionType: actionType, visible: false } })}
                        onConfirm={ e => onConfirm(e)}>
                        <div className="adobe-skycity-ccbrowser">
                            <div className="adobe-skycity-flex-row-space-between">
                                { getBreadcrumbs() }
                                { getSearchOption() }
                            </div>
                            { getCloudContentAssets(cloudContentFiles.children) }
                        </div>
                    </Dialog>
                </div>
            } catch (e) {
                console.log(e)
                error(`${__('error_msg_title')}: ${e}`, {
                    timeout: 0
                })
            }
        }
        return null
    }

    return <CloudContentBrowser />
}
