import React, { useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import Heading from '@react/react-spectrum/Heading'
import Well from '@react/react-spectrum/Well'
import Textfield from '@react/react-spectrum/Textfield'
import { Form, FormItem } from '@react/react-spectrum/Form'
import Checkbox from '@react/react-spectrum/Checkbox'
import FieldLabel from '@react/react-spectrum/FieldLabel'
import InlineEditor from '@react/react-spectrum/InlineEditor'
import NumberInput from '@react/react-spectrum/NumberInput'
import { error } from '@react/react-spectrum/Toast'

export default function TemplateOptions (props) {
    const {
        templateOptions,
        dispatch
    } = useContext(SkycityContext)

    function changeValue (e, label) {
        templateOptions[label] = e
        dispatch({
            type: 'set-template-options',
            payload: {
                templateOptions: templateOptions
            }
        })
    }

    function changeTestfield (e, label) {
        templateOptions[label] = parseInt(e.target.value)
        dispatch({
            type: 'set-template-options',
            payload: {
                templateOptions: templateOptions
            }
        })
    }

    function getTemplateOptions () {
        return <React.Fragment>
            <Form>
                <FormItem label="inlineEditor">
                    <InlineEditor defaultValue={ templateOptions.inlineEditor } onChange={ e => changeValue(e, 'inlineEditor')}/>
                </FormItem>
                <FormItem label="numberInput">
                    <NumberInput min={2} max={99} defaultValue={templateOptions.numberInput} onChange={ e => changeValue(e, 'numberInput')}/>
                </FormItem>
                <FormItem label="checkbox">
                    <Checkbox value={templateOptions.checkbox}
                        defaultChecked={templateOptions.checkbox}
                        onChange={ e => changeValue(e, 'checkbox') }></Checkbox>
                </FormItem>
            </Form>
            <div className="adobe-skycity-option">
                <div className="adobe-skycity-options-field">
                    <FieldLabel label="textfieldNumberOne:" position="left">
                        <Textfield type="number" min="0" onBlur={e => changeTestfield(e, 'textfieldNumberOne')} defaultValue={templateOptions.textfieldNumberOne} />
                    </FieldLabel>
                </div>
                <div className="adobe-skycity-options-field">
                    <FieldLabel label="textfieldNumberTwo:" position="left">
                        <Textfield type="number" min="0" onBlur={e => changeTestfield(e, 'textfieldNumberTwo')} defaultValue={templateOptions.textfieldNumberTwo} />
                    </FieldLabel>
                </div>
            </div>
        </React.Fragment>
    }

    try {
        return <div className="adobe-skycity-output-options">
            <Well>
                <Heading variant="pageTitle">{__('skycity_comp_title_options_template')}</Heading>
                <br />
                <Heading variant="subtitle3">{props.title}</Heading>
                <br />
                {getTemplateOptions()}
            </Well>
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
