import React, { useContext, useState } from 'react'
import useFetch from '../../hooks/useFetch'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'

import SyntaxHighlighter from 'react-syntax-highlighter'
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs'

import Pagination from '@react/react-spectrum/Pagination'
// import {success} from '@react/react-spectrum/Toast';
import { error } from '@react/react-spectrum/Toast'

import { SkycityContext } from '../../lib/SkycityProvider'

export default function Response ({
    response,
    language,
    style = atomOneDark,
    lineNumberStyle = { color: 'rgba(112, 112, 112, 0.8)' },
    showLineNumbers = true
}) {
    const {
        inputFiles
    } = useContext(SkycityContext)

    const [currentPage, setPage] = useState(1)
    let textFile = null
    const url = createCode(response)
    const text = useFetch({ url, defaultValue: '', json: false })

    // text += `\n`;
    function createCode (response) {
        if (response && response.length > 0) {
            const text = JSON.stringify(response[currentPage - 1].res, null, 2)

            var data = new Blob([text], { type: 'text/plain' })
            if (textFile !== null) {
                window.URL.revokeObjectURL(textFile)
            }
            textFile = window.URL.createObjectURL(data)
            return textFile
        } else {
            return ''
        }
    }

    function onPageInputChange (value) {
        // console.log(value);
        setPage(value)
    }

    try {
        if (inputFiles && inputFiles.length > 0 && response && response.length > 0) {
            return <React.Fragment>
                <center>
                    <div className="adobe-skycity-code-pagination">
                        <Pagination
                            variant="explicit"
                            mode="primary"
                            onChange={onPageInputChange}
                            onNext={onPageInputChange}
                            onPrevious={onPageInputChange}
                            totalPages={response.length}
                            defaultPage={currentPage} />
                    </div>
                </center>
                <div className="adobe-skycity-code-preview">
                    <SyntaxHighlighter lineNumberStyle={lineNumberStyle} showLineNumbers={showLineNumbers} language={language} style={style}>
                        {text}
                    </SyntaxHighlighter>
                </div>
            </React.Fragment>
        } else {
            return null
        }
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
