import {
    getHeaders,
    getStorage,
    getOptionsOutput,
    getInputFileFont
} from '../../lib/skycity.js'

import { getParams } from '../params/psEdit.param.js'
import { prepareBodyForExternal } from './testHelper.js'

export async function getConfig (curFile, outputOptions, iccProfileOption, layerOptions, inputSecondFiles) {
    const payload = await getCodePayload(curFile, outputOptions, iccProfileOption, layerOptions, inputSecondFiles)
    const config = {
        url: `${process.env.REACT_APP_CONFIG_ENDPOINT_PIE}/psdService/documentOperations`,
        method: 'POST',
        timeout: 90000,
        headers: getHeaders(),
        payload: payload,
        body: JSON.stringify(payload)
    }
    return config
}

async function getCodePayload (curFile, outputOptions, iccProfileOption, layerOptions, inputSecondFiles) {
    const codePayload = {
        inputs: [{
            href: curFile.path || curFile.href,
            storage: getStorage(curFile)
        }],
        options: layerOptions,
        outputs: await getOptionsOutput(curFile, outputOptions, iccProfileOption)
    }
    if (inputSecondFiles && inputSecondFiles.length > 0) {
        codePayload.options.fonts = getInputFileFont(inputSecondFiles)
    }
    return codePayload
}

// ************************************************************************
// Test Cases
// ************************************************************************
export async function getTestCases (storages) {
    const testCases = await getParams(storages)
    const newTestCases = await Promise.all(testCases.map(async (testCase) => {
        testCase = await prepareBodyForExternal(testCase)
        testCase.area = 'Photoshop API'
        testCase.feature = 'psEdit'
        return testCase
    }))
    return newTestCases
}
