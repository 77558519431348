import { __ } from 'i18n-for-browser'
import '../config/i18n'
import { error } from '@react/react-spectrum/Toast'

const request = require('request')

function poll (fn, cb, timeout, interval) {
    var endTime = Number(new Date()) + (timeout || 4000)
    interval = interval || 1000;

    (async function p () {
        const response = await fn()
        let res = ''
        if (response.jobStatus) { // generation
            res = response
        } else if (response && response.outputs && Array.isArray(response.outputs._links)) {
            cb(response.outputs, null)
            return
        } else {
            res = (response && response.outputs && Array.isArray(response.outputs) && response.outputs[0]) ? response.outputs[0] : response
        }
        if ((res && res.status === 'succeeded') ||
            (res && res.status === 'failed') ||
            (res && res.job_status === 'COMPLETED') ||
            (res && res.job_status === 'completed') ||
            (res && res.job_status === 'error') ||
            (res && res.job_status === 'failed') ||
            (res && res.jobStatus === 'error') ||
            (res && res.jobStatus === 'completed')) {
            cb(res, null)
        } else if (Number(new Date()) < endTime) {
            console.log(`timing out in ${Math.floor((endTime - Number(new Date())) / 1000)} sec...`)
            // console.log(`endTime: ${endTime}`);
            // console.log(`interval: ${interval}`);
            setTimeout(p, interval)
        } else {
            let errMsg = `Timed out. (${timeout / 1000} sec)`
            if (response) {
                errMsg += ` - ${JSON.stringify(res)}`
            }
            cb(null, errMsg, res)
        }
    })()
}

function getId (id, config) {
    const regex = /^https/ig
    if (!id.match(regex)) {
        return `${config.url}/${id}`
    }
    return id
}

function getPollStatus (body) {
    if (body) {
        const jsonBody = JSON.parse(body)
        if (jsonBody.status) {
            return jsonBody.status
        }
        if (jsonBody.outputs) {
            return jsonBody.outputs[0].status
        }
        if (jsonBody.job_status) {
            return jsonBody.job_status
        }
        if (jsonBody.jobStatus) {
            return jsonBody.jobStatus
        }
        alert('status not found')
    }
}

export async function getStatus (id, config, cb) {
    const options = {
        url: getId(id, config),
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${window.adobeIMS.getAccessToken().token}`,
            'X-api-key': `${process.env.REACT_APP_CONFIG_APP_KEY}`
        }
    }
    poll(() => {
        return new Promise((resolve, reject) => {
            request(options, (error, response, body) => {
                console.log('error:', error)
                console.log('statusCode:', response && response.statusCode)
                console.log('status:', getPollStatus(body))
                console.log('body:', body)
                const json = JSON.parse(body)
                if (response && response.statusCode >= 400) {
                    reject(error || json)
                    cb(null, json)
                } else {
                    try {
                        cb(json)
                        resolve(json)
                    } catch (err) {
                        resolve(err)
                    }
                }
            })
        })
    }, (res, err, errResponse) => {
        if (err) {
            // Error, failure callback
            console.log('1 - ' + err)
            cb(null, err, errResponse)
        } else {
            cb(res)
        }
    // }, 60000, 1000);
    }, config.timeout || 60000, 1000)
}

export async function apiCall (options, cb) {
    try {
        return new Promise((resolve, reject) => {
            request(options, (error, response, body) => {
                // console.log('error:', error);
                // console.log('statusCode:', response && response.statusCode);
                // console.log('body:', body);
                let json
                if (body) {
                    json = JSON.parse(body)
                    if (response && response.statusCode >= 400) {
                        reject(error || json)
                        cb(null, json)
                    } else {
                        resolve(json)
                        if (Object.keys(json._links).length > 1) {
                            cb(json._links)
                        } else {
                            cb((json && json._links && json._links.self && json._links.self.href) || json.job_id || json.jobId)
                        }
                    }
                } else {
                    reject(error || json)
                    cb(null, json)
                }
            })
        })
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
        cb(null, e)
    }
};
