import React, { useContext, useState } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import { error } from '@react/react-spectrum/Toast'
import Button from '@react/react-spectrum/Button'
import Checkbox from '@react/react-spectrum/Checkbox'
import FieldLabel from '@react/react-spectrum/FieldLabel'
import Select from '@react/react-spectrum/Select'
import Heading from '@react/react-spectrum/Heading'
import Well from '@react/react-spectrum/Well'

import axios from 'axios'

import { apiCall, getStatus } from '../../lib/cisApi.js'
import { getConfig } from '../../asset/code/psDocumentManifest.js'

export default function Operation (files) {
    const {
        inputFiles,
        fileTypeOptions,
        errorOptions,
        dispatch
    } = useContext(SkycityContext)

    const [isThumbnail, setThumbnail] = useState(true)
    const [typeOptions, setType] = useState(fileTypeOptions)

    function start (curFile) {
        const config = getConfig(curFile, isThumbnail, typeOptions)
        dispatch({ type: 'set-codes', payload: { config: config } })
        apiCall(config, (id, json) => {
            if (id) {
                getStatus(id, config, (res, error, errResponse) => {
                    if (error) {
                        errorOccured(`${JSON.stringify(error)} - ${JSON.stringify(curFile)}`, errResponse || res)
                    } else if (res && res.outputs && res.outputs[0] && res.outputs[0].status === 'failed') {
                        const errMsg = res.outputs[0].errors ? `${id} - ${JSON.stringify(res.outputs[0].errors, null, 2)}` : `ERROR with ${config.url}`
                        errorOccured(`${JSON.stringify(curFile)} - ${errMsg}`, errResponse || res)
                    } else if (res && res.outputs && res.outputs[0] && res.outputs[0].status === 'succeeded') {
                        files.push(res.outputs[0])
                        dispatch({
                            type: 'psdocumentmanifest-success',
                            payload: {
                                files: files,
                                selectedInputFile: files[0],
                                showLayerInfoPane: true,
                                loading: false,
                                wait: false,
                                response: res
                            }
                        })
                    }
                })
            } else {
                const errMsg = json ? `${JSON.stringify(json, null, 2)}` : `ERROR with ${config.url}`
                errorOccured(`${JSON.stringify(curFile)} - ${errMsg}`, json)
            }
        })
    }

    function run (e) {
        try {
            dispatch({ type: 'skycity-run', payload: {} })

            files = []
            const funcArray = []
            inputFiles.forEach(curFile => {
                funcArray.push(start(curFile))
            })
            axios.all(funcArray)
                .then((res) => {
                    // then
                })
                .catch((err) => {
                    const errMsg = `ERROR: ${JSON.stringify(err)}`
                    errorOccured(errMsg)
                })
                .finally(() => {
                    console.log('finally...')
                })
        } catch (e) {
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        }
    }

    function isRunDisabled () {
        if (inputFiles && inputFiles.length > 0) {
            return false
        }
        return true
    }

    function errorOccured (errMsg, res) {
        console.log(errMsg)
        error(errMsg.slice(0, errorOptions.wordSlice), {
            onClose: () => console.log('close'),
            timeout: errorOptions.msgTimeout
        })
        dispatch({ type: 'error-occured', payload: { errMsg: errMsg, response: res, loading: false, wait: false } })
    }

    function changeType (e) {
        const newOptions = typeOptions.map(typeOption => {
            if (typeOption.value === e) {
                typeOption.selected = true
            } else {
                typeOption.selected = false
            }
            return typeOption
        })
        setType(newOptions)
    }

    try {
        return <div>
            <Well>
                <Heading variant="pageTitle">{__('psdocumentmanifest_options')}</Heading>
                <br />
                <div className="adobe-skycity-no-wrap-options">
                    <Checkbox value={isThumbnail}
                        disabled={isRunDisabled()}
                        defaultChecked={isThumbnail}
                        onChange={ e => setThumbnail(e) }></Checkbox>
                    <FieldLabel label="Thumbnail Type" labelFor="thumbnails-type" position="left">
                        <Select
                            options={typeOptions}
                            id="thumbnails-type"
                            disabled={isRunDisabled() || !isThumbnail}
                            onChange={e => changeType(e)}
                            defaultValue={typeOptions.find(typeOption => typeOption.selected === true).value} />
                    </FieldLabel>
                </div>
            </Well>
            <div className="adobe-skycity-button-right">
                <Button variant="cta" onClick={e => run(e)} disabled={isRunDisabled()}>{__('btn_run')}</Button>
            </div>
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
