import React, { useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import { TreeView } from '@react/react-spectrum/TreeView'
import Layers from '@react/react-spectrum/Icon/Layers'
import Heading from '@react/react-spectrum/Heading'

import LayerType from '../Shared/LayerType'
import LayerVisibility from '../Shared/LayerVisibility'
import LayerLock from '../Shared/LayerLock'

export default function LayerInfo () {
    const {
        dispatch,
        inputFiles,
        selectedLayers,
        showLayerInfoPane,
        layerDs
    } = useContext(SkycityContext)

    function renderLayerInfo (layer) {
        if (layer) {
            return <div className="sc-main-tag-layer-component-contents">
                <div className="sc-main-tag-layer-component-content">
                    <LayerVisibility layer={layer} />
                    <LayerType layer={layer} />
                    <div>{layer.name}</div>
                </div>
                <LayerLock layer={layer} />
            </div>
        } else {
            return <div>{__('layer_error_layer_not_found')}</div>
        }
    }

    function toggleView () {
        dispatch({ type: 'toggle-layer-info-pane', payload: { showLayerInfoPane: !showLayerInfoPane } })
    }

    function onLayerSelectionChange (layers) {
        dispatch({ type: 'set-selected-layers', payload: { layers: layers } })
    }

    return (
        <React.Fragment>
            <div className="adobe-skycity-icon-button" onClick={ e => toggleView(e) }>
                <Layers size="S" />
                <Heading size={5} id="treeview-heading-id" className={showLayerInfoPane ? 'adobe-skycity-layer-info-wrapper-show-properties' : 'adobe-skycity-layer-info-wrapper-hide'}>{__('layer_info_title')}</Heading>
            </div>
            <div className="adobe-skycity-flex-column-psedit">
                <div className={showLayerInfoPane ? 'adobe-skycity-layer-info-wrapper-show-properties' : 'adobe-skycity-layer-info-wrapper-hide'}>
                    {inputFiles && inputFiles.length !== 0 && inputFiles[0].documentManifest && <div className={showLayerInfoPane ? 'adobe-skycity-layer-info-wrapper-show-properties' : 'adobe-skycity-layer-info-wrapper-hide'}>
                        <TreeView
                            aria-labelledby="gridview-heading-id"
                            className="ps-cep-reflow-treeview"
                            dataSource={ layerDs }
                            renderItem={ renderLayerInfo }
                            selectedItems={ selectedLayers }
                            expandItem={ selectedLayers }
                            allowsMultipleSelection
                            onSelectionChange={ selectedItems => onLayerSelectionChange(selectedItems)}
                            allowsSelection />
                    </div>}
                </div>
            </div>
        </React.Fragment>
    )
}
