import React, { useContext, createContext } from 'react'
import { SkycityContext } from '../../lib/SkycityProvider'
import Heading from '@react/react-spectrum/Heading'
import Well from '@react/react-spectrum/Well'
import { Tag, TagList } from '@react/react-spectrum/TagList'
import Alert from '@react/react-spectrum/Alert'

export default function SelectedLayersTag (props) {
    // const stepNum = props.stepNum ? `${props.stepNum} ` : '';
    const {
        selectedLayers,
        selectedInputFile,
        dispatch
    } = useContext(SkycityContext)

    const SelectedLayersTagContext = createContext()
    const SelectedLayersTagProvider = ({ children }) => {
        return (
            <SelectedLayersTagContext.Provider>
                {children}
            </SelectedLayersTagContext.Provider>
        )
    }

    function onClose (e) {
        const filteredItems = selectedLayers.filter(selectedLayer => {
            return selectedLayer.name !== e
        })
        dispatch({ type: 'set-removed-layers', payload: { layers: filteredItems } })
    }

    function getTags () {
        if (selectedInputFile &&
            selectedInputFile.documentManifest &&
            // selectedInputFile.documentManifest.layers &&
            selectedLayers && selectedLayers.length > 0) {
            let filteredLayers = selectedLayers
            if (props.type) {
                filteredLayers = selectedLayers.filter(layer => layer.type === props.type)
            }
            if (filteredLayers && filteredLayers.length > 0) {
                const selectedLayerTag = filteredLayers.map(layer => {
                    return <Tag closable key={layer.id} avatar={layer.thumbnail}>{layer.name}</Tag>
                })
                return <TagList onClose={e => onClose(e)}>
                    {selectedLayerTag}
                </TagList>
            } else {
                return <Alert header={'Not Selected'}>
                    {`Select ${props.type} layer(s) in Layer Pane`}
                </Alert>
            }
        } else {
            if (selectedInputFile && selectedInputFile.documentManifest && selectedInputFile.documentManifest.outputs && selectedInputFile.documentManifest.outputs[0].layers) {
                return <Alert variant="error" header={'Not Selected'}>
                    {`Select ${props.type} layer(s) in Layer Pane`}
                </Alert>
            }
        }
    }

    function SelectedLayerTagApp () {
        return <SelectedLayersTagProvider>
            <Well>
                <Heading variant="pageTitle">{`Selected ${props.type || ''} Layer(s)`}</Heading>
                {getTags()}
            </Well>
        </SelectedLayersTagProvider>
    }

    return <SelectedLayerTagApp />
}
