import { __ } from 'i18n-for-browser'
import '../config/i18n'
import axios from 'axios'
import {
    getContentType
} from './skycity'

const request = require('request')
require("dotenv").config();
const fetch = require("node-fetch");
const { v4: uuidv4 } = require("uuid");
const uriTemplateParser = require("uri-template");
var LinkHeader = require( 'http-link-header' )

const defaultHeaders = {
  "Cache-Control": "no-cache",
  Authorization: `Bearer ${window.adobeIMS.getAccessToken().token}`,
  'X-api-key': `${process.env.REACT_APP_CONFIG_APP_KEY}`
};

export async function fetchFromTemplateUri(uri, options, values = {}) {
  const tpl = uriTemplateParser.parse(uri);
  const url = tpl.expand(values);
  return await fetch(url, options);
}

// 1. Explore the Index Document and get the Primary URL of the home Directory
export async function exploreIndexDocument() {
    try
    {
        const indexUrl = `${process.env.REACT_APP_CONFIG_ENDPOINT_ACP_STORAGE}/index`
        const requestOptions = {
            method: "GET",
            headers: {
              "Content-Type": "application/vnd.adobecloud.directory+json",
              ...defaultHeaders,
            }
          };
          const response = await fetch(
            indexUrl,
            requestOptions
          );
          const data = await response.json();
          // The embedded Repository Metadata of the home Directory will be the only child element within the children array
          const homeDirectory = data.children[0];
          const primaryUrlArray =
            homeDirectory["_embedded"][
              "http://ns.adobe.com/adobecloud/rel/metadata/repository"
            ]["_links"]["http://ns.adobe.com/adobecloud/rel/primary"];
          // Find and return the path-based Primary URL
          const primaryPathObj = primaryUrlArray.find((el) => el.mode === "path");
          return primaryPathObj.href;
    }
    catch(err) {
        console.log(`ERROR: ${JSON.stringify(err)}`);
    }
}

// 2. Explore the home Directory and get the Create URL of the Cloud Documents Directory
export async function exploreHomeDirectory(primaryUrl) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/vnd.adobecloud.directory+json",
      ...defaultHeaders,
    },
    redirect: "follow",
  };
  const response = await fetch(primaryUrl, requestOptions);
  const data = await response.json();
  const cloudDocs = data.children.find(
    (el) => el["repo:name"] === "temp"
  );
  return {"createLink": cloudDocs["_links"]["http://ns.adobe.com/adobecloud/rel/create"][
    "href"
  ], tempUrn: cloudDocs["repo:assetId"]};
}

// 3. Create a Directory within the Cloud Documents Directory and return the Directory's links
export async function createDirectory(createUrl) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/vnd.adobecloud.directory+json",
      ...defaultHeaders,
    },
    redirect: "follow",
  };
  const response = await fetchFromTemplateUri(createUrl, requestOptions, {
    path: uuidv4(),
  });
  const links = LinkHeader.parse(response.headers.get("link"));
  return links;
}

export async function uploadFile(createUrl, fileToUpload) {
  return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async function(event) {
          try {
              const binaryData = event.target.result; 
              const tpl = uriTemplateParser.parse(createUrl);
              const url = tpl.expand({path: fileToUpload.name,});
              const response = await fetch(url, {
                  method: 'POST',
                  headers: {
                      'Access-Control-Allow-Origin': '*',
                      "Content-Type": getContentType(fileToUpload.name.split('.').pop()),
                      ...defaultHeaders
                  },
                  body: binaryData
              });
              if (!response.ok) {
                  throw new Error(`HTTP error! Status: ${response.status}`);
              }
              resolve(response);
          } catch (error) {
              reject(error);
          }
      };
      reader.onerror = function() {
          reject(new Error('Failed to read the file'));
      };
      reader.readAsArrayBuffer(fileToUpload);
  });
}
export async function retrieveRendition(renditionUrl, renditionSize, fileType) {
  const tpl = uriTemplateParser.parse(renditionUrl);
  let url;
  if(renditionSize !== 0)
  {
    url = tpl.expand({
      size: renditionSize,
    });
  }
  else
  {
    url = tpl.ast.parts[0].value;
  }
  const renditionResponse = url + `?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`;
  return renditionResponse;
}

export async function getLinks(assetId) {
  try {
    const linksUrl = `${process.env.REACT_APP_CONFIG_ENDPOINT_ACP_STORAGE}/links?assetId=${assetId}`
    const config = {
      timeout: 90000,
      headers: {
        ...defaultHeaders,
      }
    }
    const response = await axios.get(linksUrl, config);
    return response;
  } catch (e) {
    console.log(e)
    return e;
  }
}
