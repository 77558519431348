import React, { useContext } from 'react'

import { SkycityContext } from '../../lib/SkycityProvider'
import getThumbnails from '../../lib/cards.js'

import Wait from '@react/react-spectrum/Wait'
import Heading from '@react/react-spectrum/Heading'
import Well from '@react/react-spectrum/Well'

export default function OutputFiles ({
    title,
    outputFiles
}) {
    const {
        wait
    } = useContext(SkycityContext)

    if (wait && outputFiles && outputFiles.length > 0) {
        return <div>
            <Well>
                <Wait size="L" />
                {getThumbnails(outputFiles, 'adobe-skycity-half-opacity')}
            </Well>
        </div>
    } else if (outputFiles && outputFiles.length > 0) {
        return <div>
            <div className="adobe-skycity-input-file-number"><Heading variant="subtitle3">{`${outputFiles.length} file(s) Produced`}</Heading></div>
            <Well>
                {getThumbnails(outputFiles, 'adobe-skycity-full-opacity')}
            </Well>
        </div>
    } else {
        return null
    }
}
