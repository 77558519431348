import React, { useState } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'

import Heading from '@react/react-spectrum/Heading'
import Well from '@react/react-spectrum/Well'
import { error } from '@react/react-spectrum/Toast'

import OverlayTrigger from '@react/react-spectrum/OverlayTrigger'
import Tooltip from '@react/react-spectrum/Tooltip'
import FieldLabel from '@react/react-spectrum/FieldLabel'
import Textfield from '@react/react-spectrum/Textfield'

export default function SenseiParams (props) {
    function setText (name, value) {
        const slider = {}
        slider[name] = value
        props.setParams({ ...props.params, ...slider })
    }

    try {
        return <div className="adobe-skycity-output-options">
            <Well>
                <Heading variant="pageTitle">{__('skycity_comp_title_output_options')}</Heading>
                <React.Fragment>
                    <Heading className="adobe-skycity-subtitle1" variant="subtitle1">Sensei Params</Heading>
                    <Well>
                        <div className="adobe-skycity-options-col">
                            <div className="adobe-skycity-depthlab-option">
                                <FieldLabel className="adobe-skycity-options-field" label="X Coordinate" labelFor="idXCoord" position="left">
                                    <OverlayTrigger placement="right">
                                        <Textfield
                                            label="X Coordinate"
                                            defaultValue={0}
                                            value={props.params.x}
                                            onChange={e => setText('x', e)}
                                            id="idXCoord"
                                        />
                                        <Tooltip variant="info">
                                            {__('output_option_tooltip_xcoord_parametric_harmonization')}
                                        </Tooltip>
                                    </OverlayTrigger>
                                </FieldLabel>
                            </div>
                            <div className="adobe-skycity-depthlab-option">
                                <FieldLabel className="adobe-skycity-options-field" label="Y Coordinate" labelFor="idYCoord" position="left">
                                    <OverlayTrigger placement="right">
                                        <Textfield
                                            label="Y Coordinate"
                                            defaultValue={0}
                                            value={props.params.y}
                                            onChange={e => setText('y', e)}
                                            id="idYCoord"
                                        />
                                        <Tooltip variant="info">
                                            {__('output_option_tooltip_ycoord_parametric_harmonization')}
                                        </Tooltip>
                                    </OverlayTrigger>
                                </FieldLabel>
                            </div>
                        </div>
                    </Well>
                </React.Fragment>
            </Well>
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
