/* eslint-disable no-undef, no-restricted-globals, no-unused-expressions, no-sequences, no-use-before-define, new-parens */
import React, { useContext } from 'react'
import { SkycityContext } from '../../lib/SkycityProvider'

import Checkbox from '@react/react-spectrum/Checkbox'
import Select from '@react/react-spectrum/Select'
import FieldLabel from '@react/react-spectrum/FieldLabel'
import Well from '@react/react-spectrum/Well'
import Heading from '@react/react-spectrum/Heading'

export default function SelectUprightMode () {
    const {
        lrAutostraightenModes,
        lrEnableUprightOptions,
        dispatch
    } = useContext(SkycityContext)

    function isSelected () {
        return false
    }

    function changeActionByNameState (e) {
        dispatch({ type: 'set-lrEnableUprightOptions', payload: { lrEnableUprightOptions: e } })
    }

    function changeActionSelection (e) {
        const slectedUprightMode = setLrAutostraightenMode(lrAutostraightenModes, e)
        dispatch({ type: 'set-lrUprightMode', payload: { lrAutostraightenModes: slectedUprightMode } })
    }

    function setLrAutostraightenMode (lrAutostraightenOptions, lrAutostraightenMode) {
        lrAutostraightenOptions.forEach(option => {
            if (option.value === lrAutostraightenMode) {
                option.selected = true
            } else {
                option.selected = false
            }
        })
        return lrAutostraightenOptions
    }

    return <div className="adobe-skycity-output-options">
        <Well>
            <Heading variant="pageTitle">Apply Auto Straighten Options</Heading>
            <FieldLabel label="Select Upright Mode" labelFor="cbLrUprightMode" position="left">
                <Checkbox value={ isSelected() }
                    id="cbLrUprightMode"
                    onChange={ e => changeActionByNameState(e)}></Checkbox>
                <Select
                    disabled={!lrEnableUprightOptions}
                    options={lrAutostraightenModes}
                    onChange={e => changeActionSelection(e)}>
                </Select>
            </FieldLabel>
        </Well>
    </div>
}
