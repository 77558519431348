import {
    getHeaders,
    getStorage,
    getOptionsOutput
} from '../../lib/skycity.js'

export async function getConfig (curFile, outputOptions, iccProfileOption, curSecondFile, selectedLayers) {
    const payload = await getCodePayload(curFile, outputOptions, iccProfileOption, curSecondFile, selectedLayers)
    const config = {
        url: `${process.env.REACT_APP_CONFIG_ENDPOINT_PIE}/psdService/smartObject`,
        method: 'POST',
        timeout: 180000,
        headers: getHeaders(),
        payload: payload,
        body: JSON.stringify(payload)
    }
    return config
}

async function getCodePayload (curFile, outputOptions, iccProfileOption, curSecondFile, selectedLayers) {
    const layers = selectedLayers.filter(layer => layer.type === 'smartObject').map(layer => {
        return {
            id: layer.id,
            input: {
                href: curSecondFile.path || curSecondFile.href,
                storage: getStorage(curSecondFile)
            }
        }
    })
    const codePayload = {
        inputs: [
            {
                href: curFile.path,
                storage: getStorage(curFile)
            }
        ],
        options: {
            layers: layers
        },
        outputs: await getOptionsOutput(curFile, outputOptions, iccProfileOption)
    }
    return codePayload
}

// ************************************************************************
// Test Cases
// ************************************************************************
export async function getTestCases () {}
