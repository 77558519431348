import React, { useContext } from 'react'
import { SkycityContext } from '../../lib/SkycityProvider'
import ButtonGroup from '@react/react-spectrum/ButtonGroup'
import Button from '@react/react-spectrum/Button'
import ClassicGridView from '@react/react-spectrum/Icon/ClassicGridView'
import ViewList from '@react/react-spectrum/Icon/ViewList'

export default function FileViewOption () {
    const {
        dispatch,
        fileViewOption
    } = useContext(SkycityContext)

    function setViewOption (e) {
        dispatch({ type: 'set-file-view-option', payload: { vo: e } })
    }

    return <ButtonGroup aria-label="View Option" onClick={ e => setViewOption(e) }>
        <Button
            value="ClassicGridView"
            aria-label="ClassicGridView"
            icon={<ClassicGridView />}
            selected={fileViewOption === 'ClassicGridView'}/>
        <Button
            value="ListView"
            aria-label="ListView"
            icon={<ViewList />}
            selected={fileViewOption === 'ListView'}/>
    </ButtonGroup>
}
