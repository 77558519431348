const Desc = `documentManifest: Get document manifest for PSD in CC. ${new Error('To get file path').stack.split('\n')[1].match(/Object(.+)\(https/)[1] || ''}`

const Payload = {
    inputs: [{
        href: 'files/_skycity_smoketest/adobe2s.psd',
        storage: 'adobe'
    }],
    options: {
        thumbnails: {
            type: 'image/png'
        }
    }
}

module.exports = { Desc, Payload }
