import React, { createContext, useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import { error, info } from '@react/react-spectrum/Toast'
import Button from '@react/react-spectrum/Button'
import StatusLight from '@react/react-spectrum/StatusLight'
import Heading from '@react/react-spectrum/Heading'
import Alert from '@react/react-spectrum/Alert'
import { TableView } from '@react/react-spectrum/TableView'

import {
    getHealthCheckHeaders,
    getHelloApiHeaders,
    runHealthCheck,
    getHealthCheckHeadersNonCis,
    // getHealthCheckCurlOptions,
    copyText
} from '../../lib/skycity.js'

import axios from 'axios'

export default function Operation () {
    const {
        healthCheckResDs,
        errorOptions,
        dispatch
    } = useContext(SkycityContext)

    const ToolHealthCheckContext = createContext()
    const ToolHealthCheckContextProvider = ({ children }) => {
        return (
            <ToolHealthCheckContext.Provider>
                {children}
            </ToolHealthCheckContext.Provider>
        )
    }

    const result = []

    /* *********************************** */
    let endpoints = [
        { url: `${process.env.REACT_APP_CONFIG_ENDPOINT_PIE}/isitup`, method: 'GET', service: 'CIS', expected: 'si', statusCode: 200, headers: getHealthCheckHeaders() },
        { url: `${process.env.REACT_APP_CONFIG_ENDPOINT_ACR}/isitup`, method: 'GET', service: 'CIS', expected: 'si', statusCode: 200, headers: getHealthCheckHeaders() },
        { url: `${process.env.REACT_APP_CONFIG_ENDPOINT_SENSEI}/isitup`, method: 'GET', service: 'CIS', expected: 'si', statusCode: 200, headers: getHealthCheckHeaders() },
        { url: `${process.env.REACT_APP_CONFIG_ENDPOINT_PIE}/psdService/hello`, method: 'GET', service: 'CIS', expected: 'Welcome to the Photoshop API!', statusCode: 200, headers: getHelloApiHeaders() },
        { url: `${process.env.REACT_APP_CONFIG_ENDPOINT_IMS}`, method: 'HEAD', service: 'IMS', expected: '', statusCode: 200, headers: getHealthCheckHeadersNonCis() },
        { url: `${process.env.REACT_APP_CONFIG_ENDPOINT_CORS}/server-status/health`, method: 'HEAD', service: 'CC', expected: '', statusCode: 200, headers: getHealthCheckHeadersNonCis() },
        { url: `${process.env.REACT_APP_CONFIG_ENDPOINT_GATEWAY}`, method: 'HEAD', service: 'Gateway', expected: '', statusCode: 200, headers: getHealthCheckHeadersNonCis() }
    ]
    if (process.env.REACT_APP_CONFIG_ENDPOINT_IMAGE.match(/127/)) {
        endpoints = [
            { url: `${process.env.REACT_APP_CONFIG_ENDPOINT_IMAGE}/isitup`, headers: getHealthCheckHeaders() }
        ]
    }
    // { url: `${process.env.REACT_APP_CONFIG_ENDPOINT_PIESENSEI}`, headers: getSenseiHeaders() }
    // { url: 'https://ims-na1-dev1.adobelogin.com/ims/organizations/v6', headers: getImsOrgHeaders() }

    function start (endpoint) {
        const codePayload = {}
        const config = {
            url: endpoint.url,
            method: endpoint.method,
            timeout: 5000,
            headers: endpoint.headers
        }
        if (endpoint.method !== 'HEAD') {
            config.body = JSON.stringify(codePayload)
        }
        /* *********************************** */

        dispatch({ type: 'set-codes', payload: { codePayload: codePayload, config: config, codeEndpoint: config.url, codeMethod: config.method } })

        runHealthCheck(config, (res, error) => {
            console.log(`res: ${res}`)
            console.log(`error: ${error}`)
            result.push({
                url: endpoint.url,
                expected: endpoint.expected,
                statusCode: endpoint.statusCode,
                service: endpoint.service,
                res: res || error
            })
            dispatch({
                type: 'toolhealthcheck-success',
                payload: {
                    healthCheckRes: result,
                    loading: false,
                    wait: false,
                    response: res || error
                }
            })
        })
    }

    function run (e) {
        try {
            dispatch({ type: 'skycity-run', payload: {} })

            const funcArray = []
            endpoints.forEach(endpoint => {
                funcArray.push(start(endpoint))
            })
            axios.all(funcArray)
                .then((res) => {
                    // then
                })
                .catch((err) => {
                    const errMsg = `ERROR: ${JSON.stringify(err)}`
                    errorOccured(errMsg)
                })
                .finally(() => {
                    console.log('finally...')
                })
        } catch (e) {
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        }
    }

    function errorOccured (errMsg, res) {
        console.log(errMsg)
        error(errMsg.slice(0, errorOptions.wordSlice), {
            onClose: () => console.log('close'),
            timeout: errorOptions.msgTimeout
        })
        dispatch({ type: 'error-occured', payload: { errMsg: errMsg, response: res, loading: false, wait: false } })
    }

    function renderCell (column, data) {
        switch (column.key) {
        case 'result':
            if (data.res === data.expected || data.res.statusCode === data.statusCode) {
                return <StatusLight variant="celery" />
            } else if (data.service !== 'CIS' && data.res === 'Failed to fetch') {
                return <StatusLight variant="yellow" />
            } else {
                return <StatusLight variant="magenta" />
            }
        case 'url':
            return <span onClick={ e => copyCommand(data[column.key]) }>{data[column.key]}</span>
        default:
            return <span>{data[column.key]}</span>
        }
    }

    function getTable () {
        const columns = [
            {
                title: 'SERVICE',
                key: 'service',
                width: 80,
                divider: true
            },
            {
                title: 'Result',
                key: 'result',
                width: 60,
                divider: true
            },
            {
                title: 'URL',
                key: 'url',
                minWidth: 230,
                divider: true
            },
            {
                title: 'Response',
                key: 'res',
                minWidth: 230,
                divider: true
            }
        ]

        if (healthCheckResDs && healthCheckResDs.data && healthCheckResDs.data.length !== 0) {
            return <div className="adobe-skycity-table-container">
                <Alert variant="warning">CC, Gateway, IMS APIs are still in test phase.</Alert>
                <TableView
                    columns={columns}
                    rowHeight={ 36 }
                    dataSource={healthCheckResDs}
                    renderCell={renderCell} />
            </div>
        } else {
            return null
        }
    }

    function copyCommand (url) {
        // const msg = `curl -v ${url} ${getHealthCheckCurlOptions()} | jq`
        const msg = `curl -v ${url} | jq`
        copyText(msg)
        info(`Copied: ${msg}`)
    }

    function ToolHealthCheckApp () {
        try {
            return <ToolHealthCheckContextProvider>
                <Heading variant="pageTitle">{__('skycity_comp_title_health_check_table')}</Heading>
                <Button variant="cta" onClick={e => run(e)} >{__('btn_run')}</Button>
                <br /><br />
                { getTable() }
            </ToolHealthCheckContextProvider>
        } catch (e) {
            console.log(e)
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
        }
    }

    return <ToolHealthCheckApp />
}
