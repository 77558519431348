import React, { useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'
import { error } from '@react/react-spectrum/Toast'
import Wait from '@react/react-spectrum/Wait'
import Banner from '@react/react-spectrum/Banner'
import Link from '@react/react-spectrum/Link'

import Operation from './Operation'
import LayerInfo from './LayerInfo'

import SelectInput from '../Shared/SelectInput'
import Step from '../Shared/Step'
import Output from '../Shared/Output'
import FeatureNav from '../Shared/FeatureNav'

export default function Main () {
    const {
        inputFiles,
        inputFile,
        inputSecondFiles,
        inputSecondFile,
        outputFiles,
        response,
        codes,
        loading
    } = useContext(SkycityContext)

    try {
        return <div className="adobe-skycity-main-pane">
            { loading && <div className="adobe-skycity-modal-loading">
                <Wait size="L" />
                <div>{__('pocloc_loading_message')}</div>
            </div>
            }
            <div className="adobe-skycity-main">
                <div className="adobe-skycity-main-column">
                    <div className="adobe-skycity-main-feature">
                        <div>
                            <Banner header="" content={__('poc_feature')} variant="warning" />
                            <Link href={`${process.env.REACT_APP_CONFIG_URL_SKYCITY_ASSET}/demo/pocloc.gif`} target="_blank"> DEMO</Link>
                        </div>
                        <FeatureNav />
                        {/* **********************
                        Feature Input
                        ********************** */}
                        <Step num={1} title={__('skycity_comp_title_input')} />
                        <SelectInput
                            storage={['s3']}
                            actionType="set-input"
                            title="Select a PSD file that contains Text layer(s)"
                            multiple={false}
                            files={inputFiles}
                            file={inputFile}
                            imageTypes="image/vnd.adobe.photoshop"
                            documentManifest />
                        <br />
                        <SelectInput
                            storage={['s3']}
                            actionType="set-input-second"
                            title="Select font file(s)"
                            imageTypes="*.*,*,.otf,.ttf,.ttc,application/x-font-otf,application/x-font-ttf,application/x-font-ttc"
                            multiple={true}
                            files={inputSecondFiles}
                            file={inputSecondFile} />
                        {/* **********************
                        Feature Operation
                        ********************** */}
                        <Step num={2} title={__('skycity_comp_title_operation')} />
                        <Operation />
                        {/* **********************
                        Feature Output
                        ********************** */}
                        <Step num={3} title={__('skycity_comp_title_output')} />
                        <Output outputFiles={outputFiles} response={response} codes={codes} />
                    </div>
                    {/* **********************
                    LayerInfo
                    ********************** */}

                    <div className="adobe-skycity-layer-info">
                        <LayerInfo />
                    </div>
                </div>
            </div>
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
