import React, { createContext, useContext } from 'react'
import { SkycityContext } from '../../lib/SkycityProvider'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import AWS from 'aws-sdk'

import DropZone from '@react/react-spectrum/DropZone'
import DropdownButton from '@react/react-spectrum/DropdownButton'
import { MenuItem } from '@react/react-spectrum/Menu'
import { Asset } from '@react/react-spectrum/Asset'
import Button from '@react/react-spectrum/Button'
import ModalTrigger from '@react/react-spectrum/ModalTrigger'
import Delete from '@react/react-spectrum/Icon/Delete'
import { Card, CardBody, CardPreview, CardFooter } from '@react/react-spectrum/Card'
import SkycityProgress from './SkycityProgress'
import IllustratedMessage from '@react/react-spectrum/IllustratedMessage'
import UploadToCloud from '@react/react-spectrum/Icon/UploadToCloud'

import { error, warning } from '@react/react-spectrum/Toast'
import SortableContent from './SortableContent'

import filesize from 'filesize'

import configure from '@jimp/custom'
import tiff from '@jimp/tiff'

import {
    getFilenameWithoutExt,
    getRendition
} from '../../lib/skycity.js'

const Jimp = require('jimp')
const jimp = configure({ types: [tiff] }, Jimp)

export default function SelectInputLocal ({
    actionType,
    title,
    imageTypes,
    multiple,
    files,
    file,
    pFiles
}) {
    const {
        dispatch,
        wait,
        previewEdit,
        lrACRMaskOptions,
        lrACRPredictOptions
    } = useContext(SkycityContext)

    if (!files) {
        files = []
    }
    if (!pFiles) {
        pFiles = []
    }

    const SelectInputContext = createContext()
    const SelectInputProvider = ({ children }) => {
        return (
            <SelectInputContext.Provider>
                {children}
            </SelectInputContext.Provider>
        )
    }

    function getImageTypes () {
        // return imageTypes ? imageTypes : "image/*";
        return imageTypes || 'image/vnd.adobe.photoshop'
    }

    function upload (e) {
        document.getElementById(`fileUpload-${actionType}`).click()
    }

    function deleteAll (e) {
        dispatch({ type: actionType, payload: { file: [] } })
        if (actionType === 'set-input-local') {
            dispatch({ type: 'clear-output', payload: { } })
        }
    }

    async function clickUpload (e) {
        e.preventDefault()
        const file = e.target.files[0]
        if (lrACRMaskOptions && lrACRMaskOptions.referenceJobId) {
            delete lrACRMaskOptions.referenceJobId
            dispatch({ type: 'set-lrACRMaskOptions', payload: { lrACRMaskOptions: lrACRMaskOptions } })
        }

        if (lrACRPredictOptions) {
            dispatch({ type: 'set-lrACRPredictOptions', payload: { lrACRPredictOptions: lrACRPredictOptions } })
        }

        if (file.type == 'image/tiff') {
            const buffer = await file.arrayBuffer()
            const imgInput = await jimp.read(buffer)
            const base64StringInput = await imgInput.getBase64Async('image/jpeg')

            const curFile = {
                name: file.name,
                shortName: getFilenameWithoutExt(file.name),
                type: file.name.split('.').pop(),
                size: file.size,
                path: file.path,
                base64: base64StringInput,
                file: file
            }
            dispatch({ type: actionType, payload: { wait: false, files: [curFile], file: { progress: 100, name: file.name } } })
        } else {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                const curFile = {
                    name: file.name,
                    shortName: getFilenameWithoutExt(file.name),
                    type: file.name.split('.').pop(),
                    size: file.size,
                    path: file.path,
                    base64: reader.result,
                    file: file
                }
                dispatch({ type: actionType, payload: { wait: false, files: [curFile], file: { progress: 100, name: file.name } } })
            }
        }
    }

    function onDrop (e) {
        e.preventDefault()
    }

    function onDragOver (e) {
        e.preventDefault()
    }

    function getFileSize (num) {
        if (num) {
            return filesize(num, { round: 0 })
        } else {
            return ''
        }
    }

    function removeFile (e, path) {
        for (let i = 0; i < files.length; i++) {
            if (files[i].path === path) {
                files.splice(i, 1)
                i--
            }
        }
        if (files.length === 0) {
            deleteAll()
        } else {
            dispatch({ type: actionType, payload: { wait: false, files: files, file: { progress: 0, name: '' } } })
            dispatch({
                type: 'set-input-preview',
                payload: {
                    files: files,
                    loading: false,
                    wait: false,
                    response: [],
                    layers: []
                }
            })
            dispatch({ type: 'clear-output', payload: { } })
        }
    }

    function getCardFooter (file) {
        return <CardFooter><div className="adobe-skycity-flex-row-space-between">{file.storage}</div></CardFooter>
    }
    function getCardBody (file) {
        return <CardBody title={file.name} subtitle={getFileType(file)} description={getFileSize(file.size)} />
    }

    function getThumbnail (files, type) {
        const cards = files.map(file => {
            return <div className="adobe-skycity-asset-card" key={file.path}>
                <Card
                    allowsSelection={false}
                    key={file.name}
                    actionMenu={
                        <DropdownButton onSelect={e => removeFile(e, file.path)}>
                            <MenuItem>Remove</MenuItem>
                        </DropdownButton>
                    }>
                    <CardPreview>
                        <ModalTrigger>
                            <Asset
                                type="image"
                                cache={true}
                                src={getRendition(file, 300, type)} />
                        </ModalTrigger>
                    </CardPreview>
                    { getCardBody(file) }
                    { getCardFooter(file) }
                </Card>
            </div>
        })
        return cards
    }

    function getFileType (file) {
        return file.type || file.content_type
    }

    function getThumbnails (files, opacity, type) {
        const regex = /(text\/plain|xmp)/ig
        if (files && files[0]) {
            if (files[0].s3_id) {
                return <div className="adobe-skycity-thumbnail-container">
                    { getThumbnail(files, type) }
                </div>
            } else if ((files[0].type && files[0].type.match(regex)) || (files[0].content_type && files[0].content_type.match(regex))) {
                return <SortableContent actionType={actionType} files={files}/>
            } else {
                return <div className="adobe-skycity-thumbnail-container">
                    { getThumbnail(files, type) }
                </div>
            }
        }
        return null
    }

    function SelectInputFile () {
        try {
            if (wait && file && file.name) {
                return <SelectInputProvider>
                    <div className="spectrum-Dropzone">
                        <IllustratedMessage
                            className="adobe-skycity-half-opacity"
                            heading={title} /><br /><br />
                        <SkycityProgress upprogress={file.progress} caption={`${__('uploading')} ${file.name}\u2026`} />
                        { getThumbnails(files, 'adobe-skycity-half-opacity') }
                    </div>
                </SelectInputProvider>
            } else if (files && files.length !== 0) {
                return <SelectInputProvider>
                    <div className="spectrum-Dropzone">
                        <IllustratedMessage
                            heading={title} /><br /><br />
                        <div className="adobe-skycity-flex-row-space-between">
                            { getThumbnails(files, 'adobe-skycity-full-opacity') }
                            { previewEdit ? getThumbnails(pFiles, 'adobe-skycity-full-opacity', 'path') : null }
                        </div>
                        <input type="file"
                            name="file"
                            id={`fileUpload-${actionType}`}
                            className="adobe-skycity-hide"
                            accept={ getImageTypes(imageTypes) }
                            onChange={ e => clickUpload(e) }
                        />
                        { !multiple && <div className="adobe-skycity-icon-upload-more">
                            <Button variant="tool" icon={<Delete size="S"/>} onClick={ e => deleteAll(e) } />
                        </div>}
                    </div>
                </SelectInputProvider>
            } else {
                // console.log(`ccBrowserAllowsMultipleSelection: ${ccBrowserAllowsMultipleSelection}`);
                // console.log(`imageTypes: ${imageTypes}`);
                return <SelectInputProvider>
                    <div>
                        <DropZone
                            onDrop={ e => onDrop(e) }
                            onDragOver={ e => onDragOver(e) }>
                            <IllustratedMessage
                                heading={title} /><br /><br />
                            <div className="adobe-skycity-dropzone">
                                <IllustratedMessage
                                    heading=""
                                    description={__('select_input_files_title_upload')}
                                    illustration={<UploadToCloud size="L"/>}
                                    onClick={ e => upload(e) } />
                            </div>
                            <input type="file"
                                name="file"
                                id={`fileUpload-${actionType}`}
                                className="adobe-skycity-hide"
                                accept={ getImageTypes(imageTypes) }
                                onChange={ e => clickUpload(e) }
                                multiple={multiple} />
                        </DropZone>
                    </div>
                </SelectInputProvider>
            }
        } catch (e) {
            console.log(e)
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
        }
    }

    return <SelectInputFile />
}
