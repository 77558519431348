const Desc = `documentManifest: Get document manifest for PSD in AWS. ${new Error('To get file path').stack.split('\n')[1].match(/Object(.+)\(https/)[1] || ''}`

const Payload = {
    inputs: [{
        href: 'https://skycity-asset.adobedice.com/testing/pits/input/adobe2s.psd',
        storage: 'external'
    }],
    options: {
        thumbnails: {
            type: 'image/png'
        }
    }
}

module.exports = { Desc, Payload }
