import {
    getHeaders,
    getStorage
} from '../../lib/skycity.js'

import { getParams } from '../params/psDocumentManifest.param.js'
import { prepareBodyForExternal } from './testHelper.js'

export function getConfig (curFile, outputOptions, iccProfileOption, typeOptions) {
    const payload = getCodePayload(curFile, outputOptions, iccProfileOption, typeOptions)
    const config = {
        url: `${process.env.REACT_APP_CONFIG_ENDPOINT_PIE}/psdService/documentManifest`,
        method: 'POST',
        timeout: 90000,
        headers: getHeaders(),
        payload: payload,
        body: JSON.stringify(payload)
    }
    return config
}

function getCodePayload (curFile, isThumbnail, typeOptions) {
    const codePayload = {
        inputs: [{
            href: curFile.path || curFile.href,
            storage: getStorage(curFile)
        }]
    }
    if (isThumbnail) {
        const options = {
            thumbnails: {
                type: typeOptions.find(typeOption => typeOption.selected === true).value
            }
        }
        codePayload.options = options
    }
    return codePayload
}

// ************************************************************************
// Test Cases
// ************************************************************************
export async function getTestCases (storages) {
    const testCases = await getParams(storages)
    const newTestCases = await Promise.all(testCases.map(async (testCase) => {
        testCase = await prepareBodyForExternal(testCase)
        testCase.area = 'Photoshop API'
        testCase.feature = 'psDocumentManifest'
        return testCase
    }))
    return newTestCases
}
