import React, { useContext } from 'react'

import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import { FormItem } from '@react/react-spectrum/Form'
import Select from '@react/react-spectrum/Select'
import { error } from '@react/react-spectrum/Toast'

import { getFilename, getFilenameWithoutExt, getDocumentOperationsConfig, postDocumentOperations, getPreviewFileDocumentManifest } from '../../lib/skycity.js'

export default function FormLayerBlendMode (props) {
    const {
        previewFiles,
        errorOptions,
        blendModes,
        dispatch
    } = useContext(SkycityContext)

    const actionType = 'set-input-preview'
    const previewFileDocumentManifest = getPreviewFileDocumentManifest(previewFiles)

    function errorOccured (errMsg, res) {
        console.log(errMsg)
        error(errMsg.slice(0, errorOptions.wordSlice), {
            onClose: () => console.log('close'),
            timeout: errorOptions.timeout
        })
        dispatch({ type: 'error-occured', payload: { errMsg: errMsg, response: res, loading: false, wait: false } })
    }

    function getSelectedLayers (newDocumentManifest, cb) {
        return newDocumentManifest.forEach(function iter (a) {
            if ([props.curLayer.id].includes(a.id)) {
                cb(a)
            }
        })
    }

    function changeValue (e, curLayer) {
        if (e === curLayer.blendOptions.mode) {
            return
        }
        dispatch({
            type: 'skycity-psedit-run',
            payload: {
                loading: true,
                wait: true
            }
        })
        const layers = [
            {
                edit: {},
                blendOptions: {
                    blendMode: e
                },
                id: curLayer.id
            }
        ]
        getDocumentOperationsConfig(previewFiles[0], { layers: layers }, config => {
            if (config) {
                try {
                    dispatch({ type: 'set-codes', payload: { config } })
                    postDocumentOperations(previewFiles[0], previewFileDocumentManifest, config, (res, err, files) => {
                        if (res && !err) {
                            getSelectedLayers(files[0].documentManifest.outputs[0].layers, newLayers => {
                                files[0].name = getFilename(files[0].href)
                                files[0].shortName = getFilenameWithoutExt(files[0].href)
                                dispatch({
                                    type: actionType,
                                    payload: {
                                        files: files,
                                        loading: false,
                                        wait: false,
                                        response: res,
                                        layers: [newLayers]
                                    }
                                })
                            })
                        } else {
                            errorOccured(`${__('browser_cc_get_document_manifest_error')}: ${err}`)
                        }
                    })
                } catch (e) {
                    errorOccured(`${__('getDocumentOperations_error')}: ${e}`)
                }
            }
        })
    }

    function getDefaultValue () {
        if (props.curLayer && props.curLayer.blendOptions) {
            return props.curLayer.blendOptions.mode
        }
    }

    return <FormItem label={props.label}>
        <Select
            options={blendModes}
            defaultValue={ getDefaultValue() }
            onChange={ e => changeValue(e, props.curLayer)}/>
    </FormItem>
}
