import React from 'react'
import Link from '@react/react-spectrum/Link'

export default function NavLink ({ label, url, stageUrl }) {
    return (
        <div className="adobe-skycity-header-link">
            {!stageUrl || url === stageUrl
                ? <Link target="_blank" href={url}>{label}</Link>
                : <React.Fragment>
                    {label}
                    <Link target="_blank" href={url}> [Prod]</Link>
                    <Link target="_blank" href={stageUrl}> [Stage]</Link>
                </React.Fragment>
            }
        </div>
    )
}
