import React, { useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import Heading from '@react/react-spectrum/Heading'
import Well from '@react/react-spectrum/Well'
import Checkbox from '@react/react-spectrum/Checkbox'

export default function ACRPredictOptions (props) {
    const {
        dispatch,
        lrACRPredictOptions
    } = useContext(SkycityContext)

    function changeValue (e, requestType) {
        const maskOptions = { ...lrACRPredictOptions }
        if (e) {
            maskOptions.requestTypes = maskOptions.requestTypes.concat(requestType)
        } else {
            var index = maskOptions.requestTypes.indexOf(requestType)
            if (index > -1) {
                maskOptions.requestTypes.splice(index, 1)
            }
        }
        dispatch({ type: 'set-lrACRPredictOptions', payload: { lrACRPredictOptions: maskOptions } })
    }

    return <div className="adobe-skycity-output-options">
        <Well>
            <Heading variant="pageTitle">{__('skycity_comp_title_lrmask_options')}</Heading>
            <br />
            <div className="adobe-skycity-output-options-file-format">
                <Well>
                    <Checkbox label="SelectSubject" value="selectSubject" defaultChecked="true" onChange={e => changeValue(e, 'selectSubject')}/>
                    <Checkbox label="SelectSky" value="selectSky" defaultChecked="true" onChange={e => changeValue(e, 'selectSky')}/>
                    <Checkbox label="SelectSkin" value="selectSkin" defaultChecked="true" onChange={e => changeValue(e, 'selectSkin')}/>
                    <Checkbox label="DepthMap" value="depthMap" defaultChecked="true" onChange={e => changeValue(e, 'depthMap')}/>
                </Well>
            </div>
        </Well>
    </div>
}
