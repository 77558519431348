import React from 'react'
import Key from '@react/react-spectrum/Icon/Key'
import { success } from '@react/react-spectrum/Toast'

export default function TokenLink ({ className }) {
    function getToken () {
        const el = document.createElement('textarea')
        el.value = window.adobeIMS.getAccessToken().token
        el.setAttribute('readonly', '')
        el.style.position = 'absolute'
        el.style.left = '-9999px'
        document.body.appendChild(el)
        el.select()
        document.execCommand('copy')
        document.body.removeChild(el)
        success('Your token is copied.', {
            actionLabel: 'Log in console',
            onAction: (e) => console.log(window.adobeIMS.getAccessToken().token),
            timeout: 5000
        })
    }

    return (
        <div className="adobe-skycity-header-icon-div" onClick={ e => getToken(e) }>
            <Key className="adobe-skycity-header-icon" size="M"/>
        </div>
    )
}
