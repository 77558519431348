import React from 'react'
import SkycityProvider, { SkycityConsumer } from '../../lib/SkycityProvider'

import Nav from './Nav'
import Feature from './Feature'

export default function Main ({ page }) {
    return <div className="adobe-skycity-page-main">
        <SkycityProvider>
            <SkycityConsumer>
                {({ page }) => (
                    <React.Fragment>
                        <Nav page={page} />
                        <Feature page={page} />
                    </React.Fragment>
                )}
            </SkycityConsumer>
        </SkycityProvider>
    </div>
}
