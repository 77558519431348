import React from 'react'
import Heading from '@react/react-spectrum/Heading'

export default function Step ({ num, title }) {
    function getNumber (num) {
        switch (num) {
        case 1:
            return '\u2776'
        case 2:
            return '\u2777'
        case 3:
            return '\u2778'
        case 4:
            return '\u2779'
        case 5:
            return '\u2780'
        default:
            return ''
        }
    }
    return (
        <Heading variant="pageTitle" className="adobe-skycity-step-title">{getNumber(num)} {title}</Heading>
    )
}
