import { setParams } from './psDocumentManifest/index.js'

export function getParams (storages) {
    const testCases = []
    storages.forEach(storage => {
        const tc = getTestCases(storage.value)
        testCases.push(...tc)
    })
    return testCases
}

function getTestCases (storage) {
    const sharedParams = setParams(storage)
    const options = sharedParams.map(param => {
        return {
            body: param.payload,
            desc: param.desc
        }
    })
    return options
}
