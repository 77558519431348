import React, { useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import Wait from '@react/react-spectrum/Wait'
import { error } from '@react/react-spectrum/Toast'

import Operation from './Operation'

import SelectInput from '../Shared/SelectInput'
import Step from '../Shared/Step'
import Output from '../Shared/Output'
import FeatureNav from '../Shared/FeatureNav'

export default function Main () {
    const {
        inputFiles,
        inputFile,
        outputFiles,
        response,
        codes,
        loading
    } = useContext(SkycityContext)

    try {
        return <div className="adobe-skycity-main-pane">
            { loading && <div className="adobe-skycity-modal-loading">
                <Wait size="L" />
                <div>{__('lrautotone_loading_message')}</div>
            </div>}
            <div className="adobe-skycity-main">
                <div className="adobe-skycity-main-column">
                    <div className="adobe-skycity-main-feature">
                        <FeatureNav />
                        {/* **********************
                        Input
                        ********************** */}
                        <Step num={1} title={__('skycity_comp_title_input')} />
                        <SelectInput
                            storage={['as', 's3']}
                            actionType="set-input"
                            title="Select file(s)"
                            multiple={true}
                            files={inputFiles}
                            file={inputFile}
                            imageTypes=".cr2,image/png,image/tiff,image/jpeg,image/vnd.adobe.photoshop,image/*" />
                        {/* **********************
                        Operation
                        ********************** */}
                        <Step num={2} title={__('skycity_comp_title_operation')} />
                        <Operation />
                        {/* **********************
                        Output
                        ********************** */}
                        <Step num={3} title={__('skycity_comp_title_output')} />
                        <Output outputFiles={outputFiles} response={response} codes={codes} />
                    </div>
                </div>
            </div>
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
