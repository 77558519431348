import React, { useReducer } from 'react'
import analytics from '../config/analytics'
import TreeDataSource from '@react/react-spectrum/TreeDataSource'
import ListDataSource from '@react/react-spectrum/ListDataSource'
export const SkycityContext = React.createContext()
export const SkycityConsumer = SkycityContext.Consumer
// const uuid = require('uuid/v4');

const getRef = () => {
    const curUrl = new URL(document.location.href)
    const curRef = curUrl.searchParams.get('ref')
    analytics.sendPageName(curRef)
    switch (curRef) {
    case 'home':
    case 'psfileconversion':
    case 'psinsertlayer':
    case 'pssmartobject':
    case 'pssmartobjectV2':
    case 'pssmartobjectCsv':
    case 'pssocialmediarendition':
    case 'psdocumentmanifest':
    case 'psedit':
    case 'pscrop':
    case 'psreflowcep':
    case 'psreflowweb':
    case 'psactions':
    case 'psartboard':
    case 'pstext':
    case 'psuxp':
    case 'psproductcrop':
    case 'pocloc':
    case 'lrautotone':
    case 'lrmasking':
    case 'lrmaskingpredict':
    case 'lrautostraighten':
    case 'lrapplypreset':
    case 'lrapplyedit':
    case 'ssimagecutout':
    case 'ssimagecutoutonsensei':
    case 'ssimagecutoutonsenseiasync':
    case 'ssbodyparser':
    case 'ssdepthestimation':
    case 'ssinteractiveselection':
    case 'ssobjectselection':
    case 'ssdomo':
    case 'sslayerscene':
    case 'ssswappingautoencoder':
    case 'sscmganholefilling':
    case 'ssshadowdetection':
    case 'ssshadowremoval':
    case 'ssshadowgenerator':
    case 'sstrimapmatting':
    case 'ssuniversalrefinement':
    case 'ssmaskrefinement':
    case 'ssselectall':
    case 'ssmetacaf':
    case 'ssentitysegmentation':
    case 'sssegmentanything':
    case 'ssparametricharmonization':
    case 'sspieonsensei':
    case 'ssautotag':
    case 'ssgeneration':
    case 'ssacrmasking':
    case 'ssllava':
    case 'ssdistractorice':
    case 'ssstuffsegmentation':
    case 'template':
    case 'toolhealthcheck':
    case 'tooltest':
    case 'onboarding':
        return curRef
    default:
        return 'home'
    }
}

const gCache = {}

const initialState = {
    page: getRef(),
    appProgress: false,
    action: null,
    group: null,
    wait: false,
    codes: [],
    codesLinux: [],
    codesSideBySide: [],
    response: [],
    responseLinux: [],
    responseSideBySide: [],
    spinnerTimeout: 1500,
    cache: gCache,
    previewFiles: [],
    previewEdit: false,
    nonPreviewOptions: {},
    selectedInputFile: null,
    inputFiles: [],
    inputFile: { progress: 0, name: '' },
    inputSecondFiles: [],
    inputSecondFile: { progress: 0, name: '' },
    inputThirdFiles: [],
    inputThirdFile: { progress: 0, name: '' },
    inputIccFiles: [],
    inputIccFile: { progress: 0, name: '' },
    outputFiles: [],
    selectedLayers: [],
    showNavPane: true,
    showLayerInfoPane: false,
    showCcBrowserPane: false,
    showCcBrowserPaneFont: false,
    showCcBrowserPaneSecond: false,
    showCcBrowserPaneIcc: false,
    showCloudContentBrowserPane: false,
    showCloudContentBrowserPaneSecond: false,
    showCloudContentBrowserPaneIcc: false,
    showS3BrowserPane: false,
    showS3BrowserPaneSecond: false,
    showS3BrowserPaneFont: false,
    showS3BrowserPaneIcc: false,
    showAsBrowserPane: false,
    showAsBrowserPaneSecond: false,
    showAsBrowserPaneIcc: false,
    layerDs: [],
    txtLayers: [],
    txtLayersDs: [],
    layerReflowDs: [],
    loading: false,
    ccFiles: null,
    dsCcFiles: null,
    cloudContentFiles: null,
    dsCloudContentFiles: null,
    asFiles: null,
    dsAsFiles: null,
    s3Files: null,
    dsS3Files: null,
    error: null,
    errorLinux: null,
    errorSideBySide: null,
    errMsg: null,
    apiStatus: '',
    locFrom: 'en',
    testResult: [],
    testResultDs: [],
    insertLayerAsSmartObject: true,
    fontName: '',
    fileViewOption: null,
    psWasm: null,
    layerMappings: {},
    // ccLayout: new GridLayout({
    //     idealRowHeight: 10,
    //     margin: 400,
    //     maxColumns: 4,
    //     maxItemSize: {
    //         width: 50,
    //         height: 50
    //     }
    // }),
    errorOptions: {
        msgTimeout: 0,
        wordSlice: 500
    },
    outputJpegOption: {
        format: 'jpeg',
        selected: true,
        type: 'image/jpeg',
        quality: [
            { label: '1', value: 1, selected: false },
            { label: '2', value: 2, selected: false },
            { label: '3', value: 3, selected: false },
            { label: '4', value: 4, selected: false },
            { label: '5', value: 5, selected: false },
            { label: '6', value: 6, selected: false },
            { label: '7', value: 7, selected: true }
        ],
        disabled: false,
        width: 0
    },
    outputJpegOptionLr: {
        format: 'jpeg',
        selected: true,
        type: 'image/jpeg',
        quality: [
            { label: '0', value: 0, selected: false },
            { label: '1', value: 1, selected: false },
            { label: '2', value: 2, selected: false },
            { label: '3', value: 3, selected: false },
            { label: '4', value: 4, selected: false },
            { label: '5', value: 5, selected: false },
            { label: '6', value: 6, selected: false },
            { label: '7', value: 7, selected: false },
            { label: '8', value: 8, selected: false },
            { label: '9', value: 9, selected: false },
            { label: '10', value: 10, selected: false },
            { label: '11', value: 11, selected: false },
            { label: '12', value: 12, selected: true }
        ],
        disabled: false,
        width: 0
    },
    psActionFile: {
        selected: false,
        actions: []
        // actions: [
        //     { label: 'actionname1', value: 'actionname1', selected: true },
        //     { label: 'actionname2', value: 'actionname2', selected: false }
        // ]
    },
    productCropOptions: {
        units: [
            { label: 'Pixels', value: 'Pixels', selected: true },
            { label: 'Percent', value: 'Percent', selected: false }
        ],
        height: 0,
        width: 0
    },
    tableViewColumnsCc: [
        {
            title: '',
            key: 'thumbnail',
            width: 70,
            align: 'center',
            divider: false
        },
        {
            title: 'Name',
            key: 'name',
            maxWidth: 500,
            divider: true
        },
        {
            title: 'Size',
            key: 'size',
            width: 100,
            align: 'right',
            divider: true
        },
        {
            title: 'Type',
            key: 'type',
            width: 300,
            divider: true
        },
        {
            title: 'Path',
            key: 'path',
            width: 300
        }
    ],
    tableViewColumnsS3: [
        {
            title: '',
            key: 'thumbnail',
            width: 70,
            align: 'center',
            divider: false
        },
        {
            title: 'Name',
            key: 'name',
            maxWidth: 500,
            divider: true
        },
        {
            title: 'Size',
            key: 'size',
            width: 100,
            align: 'right',
            divider: true
        },
        {
            title: 'Type',
            key: 'content_type',
            width: 300,
            divider: true
        },
        {
            title: 'Path',
            key: 'path',
            width: 300
        }
    ],
    outputPngOption: {
        format: 'png',
        selected: true,
        type: 'image/png',
        compression: [
            {
                label: 'small',
                value: 'small',
                selected: false
            },
            {
                label: 'medium',
                value: 'medium',
                selected: false
            },
            {
                label: 'large',
                value: 'large',
                selected: true
            }
        ],
        disabled: false,
        width: 0
    },
    outputTiffOption: {
        format: 'tiff',
        selected: true,
        type: 'image/tiff',
        disabled: false,
        width: 0
    },
    outputPsdOption: {
        format: 'psd',
        selected: true,
        type: 'image/vnd.adobe.photoshop',
        disabled: false,
        width: 0
    },
    outputDngOption: {
        format: 'dng',
        selected: true,
        type: 'image/x-adobe-dng',
        disabled: false,
        width: 0
    },
    outputOptions: [
        {
            id: '1',
            format: 'jpeg',
            selected: true,
            type: 'image/jpeg',
            quality: [
                { label: '1', value: 1, selected: false },
                { label: '2', value: 2, selected: false },
                { label: '3', value: 3, selected: false },
                { label: '4', value: 4, selected: false },
                { label: '5', value: 5, selected: false },
                { label: '6', value: 6, selected: false },
                { label: '7', value: 7, selected: true }
            ],
            disabled: false,
            width: 0
        }
    ],
    outputOptionsLr: [
        {
            id: '1',
            format: 'jpeg',
            selected: true,
            type: 'image/jpeg',
            quality: [
                { label: '0', value: 0, selected: false },
                { label: '1', value: 1, selected: false },
                { label: '2', value: 2, selected: false },
                { label: '3', value: 3, selected: false },
                { label: '4', value: 4, selected: false },
                { label: '5', value: 5, selected: false },
                { label: '6', value: 6, selected: false },
                { label: '7', value: 7, selected: false },
                { label: '8', value: 8, selected: false },
                { label: '9', value: 9, selected: false },
                { label: '10', value: 10, selected: false },
                { label: '11', value: 11, selected: false },
                { label: '12', value: 12, selected: true }
            ],
            disabled: false,
            width: 0
        }
    ],
    iccProfileOption: {
        label: 'Color Mode',
        selected: false,
        imageMode: [
            { label: 'RGB', value: 'rgb', selected: true },
            { label: 'CMYK', value: 'cmyk', selected: false },
            { label: 'Grayscale', value: 'grayscale', selected: false }
        ],
        profileName: {
            selected: true,
            rgb: [
                { label: 'Adobe RGB (1998)', value: 'Adobe RGB (1998)', selected: true },
                { label: 'Apple RGB', value: 'Apple RGB', selected: false },
                { label: 'ColorMatch RGB', value: 'ColorMatch RGB', selected: false },
                { label: 'sRGB IEC61966-2.1', value: 'sRGB IEC61966-2.1', selected: false }
            ],
            grayscale: [
                { label: 'Dot Gain 10%', value: 'Dot Gain 10%', selected: false },
                { label: 'Dot Gain 15%', value: 'Dot Gain 15%', selected: false },
                { label: 'Dot Gain 20%', value: 'Dot Gain 20%', selected: false },
                { label: 'Dot Gain 25%', value: 'Dot Gain 25%', selected: false },
                { label: 'Dot Gain 30%', value: 'Dot Gain 30%', selected: true },
                { label: 'Gray Gamma 1.8', value: 'Gray Gamma 1.8', selected: false },
                { label: 'Gray Gamma 2.2', value: 'Gray Gamma 2.2', selected: false }
            ]
        },
        profileFile: {
            selected: false,
            input: {
                href: '',
                storage: ''
            }
        }
    },
    layerOptions: [],
    templateOptions: {
        numberInput: 10,
        inlineEditor: 'hello',
        checkbox: true,
        textfieldNumberOne: 100,
        textfieldNumberTwo: 200
    },
    fileTypeOptions: [
        { label: 'image/png', value: 'image/png', selected: true },
        { label: 'image/jpeg', value: 'image/jpeg', selected: false },
        { label: 'image/tiff', value: 'image/tiff', selected: false }
    ],
    insertLayerTo: [
        { label: 'insertAbove', value: 'insertAbove' },
        { label: 'insertBelow', value: 'insertBelow' },
        { label: 'insertInto', value: 'insertInto' },
        { label: 'insertTop', value: 'insertTop' },
        { label: 'insertBottom', value: 'insertBottom' }
    ],
    adjustmentLayers: {
        brightnessContrast: {
            selected: true,
            disabled: false,
            type: 'brightnessContrast',
            label: 'Brightness Contrast',
            props: [
                {
                    label: 'Brightness',
                    type: 'brightness',
                    field: 'number',
                    min: -150.0,
                    max: 150.0,
                    value: 0.0,
                    step: 1,
                    disabled: false
                },
                {
                    label: 'Contrast',
                    type: 'contrast',
                    field: 'number',
                    min: -150.0,
                    max: 150.0,
                    value: 0.0,
                    step: 1,
                    disabled: false
                }
            ]
        },
        exposure: {
            selected: true,
            disabled: false,
            type: 'exposure',
            label: 'Exposure',
            props: [
                {
                    label: 'Exposure',
                    type: 'exposure',
                    field: 'number',
                    min: -20.0,
                    max: 20.0,
                    value: 0.0,
                    step: 0.01,
                    disabled: false
                },
                {
                    label: 'Offset',
                    type: 'offset',
                    field: 'number',
                    min: -0.5,
                    max: 0.5,
                    value: 0.0,
                    step: 0.1,
                    disabled: false
                },
                {
                    label: 'Gamma Correction',
                    type: 'gammaCorrection',
                    field: 'number',
                    min: 0.01,
                    max: 9.99,
                    value: 1.0,
                    step: 0.01,
                    disabled: false
                }
            ]
        },
        hueSaturation: {
            selected: true,
            disabled: false,
            type: 'hueSaturation',
            label: 'Hue/Saturation',
            channels: [
                {
                    label: 'Colorize',
                    field: 'checkbox',
                    type: 'colorize',
                    disabled: false,
                    value: true
                },
                {
                    label: 'hue',
                    type: 'hue',
                    field: 'number',
                    min: -180,
                    max: 180,
                    value: 0,
                    step: 1,
                    disabled: false
                },
                {
                    label: 'saturation',
                    type: 'saturation',
                    field: 'number',
                    min: -100,
                    max: 100,
                    value: 0,
                    step: 1,
                    disabled: false
                },
                {
                    label: 'lightness',
                    type: 'lightness',
                    field: 'number',
                    min: -100,
                    max: 100,
                    value: 0,
                    step: 1,
                    disabled: false
                }
            ]
        },
        colorBalance: {
            selected: true,
            disabled: false,
            type: 'colorBalance',
            label: 'Color Balance',
            props: [
                {
                    label: 'Preserve Luminosity',
                    field: 'checkbox',
                    type: 'preserveLuminosity',
                    disabled: false,
                    value: true
                },
                {
                    label: 'Shadow Levels',
                    type: 'shadowLevels',
                    field: 'array',
                    colors: [
                        {
                            label: 'Cyan',
                            type: 'cyan',
                            field: 'number',
                            min: -100,
                            max: 100,
                            value: 0,
                            step: 1,
                            disabled: false
                        },
                        {
                            label: 'Magenta',
                            type: 'magenta',
                            field: 'number',
                            min: -100,
                            max: 100,
                            value: 0,
                            step: 1,
                            disabled: false
                        },
                        {
                            label: 'Yellow',
                            type: 'yellow',
                            field: 'number',
                            min: -100,
                            max: 100,
                            value: 0,
                            step: 1,
                            disabled: false
                        }
                    ]
                },
                {
                    label: 'Midtone Levels',
                    type: 'midtoneLevels',
                    field: 'array',
                    colors: [
                        {
                            label: 'Cyan',
                            type: 'cyan',
                            field: 'number',
                            min: -100,
                            max: 100,
                            value: 0,
                            step: 1,
                            disabled: false
                        },
                        {
                            label: 'Magenta',
                            type: 'magenta',
                            field: 'number',
                            min: -100,
                            max: 100,
                            value: 0,
                            step: 1,
                            disabled: false
                        },
                        {
                            label: 'Yellow',
                            type: 'yellow',
                            field: 'number',
                            min: -100,
                            max: 100,
                            value: 0,
                            step: 1,
                            disabled: false
                        }
                    ]
                },
                {
                    label: 'Highlight Levels',
                    type: 'highlightLevels',
                    field: 'array',
                    colors: [
                        {
                            label: 'Cyan',
                            type: 'cyan',
                            field: 'number',
                            min: -100,
                            max: 100,
                            value: 0,
                            step: 1,
                            disabled: false
                        },
                        {
                            label: 'Magenta',
                            type: 'magenta',
                            field: 'number',
                            min: -100,
                            max: 100,
                            value: 0,
                            step: 1,
                            disabled: false
                        },
                        {
                            label: 'Yellow',
                            type: 'yellow',
                            field: 'number',
                            min: -100,
                            max: 100,
                            value: 0,
                            step: 1,
                            disabled: false
                        }
                    ]
                }
            ]
        }
    },
    lrApplyEditOptions: [
        {
            label: 'Exposure',
            type: 'exposure',
            field: 'number',
            min: -5,
            max: 5,
            step: 0.01,
            value: 0,
            disabled: false
        },
        {
            label: 'Contrast',
            type: 'contrast',
            field: 'number',
            min: -100,
            max: 100,
            step: 1,
            value: 0,
            disabled: false
        },
        {
            label: 'Saturation',
            type: 'saturation',
            field: 'number',
            min: -100,
            max: 100,
            step: 1,
            value: 0,
            disabled: false
        },
        {
            label: 'VignetteAmount',
            type: 'vignetteAmount',
            field: 'number',
            min: -100,
            max: 100,
            step: 1,
            value: 0,
            disabled: false
        },
        {
            label: 'Vibrance',
            type: 'vibrance',
            field: 'number',
            min: -100,
            max: 100,
            step: 1,
            value: 0,
            disabled: false
        },
        {
            label: 'Highlights',
            type: 'highlights',
            field: 'number',
            min: -100,
            max: 100,
            step: 1,
            value: 0,
            disabled: false
        },
        {
            label: 'Shadows',
            type: 'shadows',
            field: 'number',
            min: -100,
            max: 100,
            step: 1,
            value: 0,
            disabled: false
        },
        {
            label: 'Whites',
            type: 'whites',
            field: 'number',
            min: -100,
            max: 100,
            step: 1,
            value: 0,
            disabled: false
        },
        {
            label: 'Blacks',
            type: 'blacks',
            field: 'number',
            min: -100,
            max: 100,
            step: 1,
            value: 0,
            disabled: false
        },
        {
            label: 'Clarity',
            type: 'clarity',
            field: 'number',
            min: -100,
            max: 100,
            step: 1,
            value: 0,
            disabled: false
        },
        {
            label: 'Dehaze',
            type: 'dehaze',
            field: 'number',
            min: -100,
            max: 100,
            step: 1,
            value: 0,
            disabled: false
        }
    ],
    lrACRMaskOptions: {},
    lrACRPredictOptions: {
        version: '15.0',
        requestTypes: ['selectSubject', 'selectSky', 'selectSkin', 'depthMap']
    },
    lrEnableUprightOptions: false,
    lrAutostraightenModes: [
        { label: 'Auto', value: 'auto', selected: true },
        { label: 'Full', value: 'full', selected: false },
        { label: 'Level', value: 'level', selected: false },
        { label: 'Vertical', value: 'vertical', selected: false }
    ],
    ssRefinementModelOptions: [
        { label: 'Generic', value: 'generic', selected: true },
        { label: 'Portrait', value: 'portrait', selected: false },
        { label: 'Animal', value: 'animal', selected: false }
    ],
    reflowTaggings: [
        { label: 'Select an option', value: null },
        { label: 'BackgroundFill1', value: 'BackgroundFill1' },
        { label: 'BackgroundFill2', value: 'BackgroundFill2' },
        { label: 'BackgroundFill3', value: 'BackgroundFill3' },
        { label: 'BackgroundGradient1', value: 'BackgroundGradient1' },
        { label: 'BackgroundGradient2', value: 'BackgroundGradient2' },
        { label: 'BackgroundGradient3', value: 'BackgroundGradient3' },
        { label: 'Border', value: 'Border' },
        { label: 'CallToAction1', value: 'CallToAction1' },
        { label: 'CallToAction2', value: 'CallToAction2' },
        { label: 'CallToAction3', value: 'CallToAction3' },
        { label: 'Disclaimer1', value: 'Disclaimer1' },
        { label: 'Disclaimer2', value: 'Disclaimer2' },
        { label: 'Disclaimer3', value: 'Disclaimer3' },
        { label: 'Headline1', value: 'Headline1' },
        { label: 'Headline2', value: 'Headline2' },
        { label: 'Headline3', value: 'Headline3' },
        { label: 'Image1', value: 'Image1' },
        { label: 'Image2', value: 'Image2' },
        { label: 'Image3', value: 'Image3' },
        { label: 'Logo1', value: 'Logo1' },
        { label: 'Logo2', value: 'Logo2' },
        { label: 'Logo3', value: 'Logo3' },
        { label: 'Offer1', value: 'Offer1' },
        { label: 'Offer2', value: 'Offer2' },
        { label: 'Offer3', value: 'Offer3' }
    ],
    healthCheckRes: [],
    healthCheckResDs: [],
    apiTestRes: [],
    apiTestResDs: [],
    blendModes: [
        { label: 'normal', value: 'normal' },
        { label: 'dissolve', value: 'dissolve' },
        { label: 'darken', value: 'darken' },
        { label: 'multiply', value: 'multiply' },
        { label: 'colorBurn', value: 'colorBurn' },
        { label: 'linearBurn', value: 'linearBurn' },
        { label: 'darkerColor', value: 'darkerColor' },
        { label: 'lighten', value: 'lighten' },
        { label: 'screen', value: 'screen' },
        { label: 'colorDodge', value: 'colorDodge' },
        { label: 'linearDodge', value: 'linearDodge' },
        { label: 'lighterColor', value: 'lighterColor' },
        { label: 'overlay', value: 'overlay' },
        { label: 'softLight', value: 'softLight' },
        { label: 'hardLight', value: 'hardLight' },
        { label: 'vividLight', value: 'vividLight' },
        { label: 'linearLight', value: 'linearLight' },
        { label: 'pinLight', value: 'pinLight' },
        { label: 'hardMix', value: 'hardMix' },
        { label: 'difference', value: 'difference' },
        { label: 'exclusion', value: 'exclusion' },
        { label: 'subtract', value: 'subtract' },
        { label: 'divide', value: 'divide' },
        { label: 'hue', value: 'hue' },
        { label: 'saturation', value: 'saturation' },
        { label: 'color', value: 'color' },
        { label: 'luminosity', value: 'luminosity' }
    ],
    txtLayersLocales: [
        {
            language: 'English',
            locale: 'en',
            selected: false,
            content: '',
            fontSize: 10,
            fontFace: ''
        },
        {
            language: 'Japanese',
            locale: 'ja',
            selected: false,
            content: '',
            fontSize: 10,
            fontFace: ''
        },
        {
            language: 'Franch',
            locale: 'fr',
            selected: true,
            content: '',
            fontSize: 10,
            fontFace: ''
        },
        {
            language: 'German',
            locale: 'de',
            selected: true,
            content: '',
            fontSize: 10,
            fontFace: ''
        },
        {
            language: 'Italian',
            locale: 'it',
            selected: true,
            content: '',
            fontSize: 10,
            fontFace: ''
        },
        {
            language: 'Spanish',
            locale: 'es',
            selected: true,
            content: '',
            fontSize: 10,
            fontFace: ''
        },
        {
            language: 'Portuguese',
            locale: 'pt-PT',
            selected: true,
            content: '',
            fontSize: 10,
            fontFace: ''
        },
        {
            language: 'Thai',
            locale: 'th',
            selected: false,
            content: '',
            fontSize: 10,
            fontFace: ''
        },
        {
            language: 'Russian',
            locale: 'ru',
            selected: false,
            content: '',
            fontSize: 10,
            fontFace: ''
        },
        {
            language: 'Vietnamese',
            locale: 'vi',
            selected: false,
            content: '',
            fontSize: 10,
            fontFace: ''
        },
        {
            language: 'Korean',
            locale: 'ko',
            selected: false,
            content: '',
            fontSize: 10,
            fontFace: ''
        },
        {
            language: 'Chinese Simplified',
            locale: 'zh-CN',
            selected: false,
            content: '',
            fontSize: 10,
            fontFace: ''
        },
        {
            language: 'Chinese Traditional',
            locale: 'zh-TW',
            selected: false,
            content: '',
            fontSize: 10,
            fontFace: ''
        }
    ],
    locales: [
        {
            language: 'English',
            label: 'English',
            locale: 'en',
            value: 'en',
            selected: false
        },
        {
            language: 'Japanese',
            label: 'Japanese',
            locale: 'ja',
            value: 'ja',
            selected: true
        },
        {
            language: 'Franch',
            label: 'Franch',
            locale: 'fr',
            value: 'fr',
            selected: true
        },
        {
            language: 'German',
            label: 'German',
            locale: 'de',
            value: 'de',
            selected: true
        },
        {
            language: 'Italian',
            label: 'Italian',
            locale: 'it',
            value: 'it',
            selected: true
        },
        {
            language: 'Spanish',
            label: 'Spanish',
            locale: 'es',
            value: 'es',
            selected: true
        },
        {
            language: 'Portuguese',
            label: 'Portuguese',
            locale: 'pt-PT',
            value: 'pt-PT',
            selected: false
        },
        {
            language: 'Russian',
            label: 'Russian',
            locale: 'ru',
            value: 'ru',
            selected: true
        },
        {
            language: 'Vietnamese',
            label: 'Vietnamese',
            locale: 'vi',
            value: 'vi',
            selected: false
        },
        {
            language: 'Korean',
            label: 'Korean',
            locale: 'ko',
            value: 'ko',
            selected: true
        },
        {
            language: 'Chinese Simplified',
            label: 'Chinese Simplified',
            locale: 'zh-CN',
            value: 'zh-CN',
            selected: true
        },
        {
            language: 'Chinese Traditional',
            label: 'Chinese Traditional',
            locale: 'zh-TW',
            value: 'zh-TW',
            selected: false
        }
    ],
    apiTestCases: [
        {
            testCase: 'psDocumentManifest',
            label: 'Get PSD Info',
            value: 'psDocumentManifest',
            selected: false,
            disabled: false
        },
        {
            testCase: 'psEdit',
            label: 'Edit PSD',
            value: 'psEdit',
            selected: false,
            disabled: false
        },
        {
            testCase: 'psFileConversion',
            label: 'File Conversion',
            value: 'psFileConversion',
            selected: false,
            disabled: false
        },
        {
            testCase: 'psInsertLayer',
            label: 'Insert Layer',
            value: 'psInsertLayer',
            selected: false,
            disabled: false
        },
        {
            testCase: 'psSmartObjectV2',
            label: 'Smart Object V2',
            value: 'psSmartObjectV2',
            selected: false,
            disabled: false
        },
        {
            testCase: 'psActions',
            label: 'Photoshop Actions',
            value: 'psActions',
            selected: false,
            disabled: false
        },
        {
            testCase: 'ssImagecutout',
            label: 'Image Cutout',
            value: 'ssImagecutout',
            selected: true,
            disabled: false
        }
    ],
    apiTestTypes: [
        {
            testType: 'smokeTest',
            label: 'Smoke Test',
            value: 'smokeTest',
            selected: false,
            disabled: false
        },
        {
            testType: 'basicTest',
            label: 'Basic Test',
            value: 'basicTest',
            selected: true,
            disabled: false
        }
    ],
    apiTestFiles: [
        {
            testFile: 'testFile',
            label: 'Update Testfiles',
            value: 'Update',
            selected: false,
            disabled: false
        }
    ],
    storageTypes: [
        {
            storage: 'external',
            label: 'aws',
            value: 'aws',
            selected: true
        },
        {
            storage: 'adobe',
            label: 'cc',
            value: 'cc',
            selected: false
        }
    ],
    supportedFonts: [
        { label: 'AdobeArabic-Bold', value: 'AdobeArabic-Bold', selected: false },
        { label: 'AdobeArabic-BoldItalic', value: 'AdobeArabic-BoldItalic', selected: false },
        { label: 'AdobeArabic-Italic', value: 'AdobeArabic-Italic', selected: false },
        { label: 'AdobeArabic-Regular', value: 'AdobeArabic-Regular', selected: false },
        { label: 'AdobeDevanagari-Bold', value: 'AdobeDevanagari-Bold', selected: false },
        { label: 'AdobeDevanagari-BoldItalic', value: 'AdobeDevanagari-BoldItalic', selected: false },
        { label: 'AdobeDevanagari-Italic', value: 'AdobeDevanagari-Italic', selected: false },
        { label: 'AdobeDevanagari-Regular', value: 'AdobeDevanagari-Regular', selected: false },
        { label: 'AdobeFanHeitiStd-Bold', value: 'AdobeFanHeitiStd-Bold', selected: false },
        { label: 'AdobeGothicStd-Bold', value: 'AdobeGothicStd-Bold', selected: false },
        { label: 'AdobeGurmukhi-Bold', value: 'AdobeGurmukhi-Bold', selected: false },
        { label: 'AdobeGurmukhi-Regular', value: 'AdobeGurmukhi-Regular', selected: false },
        { label: 'AdobeHebrew-Bold', value: 'AdobeHebrew-Bold', selected: false },
        { label: 'AdobeHebrew-BoldItalic', value: 'AdobeHebrew-BoldItalic', selected: false },
        { label: 'AdobeHebrew-Italic', value: 'AdobeHebrew-Italic', selected: false },
        { label: 'AdobeHebrew-Regular', value: 'AdobeHebrew-Regular', selected: false },
        { label: 'AdobeHeitiStd-Regular', value: 'AdobeHeitiStd-Regular', selected: false },
        { label: 'AdobeMingStd-Light', value: 'AdobeMingStd-Light', selected: false },
        { label: 'AdobeMyungjoStd-Medium', value: 'AdobeMyungjoStd-Medium', selected: false },
        { label: 'AdobePiStd', value: 'AdobePiStd', selected: false },
        { label: 'AdobeSongStd-Light', value: 'AdobeSongStd-Light', selected: false },
        { label: 'AdobeThai-Bold', value: 'AdobeThai-Bold', selected: false },
        { label: 'AdobeThai-BoldItalic', value: 'AdobeThai-BoldItalic', selected: false },
        { label: 'AdobeThai-Italic', value: 'AdobeThai-Italic', selected: false },
        { label: 'AdobeThai-Regular', value: 'AdobeThai-Regular', selected: false },
        { label: 'CourierStd', value: 'CourierStd', selected: false },
        { label: 'CourierStd-Bold', value: 'CourierStd-Bold', selected: false },
        { label: 'CourierStd-BoldOblique', value: 'CourierStd-BoldOblique', selected: false },
        { label: 'CourierStd-Oblique', value: 'CourierStd-Oblique', selected: false },
        { label: 'EmojiOneColor', value: 'EmojiOneColor', selected: false },
        { label: 'KozGoPr6N-Bold', value: 'KozGoPr6N-Bold', selected: false },
        { label: 'KozGoPr6N-Medium', value: 'KozGoPr6N-Medium', selected: false },
        { label: 'KozGoPr6N-Regular', value: 'KozGoPr6N-Regular', selected: false },
        { label: 'KozMinPr6N-Regular', value: 'KozMinPr6N-Regular', selected: false },
        { label: 'MinionPro-Regular', value: 'MinionPro-Regular', selected: false },
        { label: 'MinionVariableConcept-Italic', value: 'MinionVariableConcept-Italic', selected: false },
        { label: 'MinionVariableConcept-Roman', value: 'MinionVariableConcept-Roman', selected: false },
        { label: 'MyriadArabic-Bold', value: 'MyriadArabic-Bold', selected: false },
        { label: 'MyriadArabic-BoldIt', value: 'MyriadArabic-BoldIt', selected: false },
        { label: 'MyriadArabic-It', value: 'MyriadArabic-It', selected: false },
        { label: 'MyriadArabic-Regular', value: 'MyriadArabic-Regular', selected: false },
        { label: 'MyriadHebrew-Bold', value: 'MyriadHebrew-Bold', selected: false },
        { label: 'MyriadHebrew-BoldIt', value: 'MyriadHebrew-BoldIt', selected: false },
        { label: 'MyriadHebrew-It', value: 'MyriadHebrew-It', selected: false },
        { label: 'MyriadHebrew-Regular', value: 'MyriadHebrew-Regular', selected: false },
        { label: 'MyriadPro-Bold', value: 'MyriadPro-Bold', selected: false },
        { label: 'MyriadPro-BoldIt', value: 'MyriadPro-BoldIt', selected: false },
        { label: 'MyriadPro-It', value: 'MyriadPro-It', selected: false },
        { label: 'MyriadPro-Regular', value: 'MyriadPro-Regular', selected: false },
        { label: 'MyriadVariableConcept-Italic', value: 'MyriadVariableConcept-Italic', selected: false },
        { label: 'MyriadVariableConcept-Roman', value: 'MyriadVariableConcept-Roman', selected: false },
        { label: 'NotoSansKhmer-Regular', value: 'NotoSansKhmer-Regular', selected: false },
        { label: 'NotoSansLao-Regular', value: 'NotoSansLao-Regular', selected: false },
        { label: 'NotoSansMyanmar-Regular', value: 'NotoSansMyanmar-Regular', selected: false },
        { label: 'NotoSansSinhala-Regular', value: 'NotoSansSinhala-Regular', selected: false },
        { label: 'SourceCodeVariable-Italic', value: 'SourceCodeVariable-Italic', selected: false },
        { label: 'SourceCodeVariable-Roman', value: 'SourceCodeVariable-Roman', selected: false },
        { label: 'SourceSansVariable-Italic', value: 'SourceSansVariable-Italic', selected: false },
        { label: 'SourceSansVariable-Roman', value: 'SourceSansVariable-Roman', selected: false },
        { label: 'SourceSerifVariable-Roman', value: 'SourceSerifVariable-Roman', selected: false },
        { label: 'TrajanColor-Concept', value: 'TrajanColor-Concept', selected: false }
    ],
    antiAlias: [
        { label: 'antiAliasNone', value: 'antiAliasNone' },
        { label: 'antiAliasSharp', value: 'antiAliasSharp' },
        { label: 'antiAliasCrisp', value: 'antiAliasCrisp' },
        { label: 'antiAliasStrong', value: 'antiAliasStrong' },
        { label: 'antiAliasSmooth', value: 'antiAliasSmooth' },
        { label: 'antiAliasPlatformLCD', value: 'antiAliasPlatformLCD' },
        { label: 'antiAliasPlatformGray', value: 'antiAliasPlatformGray' }
    ],
    baseline: [
        { label: 'subScript', value: 'subScript' },
        { label: 'superScript', value: 'superScript' }
    ],
    fontCaps: [
        { label: 'allCaps', value: 'allCaps' },
        { label: 'smallCaps', value: 'smallCaps' }
    ],
    autoKern: [
        { label: 'opticalKern', value: 'opticalKern' },
        { label: 'metricsKern', value: 'metricsKern' }
    ],
    alignment: [
        { label: 'left', value: 'left' },
        { label: 'center', value: 'center' },
        { label: 'right', value: 'right' },
        { label: 'justifyLeft', value: 'justifyLeft' },
        { label: 'justifyCenter', value: 'justifyCenter' },
        { label: 'justifyRight', value: 'justifyRight' },
        { label: 'justifyAll', value: 'justifyAll' }
    ]

}

const reducer = (state, anAction) => {
    switch (anAction.type) {
    // *****************************************
    // Skycity
    // *****************************************
    case 'set-appProgress': {
        const { appProgress, appProgressMsg } = anAction.payload
        return { ...state, appProgress: appProgress, appProgressMsg: appProgressMsg }
    }
    case 'error-occured': {
        const { errMsg, response } = anAction.payload
        state.response.push({ res: response })
        return { ...state, response: state.response, error: true, errMsg: errMsg, wait: false, loading: false }
    }
    case 'error-occured-linux': {
        const { errMsg, response } = anAction.payload
        state.responseLinux.push({ res: response })
        return { ...state, responseLinux: state.responseLinux, errorLinux: true, errMsgLinux: errMsg, wait: false, loading: false }
    }
    case 'error-occured-sidebyside': {
        const { errMsg, response } = anAction.payload
        state.responseSideBySide.push({ res: response })
        return { ...state, responseSideBySide: state.responseSideBySide, errorSideBySide: true, errMsgSideBySide: errMsg, wait: false, loading: false }
    }

    case 'set-codes': {
        const { config } = JSON.parse(JSON.stringify(anAction.payload));
        if(config.payload.hasOwnProperty("outputs"))
        {
            config.payload.outputs.forEach(function(v){ delete v.getHref });
        }
        state.codes.push({ config: config, codeEndpoint: config.url, codeMethod: config.method, codePayload: config.payload })
        return { ...state, codes: state.codes }
    }
    case 'set-codes-linux': {
        const { config } = JSON.parse(JSON.stringify(anAction.payload));
        if(config.payload.hasOwnProperty("outputs"))
        {
            config.payload.outputs.forEach(function(v){ delete v.getHref });
        }
        state.codesLinux.push({ config: config, codeEndpoint: config.url, codeMethod: config.method, codePayload: config.payload })
        return { ...state, codesLinux: state.codesLinux }
    }
    case 'set-codes-sidebyside': {
        const { config } = JSON.parse(JSON.stringify(anAction.payload));
        if(config.payload.hasOwnProperty("outputs"))
        {
            config.payload.outputs.forEach(function(v){ delete v.getHref });
        }
        state.codesSideBySide.push({ config: config, codeEndpoint: config.url, codeMethod: config.method, codePayload: config.payload })
        return { ...state, codesSideBySide: state.codesSideBySide }
    }
    case 'set-wait': {
        const { wait } = anAction.payload
        return { ...state, wait: wait }
    }
    case 'set-apistatus': {
        const { status } = anAction.payload
        return { ...state, apiStatus: status }
    }
    case 'set-selected-layers': {
        const { layers } = anAction.payload
        return { ...state, selectedLayers: layers }
    }
    case 'set-removed-layers': {
        const { layers } = anAction.payload
        return { ...state, selectedLayers: layers }
    }
    case 'toggle-layer-info-pane': {
        const { showLayerInfoPane } = anAction.payload
        return { ...state, showLayerInfoPane: showLayerInfoPane }
    }
    case 'set-fontname': {
        const { fontName } = anAction.payload
        return { ...state, fontName: fontName }
    }
    case 'set-page': {
        const { page } = anAction.payload
        analytics.sendPageName(page)
        const curState = JSON.parse(JSON.stringify(initialState))
        return { ...curState, page }
        // return { ...state,
        //     page: page,
        //     wait: false,
        //     loading: false,
        //     inputFiles: [],
        //     inputFile: { progress: 0, name: ""},
        //     selectedInputFiles: [],
        //     selectedInputFile: null,
        //     outputFiles: [],
        //     codes:[],
        //     response: [],
        //     outputOptions: []
        // }
    }
    case 'clear-output': {
        return { ...state, outputFiles: [], loading: false, error: null, errorLinux: null, errMsg: null, codes: [], codesLinux: [], codesSideBySide: [], response: [], responseLinux: [], responseSideBySide: [] }
    }
    case 'set-file-view-option': {
        const { vo } = anAction.payload
        return { ...state, fileViewOption: vo }
    }
    case 'set-input': {
        const { wait, files, file } = anAction.payload
        return {
            ...state,
            inputFiles: files,
            previewFiles: files,
            inputFile: file,
            wait: wait,
            selectedInputFile: null,
            outputFiles: [],
            codes: [],
            codesLinux: [],
            codesSideBySide: [],
            response: [],
            responseLinux: [],
            responseSideBySide: []
        }
    }
    case 'set-input-local': {
        const { wait, files, file } = anAction.payload
        return {
            ...state,
            inputFiles: files,
            previewFiles: files,
            inputFile: file,
            wait: wait,
            selectedInputFile: null,
            outputFiles: [],
            codes: [],
            codesLinux: [],
            codesSideBySide: [],
            response: [],
            responseLinux: [],
            responseSideBySide: []
        }
    }
    case 'set-input-second': {
        const { wait, files, file } = anAction.payload
        return {
            ...state,
            inputSecondFiles: files,
            inputSecondFile: file,
            wait: wait,
            outputFiles: [],
            codes: [],
            codesLinux: [],
            codesSideBySide: [],
            response: [],
            responseLinux: [],
            responseSideBySide: []
        }
    }
    case 'set-input-third': {
        const { wait, files, file } = anAction.payload
        return {
            ...state,
            inputThirdFiles: files,
            inputThirdFile: file,
            wait: wait,
            outputFiles: [],
            codes: [],
            codesLinux: [],
            codesSideBySide: [],
            response: [],
            responseLinux: [],
            responseSideBySide: []
        }
    }
    case 'set-input-font': {
        const { wait, files, file } = anAction.payload
        return {
            ...state,
            inputFontFiles: files,
            inputFontFile: file,
            wait: wait,
            outputFiles: [],
            codes: [],
            codesLinux: [],
            codesSideBySide: [],
            response: [],
            responseLinux: [],
            responseSideBySide: []
        }
    }
    case 'set-input-icc': {
        const { wait, files, file } = anAction.payload
        const newIccProfileOption = state.iccProfileOption
        newIccProfileOption.profileFile.input.href = files && files.length > 0 && files[0].path
        newIccProfileOption.profileFile.input.storage = files && files.length > 0 && files[0].storage
        return {
            ...state,
            inputIccFiles: files,
            inputIccFile: file,
            iccProfileOption: newIccProfileOption,
            wait: wait,
            outputFiles: [],
            codes: [],
            codesLinux: [],
            response: [],
            responseLinux: []
        }
    }
    case 'set-selected-input': {
        const { file } = anAction.payload
        if (file && file.documentManifest && file.documentManifest.outputs && file.documentManifest.outputs[0] && file.documentManifest.outputs[0].layers) {
            const curLayers = file.documentManifest.outputs[0].layers
            const layerDs = new LayerDS(curLayers)
            return { ...state, selectedInputFile: file, layerDs: layerDs }
        } else if (file && file.layers) {
            const curLayers = file.layers
            const layerDs = new LayerDS(curLayers)
            return { ...state, selectedInputFile: file, layerDs: layerDs }
        }
        return { ...state, selectedInputFile: file }
    }
    case 'set-input-preview': {
        if (state.inputFiles && state.inputFiles[0]) {
            const { loading, wait, files, response, layers } = anAction.payload
            let layerDs = []
            if (files && files[0].documentManifest) {
                layerDs = new LayerDS(files[0].documentManifest.outputs[0].layers)
            }
            state.response.push({ res: response })
            return {
                ...state,
                layerDs: layerDs,
                previewFiles: files,
                wait: wait,
                loading: loading,
                response: state.response,
                selectedLayers: layers
            }
        }
        break
    }
    case 'set-previewEdit': {
        const { previewEdit } = anAction.payload
        return { ...state, previewEdit: previewEdit }
    }
    case 'set-previewOnLinux': {
        const { previewOnLinux } = anAction.payload
        return { ...state, previewOnLinux: previewOnLinux }
    }
    // case "reset-layerDs": {
    //     return { ...state, layerDs: []}
    // }
    case 'set-insertLayerType': {
        const { smartObject } = anAction.payload
        return { ...state, insertLayerAsSmartObject: smartObject }
    }
    case 'toggle-nav-pane': {
        const { showNavPane } = anAction.payload
        return { ...state, showNavPane: showNavPane }
    }
    case 'reset': {
        const curState = JSON.parse(JSON.stringify(initialState))
        return curState
    }
    case 'toggle-cc-browser-pane': {
        const { actionType, visible } = anAction.payload
        switch (actionType) {
        case 'set-input':
            return { ...state, showCcBrowserPane: visible }
        case 'set-input-second':
            return { ...state, showCcBrowserPaneSecond: visible }
        case 'set-input-font':
            return { ...state, showCcBrowserPaneFont: visible }
        case 'set-input-icc':
            return { ...state, showCcBrowserPaneIcc: visible }
        default:
            break
        }
        break
    }
    case 'toggle-cloudContent-browser-pane': {
        const { actionType, visible } = anAction.payload
        switch (actionType) {
        case 'set-input':
            return { ...state, showCloudContentBrowserPane: visible }
        case 'set-input-second':
            return { ...state, showCloudContentBrowserPaneSecond: visible }
        case 'set-input-icc':
            return { ...state, showCloudContentBrowserPaneIcc: visible }
        default:
            break
        }
        break
    }
    case 'toggle-s3-browser-pane': {
        const { actionType, visible } = anAction.payload
        switch (actionType) {
        case 'set-input':
            return { ...state, showS3BrowserPane: visible }
        case 'set-input-second':
            return { ...state, showS3BrowserPaneSecond: visible }
        case 'set-input-font':
            return { ...state, showS3BrowserPaneFont: visible }
        case 'set-input-icc':
            return { ...state, showS3BrowserPaneIcc: visible }
        default:
            break
        }
        break
    }
    case 'toggle-as-browser-pane': {
        const { actionType, visible } = anAction.payload
        switch (actionType) {
        case 'set-input':
            return { ...state, showAsBrowserPane: visible }
        case 'set-input-second':
            return { ...state, showAsBrowserPaneSecond: visible }
        case 'set-input-font':
            return { ...state, showAsBrowserPaneFont: visible }
        case 'set-input-icc':
            return { ...state, showAsBrowserPaneIcc: visible }
        default:
            break
        }
        break
    }
    case 'set-outputOptions': {
        const { outputOptions } = anAction.payload
        const newOutputOptions = outputOptions.map(option => {
            if (option.type === 'image/vnd.adobe.photoshop') {
                option.type = 'image/vnd.adobe.photoshop'
                return option
            }
            return option
        })
        return { ...state, outputOptions: newOutputOptions }
    }
    case 'set-outputOptions-lr': {
        const { outputOptionsLr } = anAction.payload
        return { ...state, outputOptionsLr: outputOptionsLr }
    }
    case 'set-iccProfileOption': {
        const { iccProfileOption } = anAction.payload
        return { ...state, iccProfileOption: iccProfileOption }
    }
    case 'set-lrEnableUprightOptions': {
        const { lrEnableUprightOptions } = anAction.payload
        return { ...state, lrEnableUprightOptions: lrEnableUprightOptions }
    }
    case 'set-lrACRMaskOptions': {
        const { lrACRMaskOptions } = anAction.payload
        return { ...state, lrACRMaskOptions: lrACRMaskOptions }
    }
    case 'set-lrACRPredictOptions': {
        const { lrACRPredictOptions } = anAction.payload
        return { ...state, lrACRPredictOptions: lrACRPredictOptions }
    }
    case 'set-lrApplyEditOptions': {
        const { lrApplyEditOptions } = anAction.payload
        return { ...state, lrApplyEditOptions: lrApplyEditOptions }
    }
    case 'set-lrUprightMode': {
        const { lrAutostraightenModes } = anAction.payload
        return { ...state, lrAutostraightenModes: lrAutostraightenModes }
    }

    case 'set-ssRefinementModelOptions': {
        const { ssRefinementModelOptions } = anAction.payload
        return { ...state, ssRefinementModelOptions: ssRefinementModelOptions }
    }

    case 'set-productCropOptions': {
        const { productCropOptions } = anAction.payload
        return { ...state, productCropOptions: productCropOptions }
    }

    case 'set-layerOptions': {
        const { layerOptions } = anAction.payload
        return { ...state, layerOptions: layerOptions }
    }
    case 'set-layerMappings': {
        const { mappings } = anAction.payload
        return { ...state, layerMappings: mappings }
    }
    case 'set-ccfiles': {
        const { ccFiles, filteredCcFiles } = anAction.payload
        let dsCcFiles = new CcDS([])
        if (ccFiles && ccFiles.children) {
            const children = ccFiles.children.map(child => {
                child.url = `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${child.path}`
                return child
            })
            ccFiles.children = children
            if (filteredCcFiles) {
                dsCcFiles = new CcDS(filteredCcFiles)
            } else {
                dsCcFiles = new CcDS(ccFiles.children)
            }
        }
        return { ...state, ccFiles: ccFiles, dsCcFiles: dsCcFiles }
    }

    case 'set-cloudContentFiles': {
        const { cloudContentFiles, filteredCloudContentFiles } = anAction.payload
        let dsCloudContentFiles = new CloudContentDS([])
        if (cloudContentFiles && cloudContentFiles.children) {
            const children = cloudContentFiles.children.map(child => {
                child.url = `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${child.path}`
                return child
            })
            cloudContentFiles.children = children
            if (filteredCloudContentFiles) {
                dsCloudContentFiles = new CloudContentDS(filteredCloudContentFiles)
            } else {
                dsCloudContentFiles = new CloudContentDS(cloudContentFiles.children)
            }
        }
        return { ...state, cloudContentFiles: cloudContentFiles, dsCloudContentFiles: dsCloudContentFiles }
    }

    case 'set-s3files': {
        try {
            const { s3Files } = anAction.payload
            let dsS3Files = null // new AsDS([]);
            if (s3Files) {
                const files = s3Files.map(file => {
                    file.id = `${file.thumbnail_url}`
                    file.s3_id = `${file.thumbnail_url}`
                    file.url = `${file.thumbnail_url}`
                    file.path = `${file.thumbnail_url}`
                    file.storage = 'external'
                    file.name = `${file.name}`
                    file.content_type = `${file.content_type}`
                    return file
                })
                dsS3Files = new AsDS(files)
            }
            return { ...state, s3Files: s3Files, dsS3Files: dsS3Files, wait: false, loading: false }
        } catch (e) {
            console.log(e)
        }
        break
    }
    case 'set-asfiles': {
        try {
            const { asFiles } = anAction.payload
            let dsAsFiles = null // new AsDS([]);
            if (asFiles) {
                const files = asFiles.map(file => {
                    file.url = `${file.thumbnail_url}`
                    file.path = `${file.thumbnail_url}`
                    file.storage = 'external'
                    return file
                })
                dsAsFiles = new AsDS(files)
            }
            return { ...state, asFiles: asFiles, dsAsFiles: dsAsFiles }
        } catch (e) {
            console.log(e)
        }
        break
    }
    case 'set-template-options': {
        const { templateOptions } = anAction.payload
        return { ...state, templateOptions: templateOptions }
    }
    case 'set-locales': {
        const { localeOptions } = anAction.payload
        return { ...state, txtLayersLocales: localeOptions }
    }
    case 'set-testCase': {
        const { apiTestCases } = anAction.payload
        return { ...state, apiTestCases: apiTestCases }
    }
    case 'set-testType': {
        const { apiTestTypes } = anAction.payload
        return { ...state, apiTestTypes: apiTestTypes }
    }
    case 'set-testFile': {
        const { apiTestFiles } = anAction.payload
        return { ...state, apiTestFiles: apiTestFiles }
    }
    case 'set-storageTypes': {
        const { storageTypes } = anAction.payload
        return { ...state, storageTypes: storageTypes }
    }
    // *****************************************
    // skycity-run
    // *****************************************
    case 'skycity-run': {
        return {
            ...state,
            layerDs: [],
            codes: [],
            codesLinux: [],
            codesSideBySide: [],
            response: [],
            responseSideBySide: [],
            responseLinux: [],
            wait: true,
            loading: true,
            error: false,
            errorLinux: false,
            errMsg: null
        }
    }
    // *****************************************
    // skycity-run-psEdit
    // *****************************************
    case 'skycity-run-psEdit': {
        return {
            ...state,
            codes: [],
            codesLinux: [],
            codesSideBySide: [],
            response: [],
            responseLinux: [],
            responseSideBySide: [],
            wait: true,
            loading: true,
            error: false,
            errorLinux: false,
            errorSideBySide: false,
            errMsg: null
        }
    }
    // *****************************************
    // skycity-run-smartObject
    // *****************************************
    case 'skycity-run-smartObject': {
        return {
            ...state,
            codes: [],
            codesLinux: [],
            codesSideBySide: [],
            response: [],
            responseLinux: [],
            responseSideBySide: [],
            wait: true,
            loading: true,
            error: false,
            errorLinux: false,
            errorSideBySide: false,
            errMsg: null
        }
    }
    // *****************************************
    // skycity-success
    // *****************************************
    case 'skycity-success': {
        const { files, response } = anAction.payload
        state.response.push({ res: response })
        return {
            ...state,
            outputFiles: files,
            response: state.response,
            loading: false,
            wait: false,
            nonPreviewOptions: {}
        }
    }
    case 'skycity-success-linux': {
        const { files, response } = anAction.payload
        state.responseLinux.push({ res: response })
        return {
            ...state,
            outputFiles: files,
            responseLinux: state.responseLinux,
            loading: false,
            wait: false,
            nonPreviewOptions: {}
        }
    }

    case 'skycity-success-sidebyside': {
        const { files, response } = anAction.payload
        state.responseSideBySide.push({ res: response })
        return {
            ...state,
            outputFiles: files,
            responseSideBySide: state.responseSideBySide,
            loading: false,
            wait: false,
            nonPreviewOptions: {}
        }
    }
    // *****************************************
    // sensei-success
    // *****************************************
    case 'sensei-success': {
        const { files, response } = anAction.payload
        state.response.push({ res: response })
        return {
            ...state,
            outputFiles: files,
            response: state.response,
            nonPreviewOptions: {}
        }
    }
    // *****************************************
    // sensei-end-wait
    // *****************************************
    case 'sensei-end-wait': {
        return {
            ...state,
            wait: false,
            loading: false
        }
    }
    // *****************************************
    // ssgeneration-success
    // *****************************************
    case 'ssgeneration-success': {
        const { files, response } = anAction.payload
        state.response.push({ res: response })
        const curLayers = (state.selectedInputFile.documentManifest && state.selectedInputFile.documentManifest.outputs && state.selectedInputFile.documentManifest.outputs[0].layers) || []
        const layerDs = new LayerDS(curLayers)
        return {
            ...state,
            outputFiles: files,
            response: state.response,
            loading: false,
            wait: false,
            layerDs: layerDs
        }
    }
    // *****************************************
    // DocumentManifes
    // *****************************************
    case 'psdocumentmanifest-success': {
        const { files, selectedInputFile, response } = anAction.payload
        state.response.push({ res: response })
        const curLayers = selectedInputFile.layers
        const layerDs = new LayerDS(curLayers)
        return {
            ...state,
            files: files,
            selectedInputFile: selectedInputFile,
            layerDs: layerDs,
            response: state.response,
            showLayerInfoPane: true,
            loading: false,
            wait: false
        }
    }
    // *****************************************
    // FileConversion
    // *****************************************
    case 'psfileconversion-success': {
        const { files, selectedInputFile, response } = anAction.payload
        state.response.push({ res: response })
        const curLayers = selectedInputFile.layers
        const layerDs = new LayerDS(curLayers)
        return {
            ...state,
            outputFiles: files,
            selectedInputFile: selectedInputFile,
            layerDs: layerDs,
            response: state.response,
            showLayerInfoPane: false,
            loading: false,
            wait: false
        }
    }
    // *****************************************
    // ToolHealthCheck
    // *****************************************
    case 'toolhealthcheck-success': {
        const { healthCheckRes } = anAction.payload
        return {
            ...state,
            healthCheckRes: healthCheckRes,
            healthCheckResDs: new TableDS(healthCheckRes),
            response: state.response,
            loading: false,
            wait: false
        }
    }
    // *****************************************
    // ToolTest
    // *****************************************
    case 'tooltest-success': {
        const { apiTestRes } = anAction.payload
        return {
            ...state,
            apiTestRes: apiTestRes,
            apiTestResDs: new TableDS(apiTestRes),
            response: state.response,
            loading: false,
            wait: false
        }
    }
    // *****************************************
    // PsdLoc
    // *****************************************
    case 'set-text-layer-table': {
        const { txtLayers } = anAction.payload
        const curTxtLayersDs = new TableDS(txtLayers)
        return {
            ...state,
            txtLayers: txtLayers,
            txtLayersDs: curTxtLayersDs,
            loading: false,
            wait: false
        }
    }
    case 'set-text-layer-locales': {
        const { txtLayersLocales } = anAction.payload
        return {
            ...state,
            txtLayersLocales: txtLayersLocales
        }
    }
    case 'set-locFrom': {
        const { locale } = anAction.payload
        return {
            ...state,
            locFrom: locale
        }
    }
    case 'set-supportedFonts': {
        const { supportedFonts } = anAction.payload
        return {
            ...state,
            supportedFonts: supportedFonts
        }
    }
    case 'set-psWasm': {
        const { psWasm } = anAction.payload
        return { ...state, psWasm: psWasm }
    }
    case 'set-psActionFile': {
        const { psActionFile } = anAction.payload
        return { ...state, psActionFile: psActionFile }
    }
    case 'skycity-loc-run': {
        return {
            ...state,
            codes: [],
            codesLinux: [],
            codesSideBySide: [],
            response: [],
            responseSideBySide: [],
            responseLinux: [],
            wait: true,
            loading: true,
            error: false,
            errorLinux: false,
            errMsg: null
        }
    }
    // *****************************************
    // skycity-psedit-run
    // *****************************************
    case 'skycity-psedit-run': {
        return {
            ...state,
            wait: true,
            loading: true,
            error: false,
            errorLinux: false,
            errMsg: null
        }
    }
    case 'skycity-run-test': {
        return {
            ...state,
            layerDs: [],
            codes: [],
            codesLinux: [],
            codesSideBySide: [],
            testResult: [],
            testResultDs: [],
            response: [],
            responseLinux: [],
            responseSideBySide: [],
            error: false,
            errorLinux: false,
            loading: true,
            wait: true,
            errMsg: null
        }
    }
    case 'set-test-result': {
        const { config, msg, error, files, res } = anAction.payload
        const newTestResult = state.testResult
        newTestResult.push({
            config: config,
            msg: msg,
            error: error,
            files: files,
            res: res
        })
        const newTestResultDs = new TableDS(newTestResult)
        return {
            ...state,
            loading: false,
            wait: false,
            testResult: newTestResult,
            testResultDs: newTestResultDs
        }
    }
    default:
        console.log(`anAction.type: ${anAction.type}`)
        // throw new Error()
        alert('Something went wrong!')
    }
}

export default function SkycityProvider ({ children }) {
    const curState = JSON.parse(JSON.stringify(initialState))
    const [state, dispatch] = useReducer(reducer, curState)
    const value = { ...state, dispatch }
    // console.log(children);
    return (
        <SkycityContext.Provider value={value}>
            {children}
        </SkycityContext.Provider>
    )
}

class LayerDS extends TreeDataSource {
    constructor (data) {
        super()
        this.data = data
    }

    load () {
        return this.data
    }

    async getChildren (item) {
        if (!item) {
            return this.data
        }
        return item.children || []
    }

    hasChildren (item) {
        return !!item.children
    }
    // update(data) {
    //     this.data = data;
    //     this.reloadData();
    // }
}

class TableDS extends ListDataSource {
    constructor (data) {
        super()
        this.data = data
    }

    // load(sortDescriptor) {
    //     let data = this.data.slice();
    //     return data;
    // }
    load () {
        return this.data
    }
}

class CcDS extends ListDataSource {
    constructor (data) {
        super()
        this.data = data
    }

    load () {
        return this.data
    }
}

class CloudContentDS extends ListDataSource {
    constructor (data) {
        super()
        this.data = data
    }

    load () {
        return this.data
    }
}

class AsDS extends ListDataSource {
    constructor (data) {
        super()
        this.data = data
    }

    load () {
        return this.data
    }
}
