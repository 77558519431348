import React, { useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import Heading from '@react/react-spectrum/Heading'
import Well from '@react/react-spectrum/Well'
import Radio from '@react/react-spectrum/Radio'
import RadioGroup from '@react/react-spectrum/RadioGroup'

export default function ACRMaskOptions (props) {
    const {
        dispatch
    } = useContext(SkycityContext)

    function changeValue (e) {
        const maskOptions = {
            version: '15.0',
            maskType: e
        }
        dispatch({ type: 'set-lrACRMaskOptions', payload: { lrACRMaskOptions: maskOptions } })
    }

    return <div className="adobe-skycity-output-options">
        <Well>
            <Heading variant="pageTitle">{__('skycity_comp_title_lrmask_options')}</Heading>
            <br />
            <div className="adobe-skycity-output-options-file-format">
                <RadioGroup name="MaskType" onChange={e => changeValue(e)}>
                    <Radio label="SelectSubject" value="selectSubject" />
                    <Radio label="SelectSky" value="selectSky" />
                    <Radio label="SelectSkin" value="selectSkin" />
                </RadioGroup>
            </div>
        </Well>
    </div>
}
