import React, { useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import { error } from '@react/react-spectrum/Toast'
import Button from '@react/react-spectrum/Button'

import OutputOptions from '../Shared/OutputOptions'

import { apiCall, getStatus } from '../../lib/cisApi.js'
import { getFilename, getFilenameWithoutExt, getHeaders, getStorage, getOptionsOutput, getLrPresets, getEncodedURI, getRenditionCreateWithUtilsStorage, getFileType, getOutputFileName } from '../../lib/skycity.js'

import axios from 'axios'

export default function Operation (files) {
    const {
        inputFiles,
        inputSecondFiles,
        outputOptionsLr,
        errorOptions,
        dispatch
    } = useContext(SkycityContext)

    async function start (curFile) {
        /* *********************************** */
        const codePayload = {
            inputs: {
                source: {
                    href: curFile.path,
                    storage: getStorage(curFile)
                },
                presets: getLrPresets(inputSecondFiles)
            },
            outputs: await getOptionsOutput(curFile, outputOptionsLr)
        }
        const config = {
            url: `${process.env.REACT_APP_CONFIG_ENDPOINT_ACR}/presets`,
            method: 'POST',
            timeout: 90000,
            headers: getHeaders(),
            payload: codePayload,
            body: JSON.stringify(codePayload)
        }
        /* *********************************** */

        dispatch({ type: 'set-codes', payload: { config: config } })

        apiCall(config, (id, json) => {
            if (id) {
                getStatus(id, config, async (res, error, errResponse) => {
                    if (error) {
                        errorOccured(`${error} - ${JSON.stringify(curFile)}`, errResponse || res)
                    } else if (res && res.outputs && res.outputs[0] && res.outputs[0].status === 'failed') {
                        const errMsg = res.outputs[0].errors ? `${id} - ${JSON.stringify(res.outputs[0].errors, null, 2)}` : `ERROR with ${config.url}`
                        errorOccured(`${JSON.stringify(curFile)} - ${errMsg}`, errResponse || res)
                    } else if (res && res.outputs && res.outputs[0] && res.outputs[0].status === 'succeeded') {
                        const rendition = config.payload.outputs[0];
                        let outputFile = {
                            'href': rendition.getHref,
                            'storage': 'external',
                            'type': rendition.type
                        };
                        await getRenditionCreateWithUtilsStorage(outputFile, 300, 0, (err, renditionSmall, renditionLarge) => {
                            if (err) {
                                console.log(`ERROR: ${JSON.stringify(err)}`)
                                error(`ERROR: ${JSON.stringify(err)}`, {
                                    timeout: 0
                                })
                            } else {

                                files.push({
                                    inputFile: curFile,
                                    name: getOutputFileName(curFile, rendition),
                                    shortName: getFileType(rendition.type),
                                    path: rendition.href,
                                    outputThumbnail: renditionSmall,
                                    output: renditionLarge,
                                    outputToDownload: rendition.getHref
                                });

                                dispatch({
                                    type: 'skycity-success',
                                    payload: {
                                        files: files,
                                        selectedInputFile: files[0],
                                        showLayerInfoPane: true,
                                        loading: false,
                                        wait: false,
                                        response: res
                                    }
                                });
                            }
                        });
                    }
                })
            } else {
                const errMsg = json ? `${JSON.stringify(json, null, 2)}` : `ERROR with ${config.url}`
                errorOccured(`${JSON.stringify(curFile)} - ${errMsg}`, json)
            }
        })
    }

    function run (e) {
        try {
            dispatch({ type: 'skycity-run', payload: {} })

            files = []
            const funcArray = []
            inputFiles.forEach(inputFile => {
                funcArray.push(start(inputFile))
            })
            axios.all(funcArray)
                .then((res) => {
                    // then
                })
                .catch((err) => {
                    const errMsg = `ERROR: ${JSON.stringify(err)}`
                    errorOccured(errMsg)
                })
                .finally(() => {
                    console.log('finally...')
                })
        } catch (e) {
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        }
    }

    function isRunDisabled () {
        if (inputFiles && inputFiles.length > 0 &&
            inputSecondFiles && inputSecondFiles.length > 0 &&
            outputOptionsLr && outputOptionsLr.find(output => output.selected === true)) {
            return false
        }
        return true
    }

    function errorOccured (errMsg, res) {
        console.log(errMsg)
        error(errMsg.slice(0, errorOptions.wordSlice), {
            onClose: () => console.log('close'),
            timeout: errorOptions.msgTimeout
        })
        dispatch({ type: 'error-occured', payload: { errMsg: errMsg, response: res, loading: false, wait: false } })
    }

    try {
        return <div>
            <OutputOptions imageTypes={['image/jpeg', 'image/x-adobe-dng', 'image/png']}/>
            <div className="adobe-skycity-button-right">
                <Button variant="cta" onClick={e => run(e)} disabled={isRunDisabled()}>{__('btn_run')}</Button>
            </div>
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
