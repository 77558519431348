const Desc = `smartObjectV2: cc input file, replace smart object with external asset. ${new Error('To get file path').stack.split('\n')[1].match(/Object(.+)\(https/)[1] || ''}`

const Payload = {
    inputs: [
        {
            href: 'files/_skycity_smoketest/smartObjectV2_cc.psd',
            storage: 'adobe'
        }
    ],
    options: {
        layers: [
            {
                id: 320,
                input: {
                    href: 'https://as2.ftcdn.net/v2/jpg/00/80/88/51/500_F_80885101_vwW81el2bQcXNMnN9mLiNx1wbHjeChrx.jpg',
                    storage: 'external'
                }
            }
        ]
    },
    outputs: [
        {
            storage: 'adobe',
            type: 'image/png',
            overwrite: true,
            width: 300,
            compression: 'large'
        }
    ]
}

module.exports = { Payload, Desc }
