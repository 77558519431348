const Desc = `renditionCreate: Convert from PSD to JPEG. ${new Error('To get file path').stack.split('\n')[1].match(/Object(.+)\(https/)[1] || ''}`

const Payload = {
    inputs: [
        {
            href: 'files/_skycity_smoketest/adobe2s.psd',
            storage: 'adobe'
        }
    ],
    outputs: [
        {
            storage: 'adobe',
            type: 'image/jpeg',
            overwrite: true,
            width: 0,
            quality: 7
        }
    ]
}

module.exports = { Payload, Desc }
