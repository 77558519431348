const request = require('request')

export function asApiCall (method, url, options, cb) {
    try {
        request(options, (error, response, body) => {
            let json
            if (error) {
                console.log(`error: ${error}`)
            }
            if (body) {
                json = JSON.parse(body)
                if (response && response.statusCode >= 400) {
                    cb(null, json)
                } else {
                    cb(json, response)
                }
            } else {
                cb(null, `${response}, JSON.stringify(options): ${JSON.stringify(options)}`)
            }
        })
    } catch (e) {
        cb(null, e)
    }
};
