// {imageInput storage},{layers},{outputImageType,outputWidth,outputQuality,outputStorage,outputicc}

// Change text content to Hello

var Code = [
    { href: 'testing/pits/input/pegasus_pattern.psd', storage: 'aws' },
    { layers: [{ edit: {}, text: { content: 'Hello' }, id: 2 }] },
    { type: 'image/png', width: 500, compression: 'large', storage: 'adobe', icc: {} }
]
module.exports = { Code }
