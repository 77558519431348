import React, { useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import { error } from '@react/react-spectrum/Toast'
import Button from '@react/react-spectrum/Button'

import { apiCallSensei } from '../../lib/ccApi.js'
import { getFilename, getFilenameWithoutExt, getSenseiHeaders, getSignedUlr } from '../../lib/skycity.js'

import axios from 'axios'

export default function Operation (files) {
    const {
        inputFiles,
        inputSecondFiles,
        errorOptions,
        dispatch
    } = useContext(SkycityContext)

    function start (curFile, curSecondFile) {
        try {
            /* *********************************** */
            getSignedUlr(curFile, curFileSignedUrl => {
                if (curFileSignedUrl) {
                    curFile.signedUrl = curFileSignedUrl
                    getSignedUlr(curSecondFile, curSecondFileSignedUrl => {
                        if (curSecondFileSignedUrl) {
                            curSecondFile.signedUrl = curSecondFileSignedUrl
                            const codePayload = {
                                fileURL: curFile.signedUrl,
                                contentAnalyzerRequests: {
                                    enable_diagnostics: 'true',
                                    requests: [{
                                        analyzer_id: `${process.env.REACT_APP_CONFIG_APP_PIESENSEI_ID}`,
                                        parameters: {
                                            background: curFile.signedUrl,
                                            layers: [
                                                curSecondFile.signedUrl
                                            ],
                                            output_format: 'png'
                                        }
                                    }]
                                }
                            }
                            const formData = new FormData()
                            formData.append('fileURL', codePayload.fileURL)
                            formData.append('contentAnalyzerRequests', JSON.stringify(codePayload.contentAnalyzerRequests))

                            const config = {
                                file: curFile,
                                url: `${process.env.REACT_APP_CONFIG_ENDPOINT_PIESENSEI}/predict`,
                                method: 'POST',
                                mode: 'cors',
                                timeout: 90000,
                                headers: getSenseiHeaders(),
                                payload: codePayload,
                                body: {
                                    formData: formData
                                }
                            }
                            /* *********************************** */
                            dispatch({ type: 'set-codes', payload: { config: config } })

                            apiCallSensei(config, (response) => {
                                if (response && response.status < 400) {
                                    const res = JSON.parse(response.response)
                                    const rawImage = res.cas_responses[0].result.response[0].feature_value
                                    // const content_id = res['cas_responses'][0]['content_id'];
                                    files.push({
                                        inputFile: curFile,
                                        secondInput: curSecondFile,
                                        name: getFilename(curSecondFile.path),
                                        shortName: getFilenameWithoutExt(curSecondFile.path),
                                        path: curSecondFile.path,
                                        outputThumbnail: `data:image/png;base64, ${rawImage}`,
                                        output: `data:image/png;base64, ${rawImage}`
                                    })
                                    console.log(JSON.stringify(res, null, 2))
                                    dispatch({
                                        type: 'skycity-success',
                                        payload: {
                                            files: files,
                                            selectedInputFile: files[0],
                                            loading: false,
                                            wait: false,
                                            response: { msg: 'please check the console window for pie on sensei response.' }
                                        }
                                    })
                                } else {
                                    const errMsg = response ? `${JSON.stringify(response, null, 2)}` : `ERROR with ${config.url}`
                                    errorOccured(`${JSON.stringify(curFile)} - ${errMsg}`, response)
                                }
                            })
                        }
                    })
                }
            })
        } catch (e) {
            console.log(e)
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
        }
    }

    function run (e) {
        try {
            dispatch({ type: 'skycity-run', payload: {} })

            files = []
            const funcArray = []
            inputFiles.forEach(curFile => {
                inputSecondFiles.forEach(curSecondFile => {
                    funcArray.push(start(curFile, curSecondFile))
                })
            })
            axios.all(funcArray)
                .then((res) => {
                    // then
                })
                .catch((err) => {
                    const errMsg = `ERROR: ${JSON.stringify(err)}`
                    errorOccured(errMsg)
                })
                .finally(() => {
                    console.log('finally...')
                })
        } catch (e) {
            errorOccured(`${__('error_msg_title')}: ${e}`)
        }
    }

    function isRunDisabled () {
        if (inputFiles && inputFiles.length > 0) {
            return false
        }
        return true
    }

    function errorOccured (errMsg, res) {
        console.log(errMsg)
        error(errMsg.slice(0, errorOptions.wordSlice), {
            onClose: () => console.log('close'),
            timeout: errorOptions.msgTimeout
        })
        dispatch({ type: 'error-occured', payload: { errMsg: errMsg, response: res, loading: false, wait: false } })
    }

    try {
        return <div>
            <div className="adobe-skycity-button-right">
                <Button variant="cta" onClick={e => run(e)} disabled={isRunDisabled()}>{__('btn_run')}</Button>
            </div>
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
