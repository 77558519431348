// ***************************
// App Info in Side Navigation
// ***************************
import React, { useState, useEffect, useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import { name, version, dependencies } from '../../../package.json'
import {
    goToUrlTarget,
    uploadTempAsset,
    getFileViewOption
} from '../../lib/skycity.js'

import gitIcon from '../../asset/git.png'
import slackIcon from '../../asset/slack.png'
import emailIcon from '../../asset/email.png'
import formIcon from '../../asset/form.png'

import Button from '@react/react-spectrum/Button'

export default function AppInfo ({ className }) {
    const {
        dispatch
    } = useContext(SkycityContext)

    const [storageAvailable, setStorageAvailable] = useState(null)
    const [styleStorage, setStyleStorage] = useState({})

    useEffect(() => {
        uploadTempAsset()
        const vo = getFileViewOption()
        dispatch({ type: 'set-file-view-option', payload: { vo: vo } })
        // eslint-disable-next-line
    }, [])

    return <div className={className}>
        <div><b>{__('appinfo_name')}</b>: {name}</div>
        <div><b>{__('appinfo_version')}</b>: {version}</div>
        <div><b>{__('appinfo_env')}</b>: {process.env.REACT_APP_CONFIG_APP_DEV}</div>
        <div><b>{__('appinfo_platform')}</b>: {`${window.navigator.platform}`}</div>
        <div><b>{__('appinfo_react_version')}</b>: {React.version}</div>
        { storageAvailable && <div><b>{__('appinfo_storage_available')}</b>: <span style={styleStorage}>{`${storageAvailable} GB`}</span></div>}
        <div><b>{__('appinfo_spectrum')}</b>: <a href={__('appinfo_spectrum_url')} target="_black">{dependencies['@react/react-spectrum']}</a></div>
        <div><b>{__('appinfo_slack')}</b>: <a href={__('appinfo_slack_url')} target="_black">{__('appinfo_slack_channel')}</a></div>
        <div><b>{__('appinfo_email')}</b>: <a href={__('appinfo_email_add_url')}>{__('appinfo_email_add')}</a></div>
        <br />
        <Button
            variant="tool"
            icon={<img src={gitIcon} width="50px" alt="tool"/>}
            onClick={() => { goToUrlTarget(__('git_url_skycity'), '_blank') }} />
        <Button
            variant="tool"
            icon={<img src={slackIcon} width="50px" alt="tool"/>}
            onClick={() => { goToUrlTarget(__('appinfo_slack_url'), '_blank') }} />
        <Button
            variant="tool"
            icon={<img src={emailIcon} width="50px" alt="tool"/>}
            onClick={() => { goToUrlTarget(__('appinfo_email_add_url'), '_blank') }} />
        <Button
            variant="tool"
            icon={<img src={formIcon} width="50px" alt="tool"/>}
            onClick={() => { goToUrlTarget(__('url_api_signup'), '_blank') }} />

        <br /><br />
        <div>{__('appinfo_copyright_one')}</div>
        <div>{__('appinfo_copyright_two')}</div>
    </div>
}
