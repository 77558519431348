import React from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'

import { error, Toast } from '@react/react-spectrum/Toast'
import Link from '@react/react-spectrum/Link'
import Well from '@react/react-spectrum/Well'
import ModalTrigger from '@react/react-spectrum/ModalTrigger'
import Dialog from '@react/react-spectrum/Dialog'

import welcomeEmail from '../../asset/onboarding/welcome_email.png'
import helloApi from '../../asset/onboarding/hello_api.png'

import Step from '../Shared/Step'

export default function Main () {
    try {
        return <div className="adobe-skycity-main-pane">
            <div className="adobe-skycity-main">
                <div className="adobe-skycity-main">
                    <div className="adobe-skycity-main-column">
                        <div className="adobe-skycity-main-feature">
                            <div>Onboarding Process for your external users</div>
                            <Step num={1} title={__('onboarding_step_signup')} />
                            <p>The external users need to <Link href="https://photoshop.adobelanding.com/prerelease-stack" target="_blank">sign up the prelrease program </Link> to get started.</p>
                            <Step num={2} title={__('onboarding_step_email')} />
                            <p>After they successfully joined the Prerelease Program, they will receive the email with <b>Client ID (API Key)</b> and <b>Client Secret</b>.</p>

                            <ModalTrigger>
                                <img src={welcomeEmail} className="adobe-skycity-onboarding-image-small" alt="welcomeemail" modalTrigger/>
                                <Dialog
                                    modalContent
                                    title={`Email: ${__('onboarding_welcome_msg')}`}
                                    confirmLabel="Close"
                                    size="S">
                                    <img src={welcomeEmail} className="adobe-skycity-onboarding-image-original" alt="welcomeemail" />
                                </Dialog>
                            </ModalTrigger>
                            <Step num={3} title={__('onboarding_step_token')} />
                            <p>The external users can generate a token with their <b>Client ID (API Key)</b> and <b>Client Secret</b> at <Link href={process.env.REACT_APP_CONFIG_ENDPOINT_PRERELEASE_TOKEN} target="_blank">{process.env.REACT_APP_CONFIG_ENDPOINT_PRERELEASE_TOKEN}</Link>.</p>
                            <Toast variant="warning">The token expires in 24 hours.</Toast>
                            <Step num={4} title={__('onboarding_step_hello')} />
                            <p>
                                <ol>
                                    <li>{__('onboarding_step_hello_one')}</li>
                                    <li>{__('onboarding_step_hello_two')}
                                        <Well>{`curl -X GET ${process.env.REACT_APP_CONFIG_ENDPOINT_IMAGE}/pie/psdService/hello -H "x-api-key: `}
                                            <b><i><font color="red">Your Client ID</font></i></b>
                                            &ldquo; -H &ldquo;Authorization: Bearer
                                            <b><i><font color="red"> Your Token</font></i></b>
                                            &ldquo;
                                        </Well>
                                    </li>
                                    <li>If you see <b>{__('onboarding_welcome_msg')}</b> in response, you are ready to use our API!
                                        <br />
                                        <ModalTrigger>
                                            <img src={helloApi} className="adobe-skycity-onboarding-image-small" alt="helloApi" modalTrigger/>
                                            <Dialog
                                                modalContent
                                                title="Hello World API"
                                                confirmLabel="Close"
                                                size="S">
                                                <img src={helloApi} className="adobe-skycity-onboarding-image-original" alt="helloApi" border="1"/>
                                            </Dialog>
                                        </ModalTrigger>
                                    </li>
                                </ol>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
