import React, { useContext, useState } from 'react'

import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import { FormItem } from '@react/react-spectrum/Form'
import Textarea from '@react/react-spectrum/Textarea'
import Link from '@react/react-spectrum/Link'
import Select from '@react/react-spectrum/Select'
import NumberInput from '@react/react-spectrum/NumberInput'
import { error } from '@react/react-spectrum/Toast'
import Alert from '@react/react-spectrum/Icon/Alert'
import UploadToCloud from '@react/react-spectrum/Icon/UploadToCloud'
import Radio from '@react/react-spectrum/Radio'
import RadioGroup from '@react/react-spectrum/RadioGroup'
import Checkbox from '@react/react-spectrum/Checkbox'

import {
    getFilename,
    goToUrlTarget,
    getFilenameWithoutExt,
    getDocumentOperationsConfig,
    postDocumentOperations,
    getPreviewFileDocumentManifest,
    getInputFileFont
} from '../../lib/skycity.js'

export default function FormLayerTextAPI (props) {
    const {
        antiAlias,
        baseline,
        fontCaps,
        autoKern,
        alignment,
        previewFiles,
        errorOptions,
        inputSecondFiles,
        nonPreviewOptions,
        previewEdit,
        supportedFonts,
        dispatch
    } = useContext(SkycityContext)

    const actionType = 'set-input-preview'
    const previewFileDocumentManifest = getPreviewFileDocumentManifest(previewFiles)
    const curLayerProp = 'text'

    const [fonts, setFonts] = useState(supportedFonts)
    const [selectedFont, setSelectedFont] = useState(supportedFonts.find(font => font.selected) && supportedFonts.find(font => font.selected).value)

    if (props.curLayer) {
        const defaultFont = props.curLayer[curLayerProp].characterStyles[0].fontName
        const newFonts = fonts.map(font => {
            font.selected = false
            if (font.value === defaultFont) {
                font.selected = true
            }
            return font
        })
        const fontFind = newFonts.find(font => font.selected)
        if (!fontFind) {
            newFonts.push({
                label: defaultFont,
                value: defaultFont,
                selected: true,
                icon: <Alert size="S" />
            })
            setFonts(newFonts)
        }
        if (inputSecondFiles && inputSecondFiles.length > 0) {
            inputSecondFiles.forEach(file => {
                const fontFind = newFonts.find(font => font.value === file.shortName)
                if (!fontFind) {
                    newFonts.push({
                        label: file.shortName,
                        value: file.shortName,
                        selected: false,
                        icon: <UploadToCloud size="S" />
                    })
                    setFonts(newFonts)
                }
            })
        }
        if (selectedFont !== defaultFont) {
            setSelectedFont(defaultFont)
        }
    }

    function changeFont (e, curLayer, textProp) {
        const newFonts = fonts.map(font => {
            font.selected = false
            if (font.value === e) {
                font.selected = true
            }
            return font
        })
        setFonts(newFonts)
        changeCharacterStyle(e, curLayer, textProp)
    }

    function errorOccured (errMsg, res) {
        console.log(errMsg)
        error(errMsg.slice(0, errorOptions.wordSlice), {
            onClose: () => console.log('close'),
            timeout: errorOptions.timeout
        })
        dispatch({ type: 'error-occured', payload: { errMsg: errMsg, response: res, loading: false, wait: false } })
    }

    function getSelectedLayers (newDocumentManifest, cb) {
        return newDocumentManifest.forEach(function iter (a) {
            if ([props.curLayer.id].includes(a.id)) {
                cb(a)
            } else if (a.children) {
                getSelectedLayers(a.children, cb)
            }
        })
    }

    function changeValue (e, curLayer, textProp) {
        if (e === curLayer[curLayerProp][textProp]) {
            return
        }
        const editedLayer = {
            [curLayerProp]: {
                [textProp]: e
            },
            id: curLayer.id
        }
        if (!previewEdit) {
            if (inputSecondFiles) {
                nonPreviewOptions.fonts = getInputFileFont(inputSecondFiles)
            }
            if (nonPreviewOptions.layers) {
                nonPreviewOptions.layers.push(editedLayer)
            } else {
                nonPreviewOptions.layers = [editedLayer]
            }
            return
        }
        dispatch({
            type: 'skycity-psedit-run',
            payload: {
                loading: true,
                wait: true
            }
        })
        const layers = [editedLayer]
        const options = {
            fonts: getInputFileFont(inputSecondFiles),
            layers: layers
        }
        getDocumentOperationsConfig(previewFiles[0], options, config => {
            if (config) {
                try {
                    dispatch({ type: 'set-codes', payload: { config } })
                    postDocumentOperations(previewFiles[0], previewFileDocumentManifest, config, (res, err, files) => {
                        if (res && !err) {
                            getSelectedLayers(files[0].documentManifest.outputs[0].layers, newLayers => {
                                files[0].name = getFilename(files[0].href)
                                files[0].shortName = getFilenameWithoutExt(files[0].href)
                                dispatch({
                                    type: actionType,
                                    payload: {
                                        files: files,
                                        loading: false,
                                        wait: false,
                                        response: res,
                                        layers: [newLayers]
                                    }
                                })
                            })
                        } else {
                            errorOccured(`${__('browser_cc_get_document_manifest_error')}: ${err}`)
                        }
                    })
                } catch (e) {
                    errorOccured(`${__('getDocumentOperations_error')}: ${e}`)
                }
            }
        })
    }

    function changeCharacterStyle (e, curLayer, textProp) {
        if (e === props.curLayer[curLayerProp].characterStyles[0][textProp]) {
            return
        }
        const editedLayer = {
            [curLayerProp]: {
                characterStyles: [{
                    [textProp]: e
                }]
            },
            id: curLayer.id
        }
        if (!previewEdit) {
            if (inputSecondFiles) {
                nonPreviewOptions.fonts = getInputFileFont(inputSecondFiles)
            }
            if (nonPreviewOptions.layers) {
                nonPreviewOptions.layers.push(editedLayer)
            } else {
                nonPreviewOptions.layers = [editedLayer]
            }
            return
        }
        dispatch({
            type: 'skycity-psedit-run',
            payload: {
                loading: true,
                wait: true
            }
        })
        const options = {
            fonts: getInputFileFont(inputSecondFiles),
            layers: [editedLayer]
        }
        getDocumentOperationsConfig(previewFiles[0], options, config => {
            if (config) {
                try {
                    dispatch({ type: 'set-codes', payload: { config } })
                    postDocumentOperations(previewFiles[0], previewFileDocumentManifest, config, (res, err, files) => {
                        if (res && !err) {
                            getSelectedLayers(files[0].documentManifest.outputs[0].layers, newLayers => {
                                files[0].name = getFilename(files[0].href)
                                files[0].shortName = getFilenameWithoutExt(files[0].href)
                                dispatch({
                                    type: actionType,
                                    payload: {
                                        files: files,
                                        loading: false,
                                        wait: false,
                                        response: res,
                                        layers: [newLayers]
                                    }
                                })
                            })
                        } else {
                            errorOccured(`${__('browser_cc_get_document_manifest_error')}: ${err}`)
                        }
                    })
                } catch (e) {
                    errorOccured(`${__('getDocumentOperations_error')}: ${e}`)
                }
            }
        })
    }

    function changeParagraphStyles (e, curLayer, textProp) {
        if (e === props.curLayer[curLayerProp].characterStyles[0][textProp]) {
            return
        }
        const editedLayer = {
            [curLayerProp]: {
                paragraphStyles: [{
                    [textProp]: e
                }]
            },
            id: curLayer.id
        }
        if (!previewEdit) {
            if (inputSecondFiles) {
                nonPreviewOptions.fonts = getInputFileFont(inputSecondFiles)
            }
            if (nonPreviewOptions.layers) {
                nonPreviewOptions.layers.push(editedLayer)
            } else {
                nonPreviewOptions.layers = [editedLayer]
            }
            return
        }
        dispatch({
            type: 'skycity-psedit-run',
            payload: {
                loading: true,
                wait: true
            }
        })
        const options = {
            fonts: getInputFileFont(inputSecondFiles),
            layers: [editedLayer]
        }
        getDocumentOperationsConfig(previewFiles[0], options, config => {
            if (config) {
                try {
                    dispatch({ type: 'set-codes', payload: { config } })
                    postDocumentOperations(previewFiles[0], previewFileDocumentManifest, config, (res, err, files) => {
                        if (res && !err) {
                            getSelectedLayers(files[0].documentManifest.outputs[0].layers, newLayers => {
                                files[0].name = getFilename(files[0].href)
                                files[0].shortName = getFilenameWithoutExt(files[0].href)
                                dispatch({
                                    type: actionType,
                                    payload: {
                                        files: files,
                                        loading: false,
                                        wait: false,
                                        response: res,
                                        layers: [newLayers]
                                    }
                                })
                            })
                        } else {
                            errorOccured(`${__('browser_cc_get_document_manifest_error')}: ${err}`)
                        }
                    })
                } catch (e) {
                    errorOccured(`${__('getDocumentOperations_error')}: ${e}`)
                }
            }
        })
    }

    function getDefaultValue (textProp) {
        if (props.curLayer) {
            return props.curLayer[curLayerProp][textProp]
        }
        return ''
    }

    function getDefaultValueCharacterStyle (textProp) {
        if (props.curLayer) {
            return props.curLayer[curLayerProp].characterStyles[0][textProp]
        }
        return ''
    }

    return <React.Fragment>
        <FormItem label='Content'>
            <Textarea className="adobe-skycity-psedit-text-content" defaultValue={ getDefaultValue('content') } onBlur={ e => changeValue(e.target.value, props.curLayer, 'content')}/>
        </FormItem>
        <FormItem label='Font Name'>
            <Select defaultValue={ selectedFont } options={ fonts } onChange={ e => changeFont(e, props.curLayer, 'fontPostScriptName') }/>
            <br />
            <Link onClick={() => { goToUrlTarget(__('api_doc_link_prod_supported_fonts'), '_blank') }} >Supported Fonts</Link>
        </FormItem>
        <FormItem label='Font Size'>
            <NumberInput defaultValue={ getDefaultValueCharacterStyle('fontSize') } onBlur={ e => changeCharacterStyle(parseFloat(e.target.value), props.curLayer, 'size')}/>
        </FormItem>
        <FormItem label='Orientation'>
            <RadioGroup name="orientation" defaultSelectedValue={ getDefaultValueCharacterStyle('orientation') } onChange={e => changeCharacterStyle(e, props.curLayer, 'orientation')}>
                <Radio label="horizontal" value="horizontal" />
                <Radio label="vertical" value="vertical" />
            </RadioGroup>
        </FormItem>
        <FormItem label='Anti Alias'>
            <Select
                options={antiAlias}
                defaultValue=""
                onChange={e => changeValue(e, props.curLayer, 'antiAlias')} />
        </FormItem>
        <FormItem label='Leading'>
            <NumberInput onBlur={e => changeCharacterStyle(parseFloat(e.target.value), props.curLayer, 'leading')} />
        </FormItem>
        <FormItem label='Tracking'>
            <NumberInput onBlur={e => changeCharacterStyle(parseFloat(e.target.value), props.curLayer, 'tracking')} />
        </FormItem>
        <FormItem label='Baseline'>
            <Select
                options={baseline}
                defaultValue=""
                onChange={e => changeCharacterStyle(e, props.curLayer, 'baseline')} />
        </FormItem>
        <FormItem label='Font Caps'>
            <Select
                options={fontCaps}
                defaultValue=""
                onChange={e => changeCharacterStyle(e, props.curLayer, 'fontCaps')} />
        </FormItem>
        <FormItem label='Auto Kern'>
            <Select
                options={autoKern}
                defaultValue=""
                onChange={e => changeCharacterStyle(e, props.curLayer, 'autoKern')} />
        </FormItem>
        <FormItem label='Strikethrough'>
            <Checkbox
                onChange={e => changeCharacterStyle(e, props.curLayer, 'strikethrough')} />
        </FormItem>
        <FormItem label='Synthetic Bold'>
            <Checkbox
                onChange={e => changeCharacterStyle(e, props.curLayer, 'syntheticBold')} />
        </FormItem>
        <FormItem label='Synthetic Italic'>
            <Checkbox
                onChange={e => changeCharacterStyle(e, props.curLayer, 'syntheticItalic')} />
        </FormItem>
        <FormItem label='Underline'>
            <Checkbox
                onChange={e => changeCharacterStyle(e, props.curLayer, 'underline')} />
        </FormItem>
        <FormItem label='Ligature'>
            <Checkbox
                onChange={e => changeCharacterStyle(e, props.curLayer, 'ligature')} />
        </FormItem>
        <FormItem label='Fractions'>
            <Checkbox
                onChange={e => changeCharacterStyle(e, props.curLayer, 'fractions')} />
        </FormItem>
        <FormItem label='Stylistic Alternates'>
            <Checkbox
                onChange={e => changeCharacterStyle(e, props.curLayer, 'stylisticAlternates')} />
        </FormItem>
        <FormItem label='Vertical Scale'>
            <NumberInput onBlur={e => changeCharacterStyle(parseFloat(e.target.value), props.curLayer, 'verticalScale')} />
        </FormItem>
        <FormItem label='Horizontal Scale'>
            <NumberInput onBlur={e => changeCharacterStyle(parseFloat(e.target.value), props.curLayer, 'horizontalScale')} />
        </FormItem>

        <FormItem label='Alignment'>
            <Select
                options={alignment}
                defaultValue=""
                onChange={e => changeParagraphStyles(e, props.curLayer, 'alignment')} />
        </FormItem>
        <FormItem label='Start Indent'>
            <NumberInput onBlur={e => changeParagraphStyles(parseFloat(e.target.value), props.curLayer, 'startIndent')} />
        </FormItem>
        <FormItem label='End Indent'>
            <NumberInput onBlur={e => changeParagraphStyles(parseFloat(e.target.value), props.curLayer, 'endIndent')} />
        </FormItem>
        <FormItem label='Hyphenate'>
            <Checkbox
                onChange={e => changeParagraphStyles(e, props.curLayer, 'hyphenate')} />
        </FormItem>
        <FormItem label='First Line Indent'>
            <NumberInput onBlur={e => changeParagraphStyles(parseFloat(e.target.value), props.curLayer, 'firstLineIndent')} />
        </FormItem>
        <FormItem label='Space Before'>
            <NumberInput onBlur={e => changeParagraphStyles(parseFloat(e.target.value), props.curLayer, 'spaceBefore')} />
        </FormItem>
        <FormItem label='Space After'>
            <NumberInput onBlur={e => changeParagraphStyles(parseFloat(e.target.value), props.curLayer, 'spaceAfter')} />
        </FormItem>
    </React.Fragment>
}
