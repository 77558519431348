const Desc = `renditionCreate: Convert from PNG to PNG with width 100px. ${new Error('To get file path').stack.split('\n')[1].match(/Object(.+)\(https/)[1] || ''}`

const Payload = {
    inputs: [
        {
            href: 'https://skycity-asset.adobedice.com/testing/pits/input/adobe1.png',
            storage: 'external'
        }
    ],
    outputs: [
        {
            storage: 'adobe',
            type: 'image/png',
            overwrite: true,
            width: 100,
            compression: 'small'
        }
    ]
}

module.exports = { Payload, Desc }
