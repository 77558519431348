// {imageInput storage},{layers},{outputImageType,outputWidth,outputQuality,outputStorage,outputicc}

// set layer visibility to false

var Code = [
    { href: 'files/_skycity_smoketest/adobe2s.psd', storage: 'adobe' },
    { layers: [{ edit: {}, id: -1, visible: false }] },
    { type: 'image/png', width: 300, compression: 'large', storage: 'adobe', icc: {} }
]
module.exports = { Code }
