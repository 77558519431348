import React, { useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import Checkbox from '@react/react-spectrum/Checkbox'
import Heading from '@react/react-spectrum/Heading'
import Well from '@react/react-spectrum/Well'
import Select from '@react/react-spectrum/Select'
import FieldLabel from '@react/react-spectrum/FieldLabel'
import Textfield from '@react/react-spectrum/Textfield'
import Button from '@react/react-spectrum/Button'
import Alert from '@react/react-spectrum/Alert'
import AddCircle from '@react/react-spectrum/Icon/AddCircle'
import Delete from '@react/react-spectrum/Icon/Delete'
import { error } from '@react/react-spectrum/Toast'

const uuid = require('uuid/v4')

export default function OutputOptions (props) {
    // const stepNum = props.stepNum ? `${props.stepNum} ` : '';
    const {
        page,
        outputJpegOption,
        outputJpegOptionLr,
        outputPngOption,
        outputTiffOption,
        outputPsdOption,
        outputDngOption,
        outputOptionsLr,
        outputOptions,
        dispatch
    } = useContext(SkycityContext)

    function getImageTypes (imageTypes) {
        const imageTypesButtons = props.imageTypes.map(imageType => {
            switch (imageType) {
            case 'image/jpeg':
                return <Button key={imageType} variant="action" value="jpeg" icon={<AddCircle />} onClick={ e => addOutputFormat('jpeg') }>JPEG</Button>
            case 'image/png':
                return <Button key={imageType} variant="action" value="png" icon={<AddCircle />} onClick={ e => addOutputFormat('png') }>PNG</Button>
            case 'image/vnd.adobe.photoshop':
                return <Button key={imageType} variant="action" value="psd" icon={<AddCircle />} onClick={ e => addOutputFormat('psd') }>PSD</Button>
            case 'image/tiff':
                return <Button key={imageType} variant="action" value="tiff" icon={<AddCircle />} onClick={ e => addOutputFormat('tiff') }>TIFF</Button>
            case 'image/x-adobe-dng':
                return <Button key={imageType} variant="action" value="dng" icon={<AddCircle />} onClick={ e => addOutputFormat('dng') }>DNG</Button>
            default:
                return null
            }
        })
        return <React.Fragment>
            <Heading className="adobe-skycity-subtitle1" variant="subtitle1">File Format</Heading>
            {imageTypesButtons}
        </React.Fragment>
    }

    function getDefaultItem (items) {
        const selected = items.find(item => item.selected === true)
        return selected.value
    }

    function changeFileTypeSelection (e, curOption) {
        if (props.isLrFeature) {
            const newOutputOptions = outputOptionsLr.map(curOutputOption => {
                if (curOutputOption.id === curOption.id) {
                    curOutputOption.selected = e
                }
                return curOutputOption
            })
            dispatch({ type: 'set-outputOptions-lr', payload: { outputOptionsLr: newOutputOptions } })
        } else {
            const newOutputOptions = outputOptions.map(curOutputOption => {
                if (curOutputOption.id === curOption.id) {
                    curOutputOption.selected = e
                }
                return curOutputOption
            })
            dispatch({ type: 'set-outputOptions', payload: { outputOptions: newOutputOptions } })
        }
    }

    function changeWidth (e, curOption) {
        if (props.isLrFeature) {
            const newOutputOptions = outputOptionsLr.map(curOutputOption => {
                if (curOutputOption.id === curOption.id) {
                    curOutputOption.width = parseInt(e.target.value)
                }
                return curOutputOption
            })
            dispatch({ type: 'set-outputOptions-lr', payload: { outputOptionsLr: newOutputOptions } })
        } else {
            const newOutputOptions = outputOptions.map(curOutputOption => {
                if (curOutputOption.id === curOption.id) {
                    curOutputOption.width = parseInt(e.target.value)
                }
                return curOutputOption
            })
            dispatch({ type: 'set-outputOptions', payload: { outputOptions: newOutputOptions } })
        }
    }

    function addOutputFormat (e) {
        let newOutputOption = {}
        switch (e) {
        case 'jpeg':
            if (props.isLrFeature) {
                newOutputOption = JSON.parse(JSON.stringify(outputJpegOptionLr))
            } else {
                newOutputOption = JSON.parse(JSON.stringify(outputJpegOption))
            }
            break
        case 'png':
            newOutputOption = JSON.parse(JSON.stringify(outputPngOption))
            break
        case 'tiff':
            newOutputOption = JSON.parse(JSON.stringify(outputTiffOption))
            break
        case 'psd':
            newOutputOption = JSON.parse(JSON.stringify(outputPsdOption))
            break
        case 'dng':
            newOutputOption = JSON.parse(JSON.stringify(outputDngOption))
            break
        default:
        }
        newOutputOption.id = uuid()
        if (props.isLrFeature) {
            // Since Lr APIs currently allow only one output per API, always set this array with just one element.
            // In the future when we support multiple outputs, this block of code will become like the else block below.
            dispatch({ type: 'set-outputOptions-lr', payload: { outputOptionsLr: [newOutputOption] } })
        } else {
            const newOutputOptions = outputOptions.map(curOutputOption => {
                return curOutputOption
            })
            newOutputOptions.push(newOutputOption)
            dispatch({ type: 'set-outputOptions', payload: { outputOptions: newOutputOptions } })
        }
    }

    function getOptions (newValue, item) {
        let array = []

        switch (item) {
        case 'quality':
            if (props.isLrFeature) {
                array = JSON.parse(JSON.stringify(outputJpegOptionLr.quality))
            } else {
                array = JSON.parse(JSON.stringify(outputJpegOption.quality))
            }
            break
        case 'compression':
            array = JSON.parse(JSON.stringify(outputPngOption.compression))
            break
        default:
        }

        const newArray = array.map(a => {
            a.selected = false
            if (a.value === newValue) {
                a.selected = true
            }
            return a
        })
        return newArray
    }

    function changeItem (newValue, curOption, item) {
        if (props.isLrFeature) {
            const newOutputOptions = outputOptionsLr.map(curOutputOption => {
                if (curOutputOption.id === curOption.id) {
                    curOutputOption[item] = getOptions(newValue, item)
                }
                return curOutputOption
            })
            dispatch({ type: 'set-outputOptions-lr', payload: { outputOptionsLr: newOutputOptions } })
        } else {
            const newOutputOptions = outputOptions.map(curOutputOption => {
                if (curOutputOption.id === curOption.id) {
                    curOutputOption[item] = getOptions(newValue, item)
                }
                return curOutputOption
            })
            dispatch({ type: 'set-outputOptions', payload: { outputOptions: newOutputOptions } })
        }
    }

    function deleteOutputOption (e, curOption) {
        if (props.isLrFeature) {
            const newOutputOptions = outputOptionsLr.filter(curOutputOption => curOutputOption.id !== curOption.id)
            dispatch({ type: 'set-outputOptions-lr', payload: { outputOptionsLr: newOutputOptions } })
        } else {
            const newOutputOptions = outputOptions.filter(curOutputOption => curOutputOption.id !== curOption.id)
            dispatch({ type: 'set-outputOptions', payload: { outputOptions: newOutputOptions } })
        }
    }

    function getWidthDisabled (val) {
        const regex = /^lr|pscrop/gi
        if (page.match(regex)) {
            return true
        } else {
            return val
        }
    }

    function getOutputOptions () {
        let outputOptionsCurrent = outputOptions
        if (props.isLrFeature) {
            // Lr APIs currently allow only one output per API.
            outputOptionsCurrent = outputOptionsLr
        }
        // outputOptions.sort((a, b) => (a.format > b.format) ? 1 : -1);
        if (!outputOptionsCurrent || outputOptionsCurrent.length === 0) {
            return <Alert header={__('error_no_output_format_selected_title')} variant="error">{__('error_no_output_format_selected')}</Alert>
        }
        const outputOptionsComp = outputOptionsCurrent.map(outputOption => {
            switch (outputOption.format) {
            case 'jpeg':
                return <React.Fragment key={outputOption.id}>
                    <Well>
                        <div className="adobe-skycity-options" key={outputOption.id} title={`ID: ${outputOption.id}`}>
                            <div className="adobe-skycity-option">
                                <Checkbox value={outputOption.type}
                                    defaultChecked={outputOption.selected}
                                    id={outputOption.id}
                                    onChange={ e => changeFileTypeSelection(e, outputOption)}>{outputOption.format.toUpperCase()}</Checkbox>
                                <FieldLabel className="adobe-skycity-options-field" label="Width" position="left">
                                    <Textfield
                                        id={outputOption.id}
                                        disabled={getWidthDisabled(!outputOption.selected)}
                                        onBlur={e => changeWidth(e, outputOption)}
                                        defaultValue={outputOption.width} />
                                </FieldLabel>
                                <FieldLabel label="Quality" labelFor={outputOption.id} position="left">
                                    <Select
                                        disabled={!outputOption.selected}
                                        options={outputOption.quality}
                                        id={outputOption.id}
                                        onChange={e => changeItem(e, outputOption, 'quality')}
                                        defaultValue={ getDefaultItem(outputOption.quality) } />
                                </FieldLabel>
                            </div>
                            <div className="adobe-skycity-output-option-delete-button">
                                <Button variant="tool" icon={<Delete size="XS"/>} onClick={ e => deleteOutputOption(e, outputOption) } />
                            </div>
                        </div>
                    </Well>
                </React.Fragment>
            case 'png':
                return <React.Fragment key={outputOption.id}>
                    <Well>
                        <div className="adobe-skycity-options" key={outputOption.id} title={`ID: ${outputOption.id}`}>
                            <div className="adobe-skycity-option">
                                <Checkbox value={outputOption.type}
                                    id={outputOption.id}
                                    defaultChecked={outputOption.selected}
                                    onChange={e => changeFileTypeSelection(e, outputOption)}>{outputOption.format.toUpperCase()}</Checkbox>
                                <FieldLabel className="adobe-skycity-options-field" label="Width" position="left">
                                    <Textfield
                                        id={outputOption.id}
                                        disabled={getWidthDisabled(!outputOption.selected)}
                                        onBlur={e => changeWidth(e, outputOption)}
                                        defaultValue={outputOption.width} />
                                </FieldLabel>
                                <FieldLabel label="Compression" labelFor="select-png-compression" position="left">
                                    <Select
                                        id="select-png-compression"
                                        aria-label="Compression Level"
                                        disabled={!outputOption.selected}
                                        options={outputOption.compression}
                                        onChange={e => changeItem(e, outputOption, 'compression')}
                                        defaultValue={getDefaultItem(outputOption.compression)} />
                                </FieldLabel>
                            </div>
                            <div className="adobe-skycity-output-option-delete-button">
                                <Button variant="tool" icon={<Delete size="XS"/>} onClick={ e => deleteOutputOption(e, outputOption) } />
                            </div>
                        </div>
                    </Well>
                </React.Fragment>
            default:
                return <React.Fragment key={outputOption.id}>
                    <Well>
                        <div className="adobe-skycity-options" key={outputOption.id} title={`ID: ${outputOption.id}`}>
                            <div className="adobe-skycity-option">
                                <Checkbox value={outputOption.type}
                                    id={outputOption.id}
                                    defaultChecked={outputOption.selected}
                                    onChange={e => changeFileTypeSelection(e, outputOption)}>{outputOption.format.toUpperCase()}</Checkbox>
                                <FieldLabel className="adobe-skycity-options-field" label="Width" position="left">
                                    <Textfield
                                        id={outputOption.id}
                                        disabled={getWidthDisabled(!outputOption.selected)}
                                        onBlur={e => changeWidth(e, outputOption)}
                                        defaultValue={outputOption.width} />
                                </FieldLabel>
                            </div>
                            <div className="adobe-skycity-output-option-delete-button">
                                <Button variant="tool" icon={<Delete size="XS"/>} onClick={ e => deleteOutputOption(e, outputOption) } />
                            </div>
                        </div>
                    </Well>
                </React.Fragment>
            }
        })
        return outputOptionsComp
    }

    try {
        return <div className="adobe-skycity-output-options">
            {getImageTypes(props.imageTypes)}
            <br /><br />
            {getOutputOptions()}
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
