import React from 'react'
import ReactDOM from 'react-dom'
import Provider from '@react/react-spectrum/Provider'
import './style/index.css'
import * as serviceWorker from './serviceWorker'

import App from './component/App'

ReactDOM.render(
    <Provider theme="light" scale="medium" typekitId="wvs4frr" className="adobe-skycity-provider">
        <App />
    </Provider>, document.getElementById('root'))

serviceWorker.unregister()
