const Desc = `documentOperations: insert png image from cc. ${new Error('To get file path').stack.split('\n')[1].match(/Object(.+)\(https/)[1] || ''}`

const Payload = {
    inputs: [
        {
            href: 'files/_skycity_smoketest/adobe2s.psd',
            storage: 'adobe'
        }
    ],
    options: {
        layers: [
            {
                add: {
                    insertTop: true
                },
                type: 'smartObject',
                input: {
                    href: 'files/_skycity_smoketest/adobelogo_red.png',
                    storage: 'adobe'
                }
            }
        ]
    },
    outputs: [
        {
            storage: 'adobe',
            type: 'vnd.adobe.photoshop',
            overwrite: true,
            width: 300
        }
    ]
}
module.exports = { Payload, Desc }
