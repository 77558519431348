import React from 'react'

import { Card, CardBody, CardPreview } from '@react/react-spectrum/Card'
import { Asset } from '@react/react-spectrum/Asset'
import { MenuItem } from '@react/react-spectrum/Menu'
import ModalTrigger from '@react/react-spectrum/ModalTrigger'
import Dialog from '@react/react-spectrum/Dialog'
import DropdownButton from '@react/react-spectrum/DropdownButton'

import filesize from 'filesize'
import { getRendition, getExtensionFromPath, downloadOutputFile } from './skycity.js'

function getFileSize (num) {
    if (num) {
        return filesize(num, { round: 0 })
    } else {
        return ''
    }
}

function getThumbnail (files) {
    const cards = files.map(file => {
        const beforeImage = file.inputFile ? (file.inputFile.thumbnail_url_l || getRendition(file.inputFile)) : getRendition(file)
        const afterImage = file.output
        const afterImageThumbnail = file.outputThumbnail
        const secondInputImage = file.secondInput ? getRendition(file.secondInput, 0, 'secondInput') : null

        const previewClassName = (file.mask || file.secondInput) ? 'adobe-skycity-output-preview-col' : 'adobe-skycity-output-preview-row'
        // const fileTitle = decodeURI(file.name) // (file.mask || file.secondInput) ? decodeURI(file.name) : decodeURI(file.shortName);
        const fileTitle = file.name
        const fileSubtitle = (file.mask || file.secondInput || file.name) ? getExtensionFromPath(file.name) : getExtensionFromPath(file.output)
        const fileSize = (file.mask || file.secondInput) ? null : getFileSize(file.size)

        return <div className="adobe-skycity-asset-card" key={file.path}>
            <Card
                allowsSelection={false}
                key={file.path}
                actionMenu={
                    <DropdownButton onSelect={e => downloadOutputFile(e, file)}>
                        <MenuItem value="download">Download</MenuItem>
                    </DropdownButton>
                }>
                <CardPreview>
                    <ModalTrigger>
                        <div>
                            <Asset
                                className="adobe-skycity-cursor-pointer"
                                type="image"
                                src={afterImageThumbnail} cache/>
                        </div>
                        <Dialog title={file.name} confirmLabel="OK" mode="fullscreen">
                            <div className={previewClassName}>
                                <Asset
                                    className="adobe-skycity-cursor-pointer"
                                    type="image"
                                    src={beforeImage} cache/>
                                { file.mask ? <Asset
                                    className="adobe-skycity-cursor-pointer"
                                    type="image"
                                    src={file.mask} /> : null }
                                { file.secondInput ? <Asset
                                    className="adobe-skycity-cursor-pointer"
                                    type="image"
                                    src={secondInputImage} cache/> : null }
                                <Asset
                                    className="adobe-skycity-cursor-pointer"
                                    type="image"
                                    src={afterImage} cache/>
                            </div>
                        </Dialog>
                    </ModalTrigger>
                </CardPreview>
                <CardBody title={fileTitle} subtitle={fileSubtitle} description={fileSize} />
            </Card>
        </div>
    })
    return cards
}

function getThumbnails (files, opacity) {
    return <div className="adobe-skycity-thumbnail-container">
        {getThumbnail(files)}
    </div>
}

export default getThumbnails
