import React, { useContext } from 'react'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import { FormItem } from '@react/react-spectrum/Form'
import Switch from '@react/react-spectrum/Switch'

export default function PreviewEdit (props) {
    const {
        previewEdit,
        dispatch
    } = useContext(SkycityContext)

    function changePreviewEdit (e) {
        dispatch({ type: 'set-previewEdit', payload: { previewEdit: e } })
    }
    return <FormItem label={props.label}>
        <Switch defaultChecked={ previewEdit } onChange={ e => changePreviewEdit(e)}/>
    </FormItem>
}
