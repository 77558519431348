const Desc = `renditionCreate: Convert from PNG to JPEG. ${new Error('To get file path').stack.split('\n')[1].match(/Object(.+)\(https/)[1] || ''}`

const Payload = {
    inputs: [
        {
            href: 'https://skycity-asset.adobedice.com/testing/pits/input/adobe1.png',
            storage: 'external'
        }
    ],
    outputs: [
        {
            storage: 'adobe',
            type: 'image/jpeg',
            overwrite: true,
            width: 0,
            quality: 7
        }
    ]
}

module.exports = { Payload, Desc }
