import React, { useContext } from 'react'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import { FormItem } from '@react/react-spectrum/Form'
import Switch from '@react/react-spectrum/Switch'

export default function PreviewOnLinux (props) {
    const {
        previewOnLinux,
        dispatch
    } = useContext(SkycityContext)

    function changePreviewOnLinux (e) {
        dispatch({ type: 'set-previewOnLinux', payload: { previewOnLinux: e } })
    }
    return <FormItem label={props.label}>
        <Switch defaultChecked={ previewOnLinux } onChange={ e => changePreviewOnLinux(e)}/>
    </FormItem>
}
