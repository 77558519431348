// {imageInput storage},{layers},{outputImageType,outputWidth,outputQuality,outputStorage,outputicc}

const Desc = `documentOperations: Change text content and font style. ${new Error('To get file path').stack.split('\n')[1].match(/Object(.+)\(https/)[1] || ''}`

const Payload = {
    inputs: [
        {
            href: 'files/_skycity_smoketest/galit-happy-birthday.psd',
            storage: 'adobe'
        }
    ],
    options: {
        fonts: [
            {
                href: 'files/_skycity_smoketest/AlteHaasGrotesk_Bold.ttf',
                storage: 'adobe'
            },
            {
                href: 'files/_skycity_smoketest/AlteHaasGroteskRegular.ttf',
                storage: 'adobe'
            },
            {
                href: 'files/_skycity_smoketest/oliver__.ttf',
                storage: 'adobe'
            }
        ],
        layers: [
            {
                edit: {},
                id: 3,
                text: {
                    content: 'Happy Friday',
                    characterStyles: [
                        {
                            fontName: 'AlteHaasGroteskRegular'
                        }
                    ]
                }
            },
            {
                edit: {},
                id: 4,
                text: {
                    content: 'CIS Team',
                    characterStyles: [
                        {
                            fontName: 'AlteHaasGrotesk_Bold'
                        }
                    ]
                }
            },
            {
                edit: {},
                id: 26,
                text: {
                    content: 'Kaori'
                }
            }
        ]
    },
    outputs: [
        {
            storage: 'adobe',
            type: 'image/png',
            overwrite: true,
            width: 0,
            compression: 'small'
        }
    ]
}

module.exports = { Payload, Desc }
