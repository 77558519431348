import { setCcParams, setAwsParams } from './psText/index.js'
const Uuid = require('uuid/v4')
const pHelper = require('./param_helper.js')

// Input files from CC
export function getCcSharedParams () {
    const params = setCcParams()
    return params
}

// Input files from AWS (process.env.s3_bucket)
export function getAwsSharedParams () {
    const params = setAwsParams()
    return params
}

export function getParams (storages) {
    const testCases = []
    storages.forEach(storage => {
        switch (storage.value) {
        case 'aws': {
            const testCasesAws = getAwsParams()
            testCases.push(...testCasesAws)
            break
        }
        case 'cc': {
            const testCasesCc = getCcParams()
            testCases.push(...testCasesCc)
            break
        }
        default:
        }
    })
    return testCases
}

export function getCcParams () {
    const sharedParams = getCcSharedParams()
    const options = sharedParams.map(param => {
        return getRequestBody(param[0], param[1], param[2])
    })
    return options
}

export function getAwsParams () {
    const sharedParams = getAwsSharedParams()
    const options = sharedParams.map(param => {
        return getRequestBody(param[0], param[1], param[2])
    })
    return options
}

function getRequestBody (imgInput, secondInput, output) {
    const requestBody = {
        inputs: [pHelper.getInputObj(imgInput)],
        options: secondInput,
        outputs: [getOutputObj(output, secondInput)]
    }
    return {
        body: requestBody
    }
}

function getOutputObj (output, secondInput) {
    const outputObj = {
        storage: output.storage,
        type: output.type,
        overwrite: true,
        width: output.width,
        selected: true,
        format: pHelper.getExtentionFromType(output.type),
        href: getOutputHref(secondInput, output)
    }
    switch (output.type) {
    case 'image/png':
        outputObj.compression = output.compression
        break
    case 'image/jpeg':
    case 'image/jpg':
        outputObj.quality = output.quality
        break
    default:
    }
    return outputObj
}

function getOutputHref (secondInput, output) {
    return `${pHelper.getOutputTempDirectory()}/_psEdit/${Uuid()}.${pHelper.getExtentionFromType(output.type)}`
}
