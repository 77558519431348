import {
    getHeaders,
    getStorage
} from '../../lib/skycity.js'

import { getParams } from '../params/ssImagecutout.param.js'
import { prepareBodyForExternal } from './testHelper.js'

export function getConfig (curFile, outputOptions) {
    const payload = getCodePayload(curFile, outputOptions)
    const config = {
        url: `${process.env.REACT_APP_CONFIG_ENDPOINT_IMAGE}/sensei/cutout`,
        method: 'POST',
        timeout: 10000,
        headers: getHeaders(),
        payload: payload,
        body: JSON.stringify(payload)
    }
    return config
}

function getCodePayload (curFile, outputOptions) {
    const codePayload = {
        input: {
            href: curFile.path || curFile.href,
            storage: getStorage(curFile)
        },
        output: outputOptions // getOptionsOutput(curFile, outputOptions)
    }
    return codePayload
}

// ************************************************************************
// Test Cases
// ************************************************************************
export async function getTestCases (storages) {
    const testCases = await getParams(storages)
    const newTestCases = await Promise.all(testCases.map(async (testCase) => {
        testCase = await prepareBodyForExternal(testCase)
        testCase.area = 'Photoshop API'
        testCase.feature = 'psSmartObjectV2'
        return testCase
    }))
    return newTestCases
}
