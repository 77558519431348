import {
    getTempDirectory
} from '../../lib/skycity.js'

const Uuid = require('uuid/v4')

export function getInputObj (inputFile) {
    return {
        href: inputFile.href,
        storage: inputFile.storage
    }
}

export function getOutputTempDirectory () {
    return getTempDirectory()
}

export function getFileName (path) {
    return path.substring(path.lastIndexOf('/') + 1)
}

export function getExtentionFromType (type) {
    switch (type) {
    case 'image/jpeg':
    case 'image/jpg':
    case 'image/png':
    case 'image/tiff':
        return type.substring(type.lastIndexOf('/') + 1)
    case 'vnd.adobe.photoshop':
    case 'image/vnd.adobe.photoshop':
        return 'psd'
    default:
        return type.substring(type.lastIndexOf('/') + 1)
    }
}

export async function readFiles (dirname) {
    var fs = require('fs-extra')
    const files = await fs.readdir(dirname)
    // const params = []
    return files
}

export function getOutputHref (dir, output) {
    return `${dir}/${Uuid()}.${getExtentionFromType(output.type)}`
}
