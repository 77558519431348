import React, { useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import { error } from '@react/react-spectrum/Toast'
import Button from '@react/react-spectrum/Button'

import axios from 'axios'

import { apiCall, getStatus } from '../../lib/cisApi.js'
import { getSenseiHeaders, getStorage, getOutputDirectory } from '../../lib/skycity.js'

export default function Operation (files) {
    const {
        inputFiles,
        layerMappings,
        errorOptions,
        dispatch
    } = useContext(SkycityContext)

    function start (curFile) {
        /* *********************************** */
        const codePayload = {
            srcPath: curFile.path,
            storage: getStorage(curFile),
            focusArea: {},
            outputDirectory: getOutputDirectory(curFile),
            mappings: layerMappings
        }
        const config = {
            url: `${process.env.REACT_APP_CONFIG_ENDPOINT_REFLOW_ML}/banner/generate`,
            method: 'POST',
            timeout: 120000,
            headers: getSenseiHeaders(),
            payload: codePayload,
            body: JSON.stringify(codePayload)
        }
        /* *********************************** */
        dispatch({ type: 'set-codes', payload: { config: config } })

        apiCall(config, (id, json) => {
            if (id) {
                getStatus(id, config, (res, error, errResponse) => {
                    if (error) {
                        errorOccured(`${error} - ${JSON.stringify(curFile)}`, errResponse || res)
                    } else if (res && res.jobStatus === 'error') {
                        const errMsg = `${id} - ${JSON.stringify(res, null, 2)} ERROR with ${config.url}`
                        errorOccured(`${JSON.stringify(curFile)} - ${errMsg}`, errResponse || res)
                    } else if (res && res.jobStatus === 'completed') {
                        res.outputs.forEach(rendition => {
                            files.push({
                                inputFile: curFile,
                                name: rendition.name,
                                shortName: rendition.name,
                                path: rendition.url,
                                outputThumbnail: `${rendition.url.replace(process.env.REACT_APP_CONFIG_ENDPOINT_CORS, process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE)}/:rendition;size=300?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`,
                                output: `${rendition.url.replace(process.env.REACT_APP_CONFIG_ENDPOINT_CORS, process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE)}/:rendition;size=0?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`,
                                outputToDownload: `${rendition.url.replace(process.env.REACT_APP_CONFIG_ENDPOINT_CORS, process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE)}/?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`
                            })
                        })
                        dispatch({
                            type: 'ssgeneration-success',
                            payload: {
                                files: files,
                                loading: false,
                                wait: false,
                                response: res
                            }
                        })
                    }
                })
            } else {
                const errMsg = json ? `${JSON.stringify(json, null, 2)}` : `ERROR with ${config.url}`
                errorOccured(`${JSON.stringify(curFile)} - ${errMsg}`, json)
            }
        })
    }

    function run (e) {
        try {
            dispatch({ type: 'skycity-run', payload: {} })

            files = []
            const funcArray = []
            inputFiles.forEach(curFile => {
                funcArray.push(start(curFile))
            })
            axios.all(funcArray)
                .then((res) => {
                    // then
                })
                .catch((err) => {
                    const errMsg = `ERROR: ${JSON.stringify(err)}`
                    errorOccured(errMsg)
                })
                .finally(() => {
                    console.log('finally...')
                })
        } catch (e) {
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        }
    }

    function isRunDisabled () {
        if (inputFiles && inputFiles.length) {
            return false
        }
        return true
    }

    function errorOccured (errMsg, res) {
        console.log(errMsg)
        error(errMsg.slice(0, errorOptions.wordSlice), {
            onClose: () => console.log('close'),
            timeout: errorOptions.msgTimeout
        })
        dispatch({ type: 'error-occured', payload: { errMsg: errMsg, response: res, loading: false, wait: false } })
    }

    try {
        return <div>
            <div className="adobe-skycity-button-right">
                <Button variant="cta" onClick={e => run(e)} disabled={isRunDisabled()}>{__('btn_run')}</Button>
            </div>
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
