import React, { useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import { error } from '@react/react-spectrum/Toast'
import Button from '@react/react-spectrum/Button'
import DocumentOutline from '@react/react-spectrum/Icon/DocumentOutline'
import FieldLabel from '@react/react-spectrum/FieldLabel'
import Textfield from '@react/react-spectrum/Textfield'

import LocaleOptions from '../Shared/LocaleOptions'
import TextLayerTable from '../Shared/TextLayerTable'
import OutputOptions from '../Shared/OutputOptions'

import { apiCall, getStatus } from '../../lib/cisApi.js'
import { getFilename, getFilenameWithoutExt, getHeaders, getStorage, getOptionsOutputLocale, getInputFileFont, getEncodedURI, goToUrlTarget } from '../../lib/skycity.js'

import axios from 'axios'

export default function Operation (files) {
    const {
        outputOptions,
        errorOptions,
        inputFiles,
        inputSecondFiles,
        txtLayersLocales,
        txtLayers,
        locales,
        locFrom,
        fontName,
        dispatch
    } = useContext(SkycityContext)

    let localTxtLayers = []

    function getTextLayerOptions (loc) {
        const layers = []
        txtLayers.forEach(layer => {
            layers.push({
                edit: {},
                id: layer.id,
                text: {
                    content: layer.locales.find(locale => locale.locale === loc).content,
                    characterStyles: [
                        {
                            fontName: fontName || getDefaultFontName()
                        }
                    ]
                }
            })
        })
        return layers
    }

    function start (curFile, loc) {
        /* *********************************** */
        const codePayload = {
            inputs: [
                {
                    href: curFile.path,
                    storage: getStorage(curFile)
                }
            ],
            options: {
                layers: getTextLayerOptions(loc)
            },
            outputs: getOptionsOutputLocale(curFile, outputOptions, loc)
        }

        if (inputSecondFiles) {
            codePayload.options.fonts = getInputFileFont(inputSecondFiles)
        }

        const config = {
            url: `${process.env.REACT_APP_CONFIG_ENDPOINT_PIE}/psdService/renditionCreate`,
            // url: `http://localhost:3000/psdService/renditionCreate`,
            method: 'POST',
            timeout: 180000,
            headers: getHeaders(),
            payload: codePayload,
            body: JSON.stringify(codePayload)
        }
        /* *********************************** */

        dispatch({ type: 'set-codes', payload: { config: config } })

        apiCall(config, (id, json) => {
            if (id) {
                getStatus(id, config, (res, error, errResponse) => {
                    if (error) {
                        errorOccured(`${error} - ${JSON.stringify(curFile)}`, errResponse || res)
                    } else if (res && res.outputs && res.outputs[0] && res.outputs[0].status === 'failed') {
                        const errMsg = res.outputs[0].errors ? `${id} - ${JSON.stringify(res.outputs[0].errors, null, 2)}` : `ERROR with ${config.url}`
                        errorOccured(`${JSON.stringify(curFile)} - ${errMsg}`, errResponse || res)
                    } else if (res && res.outputs && res.outputs[0] && res.outputs[0].status === 'succeeded') {
                        res.outputs[0]._links.renditions.forEach(rendition => {
                            files.push({
                                inputFile: curFile,
                                name: getFilename(rendition.href),
                                shortName: getFilenameWithoutExt(rendition.href),
                                path: rendition.href,
                                outputThumbnail: `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(rendition.href)}/:rendition;size=300?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`,
                                output: `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(rendition.href)}/:rendition;size=0?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`,
                                outputToDownload: `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(rendition.href)}/?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`
                            })
                        })
                        dispatch({
                            type: 'skycity-success',
                            payload: {
                                files: files,
                                selectedInputFile: files[0],
                                showLayerInfoPane: true,
                                loading: false,
                                wait: false,
                                response: res
                            }
                        })
                    }
                })
            } else {
                const errMsg = json ? `${JSON.stringify(json, null, 2)}` : `ERROR with ${config.url}`
                errorOccured(`${JSON.stringify(curFile)} - ${errMsg}`, json)
            }
        })
    }

    function run (e) {
        try {
            dispatch({ type: 'skycity-loc-run', payload: {} })

            files = []
            const funcArray = []
            inputFiles.forEach(curFile => {
                locales.forEach(loc => {
                    if (loc.selected) {
                        funcArray.push(start(curFile, loc.locale))
                    }
                })
            })
            axios.all(funcArray)
                .then((res) => {
                    // then
                })
                .catch((err) => {
                    const errMsg = `ERROR: ${JSON.stringify(err)}`
                    errorOccured(errMsg)
                })
                .finally(() => {
                    console.log('finally...')
                })
        } catch (e) {
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        }
    }

    function getTextLayers (layers) {
        layers.forEach(layer => {
            if (layer.type === 'textLayer') {
                localTxtLayers.push({
                    id: layer.id,
                    name: layer.name,
                    type: layer.type,
                    content: layer.text.content,
                    locales: JSON.parse(JSON.stringify(txtLayersLocales))
                })
            }
            if (layer.children) {
                getTextLayers(layer.children)
            }
        })
    }

    function translateStart (curFile) {
        localTxtLayers = []
        getTextLayers(curFile.documentManifest.outputs[0].layers)
        dispatch({
            type: 'set-text-layer-table',
            payload: {
                txtLayers: localTxtLayers,
                curFile: curFile
            }
        })

        localTxtLayers.forEach(txtLayer => {
            locales.forEach(locale => {
                if (locale.selected === true) {
                    gLocApi(txtLayer.content, txtLayer, locale.locale)
                }
            })
        })
    }

    function gLocApi (content, txtLayer, locale) {
        // return `loc str ${locale}`;
        return axios.get('https://script.google.com/macros/s/AKfycbyZJbT7VMkIR4XKEhOiGDYrf9KA-sJepyLca623TRyfklSwLYUx/exec', {
            params: {
                text: content,
                source: locFrom,
                target: locale
            }
        })
            .then((res) => {
                const newTxtLayers = localTxtLayers.map(curLayer => {
                    if (curLayer.id === txtLayer.id) {
                        curLayer.locales.find(curLocale => curLocale.locale === locale).content = res.data.text
                    }
                    return curLayer
                })
                dispatch({
                    type: 'set-text-layer-table',
                    payload: {
                        txtLayers: newTxtLayers
                    }
                })
            })
            .catch((err) => {
                const errMsg = `ERROR: ${JSON.stringify(err)}`
                errorOccured(errMsg)
                return ''
            })
            .finally(() => {
            // console.log(`finally...`);
            })
    }

    function translate (e) {
        try {
            dispatch({ type: 'skycity-loc-run', payload: {} })

            files = []
            const funcArray = []
            inputFiles.forEach(curFile => {
                funcArray.push(translateStart(curFile))
            })
            axios.all(funcArray)
                .then((res) => {
                    // then
                })
                .catch((err) => {
                    const errMsg = `ERROR: ${JSON.stringify(err)}`
                    errorOccured(errMsg)
                })
                .finally(() => {
                    console.log('finally...')
                })
        } catch (e) {
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        }
    }

    function isRunDisabled () {
        if (inputFiles && inputFiles.length > 0) {
            return false
        }
        return true
    }

    function errorOccured (errMsg, res) {
        console.log(errMsg)
        error(errMsg.slice(0, errorOptions.wordSlice), {
            onClose: () => console.log('close'),
            timeout: errorOptions.msgTimeout
        })
        dispatch({ type: 'error-occured', payload: { errMsg: errMsg, response: res, loading: false, wait: false } })
    }

    function getDefaultFontName () {
        if (inputSecondFiles && inputSecondFiles[0] && inputSecondFiles[0].path) {
            return getFilenameWithoutExt(inputSecondFiles[0].path)
        } else {
            return ''
        }
    }
    function setFontName (e) {
        dispatch({ type: 'set-fontname', payload: { fontName: e.currentTarget.value } })
    }

    try {
        return <div>
            <div>
                <FieldLabel label="fontName: ">
                    <Textfield defaultValue={ getDefaultFontName() } onBlur={ e => setFontName(e)} />
                    <Button
                        onClick={() => { goToUrlTarget(__('api_doc_link_prod_supported_fonts'), '_blank') }}
                        quiet variant="secondary" icon={<DocumentOutline />}>Supported Fonts</Button>
                </FieldLabel>
            </div>
            <LocaleOptions />
            <div className="adobe-skycity-button-right">
                <Button variant="cta" onClick={e => translate(e)} disabled={isRunDisabled()}>{__('btn_translate')}</Button>
            </div>
            <TextLayerTable />
            <OutputOptions imageTypes={['image/jpeg', 'image/png', 'image/vnd.adobe.photoshop', 'image/tiff']}/>
            <div className="adobe-skycity-button-right">
                <Button variant="cta" onClick={e => run(e)} disabled={isRunDisabled()}>{__('btn_run')}</Button>
            </div>
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
