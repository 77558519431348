import React, { useContext } from 'react'
import { SkycityContext } from '../../lib/SkycityProvider'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'

import DropZone from '@react/react-spectrum/DropZone'
import { Image as ImageSpectrum } from '@react/react-spectrum/Image'
import Button from '@react/react-spectrum/Button'
import Delete from '@react/react-spectrum/Icon/Delete'
import IllustratedMessage from '@react/react-spectrum/IllustratedMessage'
import UploadToCloud from '@react/react-spectrum/Icon/UploadToCloud'
import CreativeCloud from '@react/react-spectrum/Icon/CreativeCloud'
import { error } from '@react/react-spectrum/Toast'

import CcBrowser from '../Shared/CcBrowser'
import {
    generatePresignedUrlsFromUtils
} from '../../lib/utilsServiceApi'
import axios from 'axios'
import {
    getCcFiles,
    getFilenameWithoutExt,
    getHeaders,
    getTempDirectory,
    getEncodedURI,
    getRenditionCreateWithUtilsStorage,
    isSupportedFileForThumnail
} from '../../lib/skycity.js'

const uuid = require('uuid/v4')

export default function SelectInput ({
    actionType,
    title,
    storage,
    imageTypes,
    inputType,
    files,
    file,
    pFiles,
    setPosClicks,
    setNegClicks
}) {
    const {
        dispatch,
        showCcBrowserPane,
        showCcBrowserPaneSecond
    } = useContext(SkycityContext)

    if (!files) {
        files = []
    }
    if (!pFiles) {
        pFiles = []
    }

    function getImageTypes (imageTypes) {
        return imageTypes
    }

    function upload (e) {
        document.getElementById(`fileUpload-${actionType}`).click()
    }

    function browseCc () {
        getCcFiles(null, (ccFiles, res) => {
            if (ccFiles && ccFiles.total_children > 0) {
                dispatch({ type: 'set-ccfiles', payload: { ccFiles: ccFiles } })
                dispatch({ type: 'toggle-cc-browser-pane', payload: { actionType: actionType, inputType: inputType, visible: true } })
            } else {
                const msg = `ccFiles not found - ${JSON.stringify(res)}`
                console.log(msg)
                alert(msg)
            }
        })
    }

    function deleteAll (e) {
        dispatch({ type: actionType, payload: { file: [] } })
        setPosClicks([])
        setNegClicks([])
    }

    // Gets the dimensions of an image file. These dimensions may not
    // match the SpectrumImage element if the image is large. The
    // fullsize image is what is uploaded to the cloud and used for
    // inference, as a result, the positive and negative click
    // coordinates need to match the original image. Capture the
    // original width and height so that click events coordinates can
    // be correctly translated.
    async function getImageDimensions (file) {
        return new Promise((resolve, reject) => {
            const img = new Image()
            var reader = new FileReader()

            reader.onload = function (e) {
                img.src = e.target.result
            }

            img.addEventListener('load', (e) => {
                resolve({
                    width: img.naturalWidth,
                    height: img.naturalHeight
                })
            })

            reader.readAsDataURL(file)
        })
    }

    function getFileMetadata (file) {
        const curFile = {
            name: file.name,
            storage: 'external',
            path: file.path,
            type: file.name.split('.').pop(),
            size: file.size,
            shortName: getFilenameWithoutExt(file.name),
            largeRenditionGet : file.largeRenditionGet,
            smallRenditionGet : file.smallRenditionGet
        }
        // const curFile = {
        //     name: ccFile.data.name,
        //     storage: 'adobe',
        //     shortName: getFilenameWithoutExt(ccFile.data.name),
        //     type: ccFile.data.name.split('.').pop(),
        //     size: ccFile.data.size,
        //     path: ccFile.data.path,
        //     url: ccFile.config.url,
        //     width: ccFile.data.width,
        //     height: ccFile.data.height
        // }

        return curFile
    }

    async function clickUpload (e) {
        e.preventDefault()

        const file = e.target.files[0]
        try {
            const ccFile = await fileUploadToUtilsService(file)
            const newFile = getFileMetadata(ccFile)
            files = files.concat([newFile])
            dispatch({ type: actionType, payload: { wait: false, files: files } })
        } catch (err) {
            console.log(`ERROR: ${JSON.stringify(err)}`)
            error(`ERROR: ${JSON.stringify(err)}`, {
                timeout: 0
            })
        }
    }

    async function fileUploadToUtilsService(fileToUpload) {
        return new Promise(async (resolve, reject) => {
            const config = {
                name: fileToUpload.name,
                path: '',
                type: fileToUpload.type,
                size: fileToUpload.size
            }

            const response = await generatePresignedUrlsFromUtils(1);
            dispatch({ type: actionType, payload: { wait: true, files: files, file: { progress: 0, name: fileToUpload.name } } })
            const configS3PUT = {
                headers: {
                    'Content-Type': 'application/octet-stream',
                    'Access-Control-Allow-Origin': '*'
                },
                onUploadProgress: (e) => {
                    const currentProgress = (parseInt(e.loaded) / parseInt(e.total) * 100).toFixed(0)
                    const curFile = {}
                    curFile.name = fileToUpload.name
                    curFile.progress = currentProgress
                    dispatch({ type: actionType, payload: { wait: true, files: files, file: { progress: currentProgress, name: fileToUpload.name } } })
                },
                timeout: 300000
            }
            await axios.put(response.data.outputs.urls[0].put, fileToUpload, configS3PUT).then(async (res, err) => {
                if (!err) {
                    config.path = response.data.outputs.urls[0].get;
                    config.storage = 'external';
                    config.type = config.name.split('.').pop();
                    if(isSupportedFileForThumnail(config.type.toLowerCase()))
                    {
                        await getRenditionCreateWithUtilsStorage(config, 300, 0, (err, renditionSmall, renditionLarge) => {
                            if (err) {
                                console.log(`ERROR: ${JSON.stringify(err)}`)
                            } else {
                                config.smallRenditionGet = renditionSmall;
                                config.largeRenditionGet = renditionLarge;
                                resolve(config);
                            }
                        });
                    }
                    else
                    {
                        resolve(config);
                    }
                }
            });
        });
    }
    async function fileUpload (fileToUpload) {
        const uuidNum = uuid()
        dispatch({ type: actionType, payload: { wait: true, files: files, file: { progress: 0, name: fileToUpload.name } } })
        const url = `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getTempDirectory()}/${uuidNum}/${fileToUpload.name}`
        const formData = new FormData()
        formData.append('file', fileToUpload)
        const config = {
            headers: getHeaders(),
            onUploadProgress: (e) => {
                const currentProgress = (parseInt(e.loaded) / parseInt(e.total) * 100).toFixed(0)
                const curFile = {}
                curFile.name = fileToUpload.name
                curFile.progress = currentProgress
                dispatch({ type: actionType, payload: { wait: true, files: files, file: { progress: currentProgress, name: fileToUpload.name } } })
            },
            timeout: 300000
        }

        const promises = [getImageDimensions(fileToUpload),
            axios.put(url, formData, config)]
        const [dimensions, axiosResult] = await Promise.all(promises)
        axiosResult.data = { ...axiosResult.data, ...dimensions }
        return axiosResult
    }

    function onDrop (e) {
        e.preventDefault()
    }

    function onDragOver (e) {
        e.preventDefault()
    }

    function getThumbnail (file, type) {
        return <div
            className="adobe-skycity-interactive-select-container"
            key={file.path}
        >
            <ImageSpectrum
                type="image"
                cache={true}
                onClick={ addClick }
                src={file.smallRenditionGet} />
        </div>
    }

    async function addClick (e) {
        const boundingRect = e.target.getBoundingClientRect()
        const translateX = files[0].width / boundingRect.width
        const translateY = files[0].height / boundingRect.height

        const x = Math.round((e.clientX - boundingRect.left) * translateX)
        const y = Math.round((e.clientY - boundingRect.top) * translateY)
        const coordPair = {
            x: x,
            y: y,
            id: uuid()
        }
        if (e.shiftKey) {
            setNegClicks(clicks => clicks.concat([{ ...coordPair }]))
        } else {
            setPosClicks(clicks => clicks.concat([{ ...coordPair }]))
        }
    }

    function fileSelector () {
        return <div>
            <DropZone
                onDrop={ e => onDrop(e) }
                onDragOver={ e => onDragOver(e) }>
                <IllustratedMessage
                    heading={title} /><br /><br />
                <div className="adobe-skycity-dropzone">
                    <IllustratedMessage
                        heading=""
                        description={__('select_input_files_title_upload')}
                        illustration={<UploadToCloud size="L"/>}
                        onClick={ e => upload(e) } />
                    { storage && storage.find(st => st === 'cc') &&
                                 <IllustratedMessage
                                     heading=""
                                     description={__('select_input_files_title_cc')}
                                     illustration={<CreativeCloud size="L"/>}
                                     onClick={ e => browseCc(e) } />
                    }
                </div>
                <input type="file"
                    name="file"
                    id={`fileUpload-${actionType}`}
                    className="adobe-skycity-hide"
                    accept={ getImageTypes(imageTypes) }
                    onChange={ async e => clickUpload(e) } />
            </DropZone>
            { storage && storage.find(st => st === 'cc') &&
                         <CcBrowser
                             title={title}
                             showCcBrowserPane={showCcBrowserPane}
                             showCcBrowserPaneSecond={showCcBrowserPaneSecond}
                             inputType={inputType}
                             imageTypes={imageTypes.split(',')}
                             actionType={actionType} />
            }
        </div>
    }

    function loaded () {
        return <div className="spectrum-Dropzone">
            <IllustratedMessage
                heading={title} /><br /><br />
            <div className="adobe-skycity-flex-row-space-between">
                { getThumbnail(files[0], 'adobe-skycity-full-opacity') }
                {
                    <div className="adobe-skycity-icon-upload-more">
                        <Button variant="tool" icon={<Delete size="S"/>} onClick={ e => deleteAll(e) } />
                    </div>
                }
            </div>
        </div>
    }

    function SelectInputFile () {
        try {
            if (files && files.length !== 0) {
                return loaded()
            } else {
                return fileSelector()
            }
        } catch (e) {
            console.log(e)
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
        }
    }

    return <SelectInputFile />
}
