import React, { createContext, useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import { error } from '@react/react-spectrum/Toast'
import { TreeView } from '@react/react-spectrum/TreeView'
import Wait from '@react/react-spectrum/Wait'
import Tooltip from '@react/react-spectrum/Tooltip'
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger'
import Document from '@react/react-spectrum/Icon/Document'
import Heading from '@react/react-spectrum/Heading'
import Layers from '@react/react-spectrum/Icon/Layers'
import FieldLabel from '@react/react-spectrum/FieldLabel'
import Textfield from '@react/react-spectrum/Textfield'

import LayerType from '../Shared/LayerType'
import LayerVisibility from '../Shared/LayerVisibility'
import LayerLock from '../Shared/LayerLock'

export default function DocManifestInfo () {
    const {
        wait,
        dispatch,
        inputFiles,
        selectedInputFile,
        showLayerInfoPane,
        layerDs
    } = useContext(SkycityContext)

    const DocManifestInfoContext = createContext()
    const DocManifestInfoProvider = ({ children }) => {
        return (
            <DocManifestInfoContext.Provider>
                {children}
            </DocManifestInfoContext.Provider>
        )
    }

    function renderLayerInfo (layer) {
        if (layer) {
            return <OverlayTrigger placement="right">
                <div className="sc-main-tag-layer-component-contents">
                    <div className="sc-main-tag-layer-component-content">
                        <LayerVisibility layer={layer} />
                        <LayerType layer={layer} />
                        <div>{layer.name}</div>
                    </div>
                    <LayerLock layer={layer} />
                </div>
                <Tooltip variant="info">{__('layerinfo_tooltip_id')} {layer.id}</Tooltip>
            </OverlayTrigger>
        } else {
            return <div>{__('layer_error_layer_not_found')}</div>
        }
    }

    function onLayerSelectionChange (layers) {
        dispatch({ type: 'set-selected-layers', payload: { layers: layers } })
    }

    function toggleView () {
        dispatch({ type: 'toggle-layer-info-pane', payload: { showLayerInfoPane: !showLayerInfoPane } })
    }

    function getDocInfo () {
        const docProps = Object.entries(selectedInputFile.document).map(([key, value]) =>
            <FieldLabel key={key} label={`${key}:`} labelFor={key} position="left" style={{ width: '100px', margin: '3px' }}>
                <Textfield id={key} value={value} readOnly />
            </FieldLabel>
        )
        return <div>{docProps}</div>
    }

    try {
        if (wait) {
            return <center><Wait size="S" /></center>
        } else if (selectedInputFile && selectedInputFile.layers) {
            return <DocManifestInfoProvider>
                <React.Fragment>
                    <div className="adobe-skycity-icon-button" onClick={ e => toggleView(e) }>
                        <Document size="S" />
                        <Heading size={5} id="treeview-heading-id" className={showLayerInfoPane ? 'adobe-skycity-layer-info-wrapper-show' : 'adobe-skycity-layer-info-wrapper-hide'}>{__('psd_info_title')}</Heading>
                    </div>
                    <div className={showLayerInfoPane ? 'adobe-skycity-layer-info-wrapper-show' : 'adobe-skycity-layer-info-wrapper-hide'}>
                        {inputFiles && inputFiles.length !== 0 && <div className={showLayerInfoPane ? 'adobe-skycity-layer-info-wrapper-show' : 'adobe-skycity-layer-info-wrapper-hide'}>
                            { getDocInfo() }
                            <br />
                            <div className="adobe-skycity-icon-button">
                                <Layers size="S" />
                                <Heading size={5} id="treeview-heading-id" className="adobe-skycity-layer-info-wrapper-show">{__('layer_info_title')}</Heading>
                            </div>
                            <TreeView
                                aria-labelledby="gridview-heading-id"
                                className="ps-cep-reflow-treeview"
                                dataSource={ layerDs }
                                renderItem={ renderLayerInfo }
                                onSelectionChange={ selectedItems => onLayerSelectionChange(selectedItems)}
                                allowsSelection
                                allowsMultipleSelection />
                        </div>}
                    </div>
                </React.Fragment>
            </DocManifestInfoProvider>
        } else {
            return <div>{__('layer_error_layerinfo_not_found')}</div>
        }
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
        return <div>{__('error_msg_title')}</div>
    }
}
