import React, { useContext } from 'react'
import { SkycityContext } from '../../lib/SkycityProvider'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { Asset } from '@react/react-spectrum/Asset'
import Dialog from '@react/react-spectrum/Dialog'
import { Card, CardBody, CardPreview } from '@react/react-spectrum/Card'
import { GridView, GridLayout } from '@react/react-spectrum/GridView'
import { TableView } from '@react/react-spectrum/TableView'
import { error, warning } from '@react/react-spectrum/Toast'
import FileTxt from '@react/react-spectrum/Icon/FileTxt'
import Image from '@react/react-spectrum/Icon/Image'
import FileViewOption from './FileViewOption'

import {
    getDocumentManifest,
    getFilenameWithoutExt,
    getFileSize
} from '../../lib/skycity.js'

export default function S3Browser ({
    imageTypes,
    title,
    showS3BrowserPane,
    showS3BrowserPaneSecond,
    showS3BrowserPaneFont,
    showS3BrowserPaneIcc,
    multiple,
    actionType,
    documentManifest
}) {
    const {
        s3Files,
        dsS3Files,
        dispatch,
        selectedInputFile,
        inputFiles,
        inputSecondFiles,
        inputFontFiles,
        inputIccFiles,
        fileViewOption,
        tableViewColumnsS3
    } = useContext(SkycityContext)

    let ranges

    function selectionChange (e, id, allowsSelection) {
        if (!allowsSelection) {
            return
        }
        if (!multiple && e && s3Files.find(s3File => s3File.checked === true)) {
            warning('You can only select one file', {
                timeout: 0
            })
            return
        }
        s3Files.forEach(file => {
            if (file.id === id) {
                file.checked = e
            }
            return file
        })
        const filtered = s3Files.filter(asset => {
            if (imageTypes.find(type => type === asset.content_type)) {
                asset.shortName = getFilenameWithoutExt(asset.thumbnail_url)
                return asset
            }
            return null
        })
        dispatch({ type: 'set-s3files', payload: { s3Files: filtered } })
    }

    function renderCell (column, asset) {
        try {
            if (column.key === 'thumbnail') {
                // let allowsSelection = false
                // if (imageTypes.find(type => type === asset.type)) {
                //     allowsSelection = true
                // }
                switch (asset.content_type) {
                case 'image/png':
                case 'image/jpeg':
                case 'image/tiff':
                case 'application/x-font-otf':
                case 'image/svg+xml':
                    return <Asset
                        type="image"
                        src={asset.thumbnail_url}
                        cache />
                case 'image/vnd.adobe.photoshop':
                    return <Image size="M" />
                case 'text/plain':
                case 'text/csv':
                case 'application/illustrator':
                case 'application/pdf':
                case 'application/octet-stream':
                case 'application/json':
                    return <FileTxt size="M" />
                default:
                    return <FileTxt size="M" />
                }
            } else if (column.key === 'size') {
                return <span>{getFileSize(asset[column.key])}</span>
            }
            return <span>{asset[column.key]}</span>
        } catch (e) {
            console.log(e)
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
        }
    }

    function renderItem (asset) {
        try {
            let allowsSelection = false
            if (imageTypes.find(type => type === asset.content_type)) {
                allowsSelection = true
            }
            // console.log(`asset.content_type: ${asset.content_type}`);
            switch (asset.content_type) {
            case 'image/png':
            case 'image/jpeg':
                return <div onClick={ e => selectionChange(!asset.checked, asset.id, allowsSelection)}>
                    <Card
                        size="S"
                        selected={asset.checked}
                        allowsSelection={allowsSelection}
                        onSelectionChange={ e => selectionChange(e, asset.id) }>
                        <CardPreview>
                            <Asset
                                type="image"
                                src={asset.thumbnail_url}
                                cache />
                        </CardPreview>
                        <CardBody title={asset.key.split('/').pop()} subtitle={asset.content_type.split('/').pop()} description={getFileSize(asset.size)} />
                    </Card>
                </div>
            case 'image/tiff':
            case 'application/x-font-otf':
            case 'image/svg+xml':
            case 'image/vnd.adobe.photoshop':
                return <div onClick={ e => selectionChange(!asset.checked, asset.id, allowsSelection)}>
                    <Card
                        size="S"
                        selected={asset.checked}
                        allowsSelection={allowsSelection}
                        onSelectionChange={ e => selectionChange(e, asset.id) }>
                        <CardPreview>
                            <Asset type="file" />
                        </CardPreview>
                        <CardBody title={asset.key.split('/').pop()} subtitle={asset.content_type.split('/').pop()} description={getFileSize(asset.size)} />
                    </Card>
                </div>
            case 'text/plain':
            case 'text/csv':
            case 'application/illustrator':
            case 'application/pdf':
            case 'application/octet-stream':
            case 'application/json':
                return <div onClick={ e => selectionChange(!asset.checked, asset.id, allowsSelection) }>
                    <Card
                        size="S"
                        selected={asset.checked}
                        allowsSelection={allowsSelection}
                        onSelectionChange={ e => selectionChange(e, asset.id, allowsSelection) }>
                        <CardPreview>
                            <Asset type="file" />
                        </CardPreview>
                        <CardBody title={asset.key.split('/').pop()} subtitle={asset.content_type.split('/').pop()} description={getFileSize(asset.size)} />
                    </Card>
                </div>
            default:
                return <div>
                    <Card variant="quiet"
                        allowsSelection={allowsSelection}>
                        <CardPreview>
                            <Asset type="file" />
                        </CardPreview>
                        <CardBody title={asset.key.split('/').pop()} subtitle={asset.content_type.split('/').pop()} description={getFileSize(asset.size)} />
                    </Card>
                </div>
            }
        } catch (e) {
            console.log(e)
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
        }
    }

    function getS3Assets () {
        try {
            if (fileViewOption === 'ListView') {
                return <div className="adobe-skycity-thumbnail-container">
                    <TableView
                        columns={tableViewColumnsS3}
                        dataSource={dsS3Files}
                        onSelectionChange={ e => changedSelection(e) }
                        renderCell={renderCell} />
                </div>
            }
            return <div className="adobe-skycity-thumbnail-container">
                <GridView
                    allowsMultipleSelection={multiple}
                    layout={GridLayout}
                    dataSource={dsS3Files}
                    renderItem={renderItem} />
            </div>
        } catch (e) {
            console.log(e)
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
        }
    }

    function changedSelection (data) {
        if (data && data.sectionIndexSets && data.sectionIndexSets.get(0) && data.sectionIndexSets.get(0).ranges) {
            ranges = data.sectionIndexSets.get(0).ranges
        } else {
            ranges = null
        }
    }

    function updatedSelectedFiles (ranges) {
        const ids = []
        if (ranges && ranges.length > 0) {
            ranges.forEach(range => {
                let i = range.start
                for (i; i <= range.end; i++) {
                    ids.push(s3Files[i].id)
                }
            })
            // console.log(ids.length);
        }
        const newS3Files = s3Files.map(file => {
            file.checked = false
            if (ids.find(id => (id === file.id))) {
                file.checked = true
            }
            return file
        })
        dispatch({ type: 'set-s3files', payload: { s3Files: newS3Files } })
    }

    function onConfirm (e) {
        if (ranges && ranges.length > 0) {
            updatedSelectedFiles(ranges)
        }
        if (s3Files && s3Files.length > 0) {
            const checkedFiles = s3Files.filter(s3File => s3File.checked === true)
            let newFiles = checkedFiles

            if (actionType === 'set-input' && inputFiles) {
                newFiles = [...inputFiles, ...checkedFiles]
            }
            if (actionType === 'set-input-font' && inputFontFiles) {
                newFiles = [...inputFontFiles, ...checkedFiles]
            }
            if (actionType === 'set-input-second' && inputSecondFiles) {
                newFiles = [...inputSecondFiles, ...checkedFiles]
            }
            if (actionType === 'set-input-icc' && inputIccFiles) {
                newFiles = [...inputIccFiles, ...checkedFiles]
            }

            dispatch({ type: actionType, payload: { wait: false, files: newFiles, file: { progress: 0, name: '' } } })
            dispatch({ type: 'toggle-s3-browser-pane', payload: { actionType: actionType, visible: false } })

            if (documentManifest) {
                getDocumentManifest(checkedFiles[0], files => {
                    if (files) {
                        dispatch({ type: actionType, payload: { wait: false, files: files, file: { progress: 0, name: '' } } })
                        if (!selectedInputFile) {
                            if (actionType === 'set-input') {
                                dispatch({ type: 'set-selected-input', payload: { file: files[0] } })
                                dispatch({ type: 'toggle-layer-info-pane', payload: { showLayerInfoPane: true } })
                            }
                        }
                    }
                })
            }
        }
    }

    function getClassName (actionType) {
        switch (actionType) {
        case 'set-input':
            if (showS3BrowserPane) {
                return 'adobe-skycity-s3-browser-wrapper-show'
            } else {
                return 'adobe-skycity-s3-browser-wrapper-hide'
            }
        case 'set-input-second':
            if (showS3BrowserPaneSecond) {
                return 'adobe-skycity-s3-browser-wrapper-show'
            } else {
                return 'adobe-skycity-s3-browser-wrapper-hide'
            }
        case 'set-input-font':
            if (showS3BrowserPaneFont) {
                return 'adobe-skycity-s3-browser-wrapper-show'
            } else {
                return 'adobe-skycity-s3-browser-wrapper-hide'
            }
        case 'set-input-icc':
            if (showS3BrowserPaneIcc) {
                return 'adobe-skycity-s3-browser-wrapper-show'
            } else {
                return 'adobe-skycity-s3-browser-wrapper-hide'
            }
        default:
            return 'adobe-skycity-s3-browser-wrapper-hide'
        }
    }

    if ((s3Files && s3Files.length > 0) && (showS3BrowserPane || showS3BrowserPaneSecond || showS3BrowserPaneFont || showS3BrowserPaneIcc)) {
        try {
            return <div id={`s3-browser-${actionType}`} className={getClassName(actionType)}>
                <Dialog
                    title={`AWS S3 - ${title}`}
                    mode="fullscreen"
                    confirmLabel="OK"
                    cancelLabel="Cancel"
                    onCancel={ e => dispatch({ type: 'toggle-s3-browser-pane', payload: { actionType: actionType, visible: false } })}
                    onConfirm={ e => onConfirm(e)}>
                    <div className="adobe-skycity-s3browser">
                        <div className="adobe-skycity-flex-row-space-between">
                            <div></div>
                            <FileViewOption />
                        </div>
                        { getS3Assets(s3Files) }
                    </div>
                </Dialog>
            </div>
        } catch (e) {
            console.log(e)
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
        }
    } else {
        return null
    }
}
