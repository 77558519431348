import React, { useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import Wait from '@react/react-spectrum/Wait'
import Button from '@react/react-spectrum/Button'
import { error } from '@react/react-spectrum/Toast'

import OperationCep from './OperationCep'

import SelectInput from '../Shared/SelectInput'
import FeatureNav from '../Shared/FeatureNav'
import Step from '../Shared/Step'

export default function Main () {
    const {
        inputFiles,
        inputFile,
        loading
    } = useContext(SkycityContext)

    function getReflowCepWorkflow () {
        if (inputFiles && inputFiles.length > 0) {
            return <React.Fragment>
                <Step num={2} title={__('skycity_comp_title_operation')} />
                <OperationCep ccFile={inputFiles[0]} url={process.env.REACT_APP_CONFIG_APP_REFLOW_CEP}/>
                <div className="adobe-skycity-button-center">
                    <Button
                        onClick={() => { window.location.reload() }}
                        variant="cta">{__('btn_api_restart')}</Button>
                </div>
            </React.Fragment>
        } else {
            return <React.Fragment>
                <Step num={1} title={__('skycity_comp_title_input')} />
                <SelectInput
                    storage={[]}
                    actionType="set-input"
                    title="Select a PSD file to reflow"
                    multiple={false}
                    files={inputFiles}
                    file={inputFile}
                    imageTypes="image/png,image/jpeg,image/vnd.adobe.photoshop" />
            </React.Fragment>
        }
    }

    try {
        return <div className="adobe-skycity-main-pane">
            { loading && <div className="adobe-skycity-modal-loading">
                <Wait size="L" />
                <div>{__('sspieonsensei_loading_message')}</div>
            </div>}
            <div className="adobe-skycity-main">
                <div className="adobe-skycity-main-column">
                    <div className="adobe-skycity-main-feature">
                        <FeatureNav />
                        {getReflowCepWorkflow()}
                    </div>
                </div>
            </div>
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
