const Desc = `smartObjectV2: replace nested smart object layer. ${new Error('To get file path').stack.split('\n')[1].match(/Object(.+)\(https/)[1] || ''}`

const Payload = {
    inputs: [
        {
            href: 'files/_skycity_smoketest/smartObject_filter.psd',
            storage: 'adobe'
        }
    ],
    options: {
        layers: [
            {
                name: 'so1',
                input: {
                    href: 'https://as2.ftcdn.net/jpg/02/16/53/79/500_F_216537946_ODYbV4mHvMYtcpS6zUQ1nilKBkHnaazp.jpg',
                    storage: 'external'
                }
            },
            {
                name: 'so3',
                smartObject: {
                    layers: [
                        {
                            name: 'so3-1',
                            smartObject: {
                                layers: [
                                    {
                                        name: 'so3-2',
                                        input: {
                                            href: 'https://as1.ftcdn.net/jpg/02/09/20/56/500_F_209205609_pTnyKKoslI8bHrF2LoatzPDXRI99ucuZ.jpg',
                                            storage: 'external'
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            },
            {
                name: 'so4',
                input: {
                    href: 'https://as2.ftcdn.net/jpg/00/80/88/51/500_F_80885101_vwW81el2bQcXNMnN9mLiNx1wbHjeChrx.jpg',
                    storage: 'external'
                }
            }
        ]
    },
    outputs: [
        {
            storage: 'adobe',
            type: 'image/png',
            overwrite: true,
            width: 300,
            compression: 'large'
        }
    ]
}

module.exports = { Payload, Desc }
