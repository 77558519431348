import React, { useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import { error } from '@react/react-spectrum/Toast'
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger'
import Button from '@react/react-spectrum/Button'
import Tooltip from '@react/react-spectrum/Tooltip'
import Download from '@react/react-spectrum/Icon/Download'

import NavLink from '../Shared/NavLink'
import FeatureStatus from '../Shared/FeatureStatus'

import useTestfile from '../../hooks/useTestfile'

export default function FeatureNav () {
    const { page } = useContext(SkycityContext)
    const label = __(`api_doc_title_${page}`)
    const url = __(`api_doc_link_prod_${page}`)
    const stageUrl = __(`api_doc_link_stage_${page}`)
    const { testFile } = useTestfile({ page: page })

    try {
        return <div className="adobe-skycity-feature-nav">
            <div className="adobe-skycity-flex-row">
                <NavLink label={label} url={url} stageUrl={stageUrl} />
                <FeatureStatus page={page} />
            </div>
            <OverlayTrigger placement="right">
                <a href={testFile.href}>
                    <Button variant="tool" icon={<Download size="S"/>} aria-label="Download" disabled={!testFile.href}/>
                </a>
                <Tooltip className="adobe-skycity-feature-nav-download-tooltip" variant="info">
                    {__('download_testfiles')}
                </Tooltip>
            </OverlayTrigger>
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
