import React, { useContext, useState } from 'react'
import { SkycityContext } from '../../lib/SkycityProvider'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { Asset } from '@react/react-spectrum/Asset'
import Button from '@react/react-spectrum/Button'
import Dialog from '@react/react-spectrum/Dialog'
import Textfield from '@react/react-spectrum/Textfield'
import { Card, CardBody, CardPreview } from '@react/react-spectrum/Card'
import { GridView, GridLayout } from '@react/react-spectrum/GridView'
import { error, warning } from '@react/react-spectrum/Toast'
import {
    getDocumentManifest,
    getFilenameWithoutExt,
    getStockFiles,
    getLocalStorageItem,
    setLocalStorageItem
} from '../../lib/skycity.js'

export default function StockBrowser ({
    imageTypes,
    title,
    showAsBrowserPane,
    showAsBrowserPaneSecond,
    showAsBrowserPaneFont,
    showAsBrowserPaneIcc,
    multiple,
    actionType,
    documentManifest
}) {
    const {
        asFiles,
        dsAsFiles,
        dispatch,
        selectedInputFile,
        inputFiles,
        inputSecondFiles,
        inputFontFiles,
        inputIccFiles
    } = useContext(SkycityContext)

    // const asSearchKeyword = getLocalStorageItem('asSearchKeyword') || __('browser_as_search_string');
    const [searchText, setSearchText] = useState(getLocalStorageItem('asSearchKeyword') || __('browser_as_search_string'))

    function selectionChange (e, id, allowsSelection) {
        if (!allowsSelection) {
            return
        }
        if (!multiple && e && asFiles.find(asFile => asFile.checked === true)) {
            warning('You can only select one file', {
                timeout: 0
            })
            return
        }
        asFiles.forEach(file => {
            if (file.id === id) {
                file.checked = e
            }
            return file
        })
        const filtered = asFiles.filter(asset => {
            if (imageTypes.find(type => type === asset.content_type)) {
                asset.shortName = getFilenameWithoutExt(asset.thumbnail_url)
                return asset
            }
            return null
        })
        dispatch({ type: 'set-asfiles', payload: { asFiles: filtered } })
    }

    function renderItem (asset) {
        try {
            let allowsSelection = false
            if (imageTypes.find(type => type === asset.content_type)) {
                allowsSelection = true
            }
            // console.log(`asset.content_type: ${asset.content_type}`);
            switch (asset.content_type) {
            case 'image/png':
            case 'image/jpeg':
            case 'image/tiff':
            case 'image/svg+xml':
            case 'image/vnd.adobe.photoshop':
            case 'application/illustrator':
            case 'application/pdf':
                // case "application/illustrator":
                return <div onClick={ e => selectionChange(!asset.checked, asset.id, allowsSelection)}>
                    <Card
                        size="S"
                        selected={asset.checked}
                        allowsSelection={allowsSelection}
                        onSelectionChange={ e => selectionChange(e, asset.id) }>
                        <CardPreview>
                            <Asset
                                type="image"
                                cache={true}
                                src={asset.thumbnail_url} />
                        </CardPreview>
                        <CardBody title={asset.content_type}/>
                    </Card>
                </div>
            default:
                return <div>
                    <Card variant="quiet"
                        allowsSelection={allowsSelection}>
                        <CardPreview>
                            <Asset type="file" />
                        </CardPreview>
                        <CardBody title={asset.content_type}/>
                    </Card>
                </div>
            }
        } catch (e) {
            console.log(e)
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
        }
    }

    function getAsAssets () {
        try {
            return <div className="adobe-skycity-thumbnail-container">
                <GridView
                    allowsMultipleSelection={multiple}
                    layout={GridLayout}
                    dataSource={dsAsFiles}
                    renderItem={renderItem} />
            </div>
        } catch (e) {
            console.log(e)
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
        }
    }

    function onConfirm (e) {
        if (asFiles && asFiles.length > 0) {
            const checkedFiles = asFiles.filter(asFile => asFile.checked === true)
            let newFiles = checkedFiles

            if (actionType === 'set-input' && inputFiles) {
                newFiles = [...inputFiles, ...checkedFiles]
            }
            if (actionType === 'set-input-second' && inputSecondFiles) {
                newFiles = [...inputSecondFiles, ...checkedFiles]
            }
            if (actionType === 'set-input-font' && inputFontFiles) {
                newFiles = [...inputFontFiles, ...checkedFiles]
            }
            if (actionType === 'set-input-icc' && inputIccFiles) {
                newFiles = [...inputIccFiles, ...checkedFiles]
            }

            dispatch({ type: actionType, payload: { wait: false, files: newFiles, file: { progress: 0, name: '' } } })
            dispatch({ type: 'toggle-as-browser-pane', payload: { actionType: actionType, visible: false } })

            if (documentManifest) {
                getDocumentManifest(checkedFiles[0], files => {
                    if (files) {
                        dispatch({ type: actionType, payload: { wait: false, files: files, file: { progress: 0, name: '' } } })
                        if (!selectedInputFile) {
                            if (actionType === 'set-input') {
                                dispatch({ type: 'set-selected-input', payload: { file: files[0] } })
                                dispatch({ type: 'toggle-layer-info-pane', payload: { showLayerInfoPane: true } })
                            }
                        }
                    }
                })
            }
        }
    }

    function getClassName (actionType) {
        switch (actionType) {
        case 'set-input':
            if (showAsBrowserPane) {
                return 'adobe-skycity-as-browser-wrapper-show'
            } else {
                return 'adobe-skycity-as-browser-wrapper-hide'
            }
        case 'set-input-second':
            if (showAsBrowserPaneSecond) {
                return 'adobe-skycity-as-browser-wrapper-show'
            } else {
                return 'adobe-skycity-as-browser-wrapper-hide'
            }
        case 'set-input-font':
            if (showAsBrowserPaneFont) {
                return 'adobe-skycity-as-browser-wrapper-show'
            } else {
                return 'adobe-skycity-as-browser-wrapper-hide'
            }
        case 'set-input-icc':
            if (showAsBrowserPaneIcc) {
                return 'adobe-skycity-as-browser-wrapper-show'
            } else {
                return 'adobe-skycity-as-browser-wrapper-hide'
            }
        default:
            return 'adobe-skycity-as-browser-wrapper-hide'
        }
    }

    function browseStock (e) {
        // const params = 'Rest/Media/1/Search/Files?locale=en_US%26search_parameters%5Bwords%5D=kittens';
        setLocalStorageItem('asSearchKeyword', searchText)
        const params = 'Rest/Media/1/Search/Files'
        const locale = 'en_US'
        getStockFiles(params, locale, searchText, (asFiles, res) => {
            if (asFiles) {
                const filtered = asFiles.files.filter(asset => {
                    if (imageTypes.find(type => type === asset.content_type)) {
                        return asset
                    }
                    return null
                })
                dispatch({ type: 'set-asfiles', payload: { asFiles: filtered } })
            } else {
                const msg = `asFiles not found - ${JSON.stringify(res)}`
                console.log(msg)
                alert(msg)
            }
        })
    }

    if ((asFiles && asFiles.length > 0) && (showAsBrowserPane || showAsBrowserPaneSecond || showAsBrowserPaneIcc || showAsBrowserPaneFont)) {
        try {
            return <div id={`as-browser-${actionType}`} className={getClassName(actionType)}>
                <Dialog
                    title={`Adobe Stock - ${title}`}
                    mode="fullscreen"
                    confirmLabel="OK"
                    cancelLabel="Cancel"
                    onCancel={ e => dispatch({ type: 'toggle-as-browser-pane', payload: { actionType: actionType, visible: false } })}
                    onConfirm={ e => onConfirm(e)}>
                    <div className="adobe-skycity-asbrowser">
                        <Textfield value={searchText} onChange={ e => setSearchText(e) }/>{' '}
                        <Button variant="cta" onClick={ e => browseStock(e) } label="Search"/>
                        { getAsAssets(asFiles) }
                    </div>
                </Dialog>
            </div>
        } catch (e) {
            console.log(e)
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
        }
    } else {
        return null
    }
}
