import React, { useContext, useState } from 'react'
import useFetch from '../../hooks/useFetch'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'

import SyntaxHighlighter from 'react-syntax-highlighter'
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs'

import Button from '@react/react-spectrum/Button'
import Pagination from '@react/react-spectrum/Pagination'
import { success, error } from '@react/react-spectrum/Toast'

import { SkycityContext } from '../../lib/SkycityProvider'

import { downloadFile, copyText, getCodeLineToken, getCodeLineApiKey } from '../../lib/skycity.js'

export default function CodeSnippet ({
    language,
    style = atomOneDark,
    lineNumberStyle = { color: 'rgba(112, 112, 112, 0.8)' },
    showLineNumbers = true
}) {
    const {
        codes,
        inputFiles
    } = useContext(SkycityContext)

    const [currentPage, setPage] = useState(1)
    let textFile = null
    const url = createCode(codes)
    const text = useFetch({ url, defaultValue: '', json: false })

    // text += `\n`;
    function createCode (codes) {
        try {
            if (codes && codes.length > 0) {
                let text = ' #!/bin/bash\n\n'
                text += '# ************************************\n'
                text += '# Check if jq is installed\n'
                text += '# ************************************\n'
                text += 'if [ ! `which jq` ]; then\n'
                text += '\techo "************************************"\n'
                text += '\techo "Please install jq: brew install jq"\n'
                text += '\techo "************************************"\n'
                text += '\texit 1\n'
                text += 'fi\n\n'
                text += '# ************************************\n'
                text += '# Set variables\n'
                text += '# ************************************\n'
                text += '# set -x\n'
                // text += `token='${window.adobeIMS.getAccessToken().token}'\n`
                // text += `apiKey='${process.env.REACT_APP_CONFIG_APP_KEY}'\n`
                text += `${getCodeLineToken()}`
                text += `${getCodeLineApiKey(process.env.REACT_APP_CONFIG_APP_KEY)}`
                text += `endpoint='${codes[currentPage - 1].codeEndpoint}'\n`
                text += `method='${codes[currentPage - 1].codeMethod}'\n\n`
                text += `payload='${JSON.stringify(codes[currentPage - 1].codePayload, null, 2)}'\n\n`
                text += '# ************************************\n'
                text += '# Call API\n'
                text += '# ************************************\n'
                text += 'res=$(curl -Ss -H "Authorization: Bearer $token" -H "Content-Type:application/json" -H "x-api-key: $apiKey" -X "$method" -d "$payload" "$endpoint")\n'
                text += 'myerror=$(echo $res | jq -r .code)\n'
                text += 'if [ $myerror != "null" ]; then\n'
                text += '\techo "ERROR: $res"\n'
                text += '\texit 1\n'
                text += 'fi\n'
                // text += `jobid=$(echo $res | jq -r ._links.self.href)\n`;
                text += 'jobid=$endpoint/$(echo $res | jq -r .job_id)\n'
                text += 'echo "JOBID: $jobid"\n\n'
                text += '# ************************************\n'
                text += '# Check Status\n'
                text += '# ************************************\n'
                text += 'while [ "x$jobstatus" != "xcompleted" ] && [ "x$jobstatus" != "xCOMPLETED" ] && [ "x$jobstatus" != "xerror" ]; do\n'
                text += '\toutput=$(curl -Ss -H "Authorization: Bearer $token" -H "Content-Type:application/json" -H "x-api-key: $apiKey" -X GET "$jobid")\n'
                text += '\tjobstatus=$(echo $output | jq -r .job_status)\n'
                text += '\techo "JOBSTATUS: $jobstatus"\n'
                text += 'done\n\n'
                text += '# ************************************\n'
                text += '# Result\n'
                text += '# ************************************\n'
                text += 'echo "************************************"\n'
                text += 'echo "RESULT"\n'
                text += 'echo ""\n'
                text += 'echo $output | jq\n'
                text += 'echo "************************************"\n'

                var data = new Blob([text], { type: 'text/plain' })
                if (textFile !== null) {
                    window.URL.revokeObjectURL(textFile)
                }
                textFile = window.URL.createObjectURL(data)
                return textFile
            } else {
                return ''
            }
        } catch (e) {
            console.log(e)
        }
    }

    function onPageInputChange (value) {
        // console.log(value);
        setPage(value)
    }

    function getText () {
        const newLine = copyText(text)
        success('The code is copied.', {
            actionLabel: 'Log in console',
            onAction: (e) => console.log(newLine),
            timeout: 5000
        })
    }

    try {
        console.log(`inputFiles: ${inputFiles}`)
        console.log(`codes: ${codes}`)
        if (inputFiles && inputFiles.length > 0 && codes && codes.length > 0) {
            return <React.Fragment>
                <center>
                    <div className="adobe-skycity-code-pagination">
                        <Pagination
                            variant="explicit"
                            mode="primary"
                            onChange={onPageInputChange}
                            onNext={onPageInputChange}
                            onPrevious={onPageInputChange}
                            totalPages={codes.length}
                            defaultPage={currentPage} />
                    </div>
                </center>
                <div className="adobe-skycity-code-preview">
                    <SyntaxHighlighter lineNumberStyle={lineNumberStyle} showLineNumbers={showLineNumbers} language={language} style={style}>
                        {text}
                    </SyntaxHighlighter>
                </div>
                <div className="adobe-skycity-button-right">
                    <Button variant="cta" onClick={e => getText(e)} disabled={text === ''}>{__('btn_api_copy')}</Button>
                    <Button variant="cta" onClick={e => downloadFile(textFile, __('batch_filename'))} disabled={text === ''}>{__('btn_api_download')}</Button>
                </div>
            </React.Fragment>
        } else {
            return null
        }
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
