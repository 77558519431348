import React from 'react'

import { FormItem } from '@react/react-spectrum/Form'
import Textfield from '@react/react-spectrum/Textfield'

export default function FormLayerType (props) {
    return <FormItem label={props.label}>
        <Textfield value={props.curLayer.type} disabled />
    </FormItem>
}
