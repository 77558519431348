import {
    getHeaders,
    getStorage,
    getOptionsOutput,
    getTempDirectory
} from '../../lib/skycity.js'

import { getParams } from '../params/psFileConversion.param.js'
import { getInputObj } from './testHelper.js'

export async function getConfig (curFile, outputOptions, iccProfileOption, storageTypes) {
    const payload = await getCodePayload(curFile, outputOptions, iccProfileOption)
    const config = {
        url: `${process.env.REACT_APP_CONFIG_ENDPOINT_PIE}/psdService/renditionCreate`,
        method: 'POST',
        timeout: 90000,
        headers: getHeaders(),
        payload: payload,
        body: JSON.stringify(payload)
    }
    return config
}

async function getCodePayload (curFile, outputOptions, iccProfileOption) {
    const codePayload = {
        inputs: [
            {
                href: curFile.path || curFile.href,
                storage: getStorage(curFile)
            }
        ],
        outputs: await getOptionsOutput(curFile, outputOptions, iccProfileOption)
    }
    return codePayload
}

// ************************************************************************
// Test Cases
// ************************************************************************
export async function getTestCases (storages) {
    const testCases = await getParams(storages)
    const newTestCases = await Promise.all(testCases.map(async (testCase) => {
        testCase = await prepareBodyForExternal(testCase)
        testCase.area = 'Photoshop API'
        testCase.feature = 'psFileConversion'
        return testCase
    }))
    return newTestCases
}

export async function prepareBodyForExternal (options) {
    options.body.inputs = [await getInputObj(options.body.inputs[0])]
    options.body.outputs = options.body.outputs.map(output => {
        if (output.storage === 'adobe') {
            output.href = `${getTempDirectory()}/${output.href}`
        }
        return output
    })
    return options
}
