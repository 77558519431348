import React, { createContext, useContext } from 'react'

import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import Properties from '@react/react-spectrum/Icon/Properties'
import Heading from '@react/react-spectrum/Heading'

import Well from '@react/react-spectrum/Well'
import { Form } from '@react/react-spectrum/Form'

import PreviewEdit from '../Shared/PreviewEdit'
import FormLayerId from '../Shared/FormLayerId'
import FormLayerType from '../Shared/FormLayerType'
import FormLayerVisibility from '../Shared/FormLayerVisibility'
import FormLayerLocked from '../Shared/FormLayerLocked'
import FormLayerBlendMode from '../Shared/FormLayerBlendMode'
import FormLayerOpacity from '../Shared/FormLayerOpacity'
import FormLayerBounds from '../Shared/FormLayerBounds'
import FormLayerInlineInput from '../Shared/FormLayerInlineInput'
import FormLayerTextAPI from '../Shared/FormLayerTextAPI'

export default function LayerProperty () {
    const {
        showLayerInfoPane,
        selectedLayers
    } = useContext(SkycityContext)

    const LayerPropertyContext = createContext()
    const LayerPropertyProvider = ({ children }) => {
        return (
            <LayerPropertyContext.Provider>
                {children}
            </LayerPropertyContext.Provider>
        )
    }

    // const LayerProperty = () => {
    function LayerProperty () {
        return (
            <LayerPropertyProvider>
                <div className="adobe-skycity-layer-properties">
                    <Well className="adobe-skycity-layer-properties">
                        <div className="adobe-skycity-icon-button">
                            <Properties size="S" />
                            <Heading size={5} id="treeview-heading-id" className={showLayerInfoPane ? 'adobe-skycity-layer-info-wrapper-show-properties' : 'adobe-skycity-layer-info-wrapper-hide'}>{__('layer_properties_title')}</Heading>
                            <PreviewEdit label="Preview"/>
                        </div>
                        <div className="adobe-skycity-layer-panel">
                            <Form>
                                <FormLayerId label="ID:" curLayer={selectedLayers[0]} />
                                <FormLayerType label="Type:" curLayer={selectedLayers[0]} />
                                <FormLayerInlineInput label="Name:" curLayerProp="name" curLayer={selectedLayers[0]} />
                                <FormLayerVisibility label="Visibility:" curLayer={selectedLayers[0]} />
                                <FormLayerLocked label="Locked:" curLayer={selectedLayers[0]} />
                                <FormLayerBlendMode label="Blend Mode:" curLayer={selectedLayers[0]} />
                                <FormLayerOpacity label="Opacity:" curLayer={selectedLayers[0]} />
                                <FormLayerBounds label="Bounds Top:" curLayerProp="top" curLayer={selectedLayers[0]} />
                                <FormLayerBounds label="Bounds Left:" curLayerProp="left" curLayer={selectedLayers[0]} />
                                <FormLayerBounds label="Bounds Width:" curLayerProp="width" curLayer={selectedLayers[0]} />
                                <FormLayerBounds label="Bounds Height:" curLayerProp="height" curLayer={selectedLayers[0]} />
                                { selectedLayers[0].text && <FormLayerTextAPI label="Text" curLayer={selectedLayers[0]} />}
                            </Form>
                        </div>
                    </Well>
                </div>
            </LayerPropertyProvider>
        )
    }

    return <LayerProperty />
}
