import React, { useContext, useState } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import Wait from '@react/react-spectrum/Wait'
import { error } from '@react/react-spectrum/Toast'

import Operation from './Operation'
import SelectInput from './SelectInput'
import SenseiParams from './SenseiParams'

import FeatureNav from '../Shared/FeatureNav'
import Step from '../Shared/Step'
import Output from '../Shared/Output'

export default function Main () {
    const {
        outputFiles,
        response,
        codes,
        loading,
        inputSecondFiles,
        inputFiles
    } = useContext(SkycityContext)

    const [posClicks, setPosClicks] = useState([])
    const [negClicks, setNegClicks] = useState([])

    function getInputImage () {
        return <SelectInput
            storage={[]}
            actionType="set-input"
            title="Select file"
            files={inputFiles}
            setPosClicks={setPosClicks}
            setNegClicks={setNegClicks}
            imageTypes="image/png,image/jpeg" />
    }

    function getMask () {
        return <SelectInput
            storage={[]}
            actionType="set-input-second"
            title="Select (optional) mask file"
            files={inputSecondFiles}
            setPosClicks={setPosClicks}
            setNegClicks={setNegClicks}
            imageTypes="image/png,image/jpeg" />
    }

    try {
        return <div className="adobe-skycity-main-pane">
            { loading && <div className="adobe-skycity-modal-loading">
                <Wait size="L" />
                <div>{__('ssinteractiveselection_loading_message')}</div>
            </div>}
            <div className="adobe-skycity-main">
                <div className="adobe-skycity-main-column">
                    <div className="adobe-skycity-main-feature">
                        <FeatureNav />
                        {/* **********************
                        Input
                        ********************** */}
                        <Step num={1} title={__('skycity_comp_title_input')} />

                        {getInputImage()}
                        {getMask()}

                        <Step num={2} title={__('skycity_comp_title_operation')} />
                        <SenseiParams
                            posClicks={posClicks}
                            setPosClicks={setPosClicks}
                            negClicks={negClicks}
                            setNegClicks={setNegClicks}
                        />
                        <Operation
                            posClicks={posClicks}
                            negClicks={negClicks}
                        />
                        {/* **********************
                        Output
                        ********************** */}
                        <Step num={3} title={__('skycity_comp_title_output')} />
                        <Output outputFiles={outputFiles} response={response} codes={codes} />
                    </div>
                </div>
            </div>
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
