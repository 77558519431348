import React, { useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import { error } from '@react/react-spectrum/Toast'
import Button from '@react/react-spectrum/Button'

import axios from 'axios'

import { ssApiSyncCall } from '../../lib/ccApi.js'
import {
    getFilename,
    getFilenameWithoutExt,
    getEncodedURI,
    getTempDirectory,
    getSenseiHeaders,
    fileUpload
} from '../../lib/skycity.js'

import {
    exploreIndexDocument,
    exploreHomeDirectory,
    uploadFile,
    retrieveRendition

} from '../../lib/acpApi.js'
const LinkHeader = require( 'http-link-header' )
const uuid = require('uuid/v4')

export default function Operation (files) {
    const {
        inputFiles,
        errorOptions,
        dispatch
    } = useContext(SkycityContext)

    async function start (curFile) {
        //  replace with presignedUrl
        const filePath = curFile.path
        const codePayload = {
            contentAnalyzerRequests: {
                'sensei:name': 'Select All',
                'sensei:invocation_mode': 'synchronous',
                'sensei:invocation_batch': false,
                'sensei:in_response': false,
                'sensei:engines': [
                    {
                        'sensei:execution_info': {
                            'sensei:engine': 'Annotation:select-all-chain-v2:e6c1397c-e844-4c40-844f-908590a641f7'
                        },
                        'sensei:inputs': {
                            image_in: {
                                'dc:format': checkFileType(curFile.type),
                                'sensei:repoType': 'HTTP',
                                'repo:path': filePath
                            }
                        },
                        'sensei:outputs': {
                            masks_out: {
                                'dc:format': 'image/png',
                                'sensei:multipart_field_name': 'masks_out'
                            },
                            subpart_masks_out: {
                                'dc:format': 'image/png',
                                'sensei:multipart_field_name': 'subpart_masks_out'
                            },
                            stuff_masks_out: {
                                'dc:format': 'image/png',
                                'sensei:multipart_field_name': 'stuff_masks_out'
                            },
                            metadata_out: {
                                'dc:format': 'application/json',
                                'sensei:multipart_field_name': 'metadata_out'
                            },
                            stuff_category: {
                                'dc:format': 'application/json',
                                'sensei:multipart_field_name': 'background_information'
                            }
                        }
                    }
                ]
            }
        }

        const formData = new FormData()
        formData.append('contentAnalyzerRequests', JSON.stringify(codePayload.contentAnalyzerRequests))
        const config = {
            file: curFile,
            url: `${process.env.REACT_APP_CONFIG_ENDPOINT_MLSENSEI}/predict`,
            method: 'POST',
            mode: 'cors',
            timeout: 90000,
            headers: getSenseiHeaders(),
            payload: codePayload,
            body: {
                formData: formData
            }
        }

        /* *********************************** */
        dispatch({ type: 'set-codes', payload: { config: config } })

        try {
            const responseBody = await ssApiSyncCall(config)
            const contentAnalyzerResponse = responseBody.get('contentAnalyzerResponse')
            const response = JSON.parse(responseBody.get('metadata_out'))
            response.sensei_metadata = JSON.parse(contentAnalyzerResponse)
            const masks = response.sensei_metadata.statuses[0].invocations[0]['sensei:outputs'].masks_out
            const subpart_masks = response.sensei_metadata.statuses[0].invocations[0]['sensei:outputs'].subpart_masks_out
            const stuff_masks = response.sensei_metadata.statuses[0].invocations[0]['sensei:outputs'].stuff_masks_out
            const metadata_out = response.sensei_metadata.statuses[0].invocations[0]['sensei:outputs'].metadata_out

            const foreground = JSON.parse(responseBody.get('metadata_out'))
            const background = JSON.parse(responseBody.get('background_information'))

            // 1. Explore the Index Document and get the Primary URL of the home Directory
            const homeDirectoryPrimaryUrl = await exploreIndexDocument();
        
            // 2. Explore the home Directory and get the Create URL of the Cloud Documents Directory
            const exploreHomeDirectoryResponse = await exploreHomeDirectory(
            homeDirectoryPrimaryUrl
            );
            const cloudDocumentsCreateUrl = exploreHomeDirectoryResponse.createLink;
            // upload refined masks
            for (let i = 0; i < masks.length; ++i) {
                const output = responseBody.get(masks[i]['sensei:multipart_field_name'])
                const blob = output.slice(0, output.size, 'image/png')
                const outputFileName = `selectall_refined_mask_${i}_${uuid()}_${curFile.shortName}.png`
                const newFile = new File([blob], outputFileName, { type: 'image/png' })
                const outputHref = `${getTempDirectory()}/${outputFileName}`
                const fileUploaded = await uploadFile(
                    cloudDocumentsCreateUrl,
                    newFile
                );
                const uploadlinks = LinkHeader.parse(fileUploaded.headers.get("link"));
                const primaryUrl = uploadlinks.refs.filter(val => val.rel.match("http://ns.adobe.com/adobecloud/rel/primary"))[0].uri;
                const renditionUrl = uploadlinks.refs.filter(val => val.rel.match("http://ns.adobe.com/adobecloud/rel/rendition"))[0].uri;
                const largeRendition = await retrieveRendition(
                      renditionUrl,
                      0,
                      'image/png'
                );
                files.push({
                    inputFile: curFile,
                    name: getFilename(outputHref),
                    shortName: getFilenameWithoutExt(outputHref),
                    path: outputHref,
                    outputThumbnail: largeRendition,
                    output: largeRendition,
                    outputToDownload: `${getEncodedURI(primaryUrl)}/?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`
                })
            }
            // upload subpart masks
            for (let i = 0; i < subpart_masks.length; ++i) {
                const output = responseBody.get(subpart_masks[i]['sensei:multipart_field_name'])
                const blob = output.slice(0, output.size, 'image/png')
                const outputFileName = `selectall_subpart_mask_${i}_${uuid()}_${curFile.shortName}.png`
                const newFile = new File([blob], outputFileName, { type: 'image/png' })
                const outputHref = `${getTempDirectory()}/${outputFileName}`
                const submaskFileUploaded = await uploadFile(
                    cloudDocumentsCreateUrl,
                    newFile
                );
                const submaskUploadlinks = LinkHeader.parse(submaskFileUploaded.headers.get("link"));
                const submaskPrimaryUrl = submaskUploadlinks.refs.filter(val => val.rel.match("http://ns.adobe.com/adobecloud/rel/primary"))[0].uri;
                const submaskRenditionUrl = submaskUploadlinks.refs.filter(val => val.rel.match("http://ns.adobe.com/adobecloud/rel/rendition"))[0].uri;
                const submaksLargeRendition = await retrieveRendition(
                    submaskRenditionUrl,
                      0,
                      'image/png'
                );
                files.push({
                    inputFile: curFile,
                    name: getFilename(outputHref),
                    shortName: getFilenameWithoutExt(outputHref),
                    path: outputHref,
                    outputThumbnail: submaksLargeRendition,
                    output: submaksLargeRendition,
                    outputToDownload: `${getEncodedURI(submaskPrimaryUrl)}/?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`
                })
            }
            // upload stuff masks
            for (let i = 0; i < stuff_masks.length; ++i) {
                const output = responseBody.get(stuff_masks[i]['sensei:multipart_field_name'])
                const blob = output.slice(0, output.size, 'image/png')
                const outputFileName = `selectall_stuff_mask_${i}_${uuid()}_${curFile.shortName}.png`
                const newFile = new File([blob], outputFileName, { type: 'image/png' })
                const outputHref = `${getTempDirectory()}/${outputFileName}`
                const stuffmaskFileUploaded = await uploadFile(
                    cloudDocumentsCreateUrl,
                    newFile
                );
                const stuffmaskUploadlinks = LinkHeader.parse(stuffmaskFileUploaded.headers.get("link"));
                const stuffmaskPrimaryUrl = stuffmaskUploadlinks.refs.filter(val => val.rel.match("http://ns.adobe.com/adobecloud/rel/primary"))[0].uri;
                const stuffmaskRenditionUrl = stuffmaskUploadlinks.refs.filter(val => val.rel.match("http://ns.adobe.com/adobecloud/rel/rendition"))[0].uri;
                const stuffmaksLargeRendition = await retrieveRendition(
                    stuffmaskRenditionUrl,
                      0,
                      'image/png'
                );
                files.push({
                    inputFile: curFile,
                    name: getFilename(outputHref),
                    shortName: getFilenameWithoutExt(outputHref),
                    path: outputHref,
                    outputThumbnail: stuffmaksLargeRendition,
                    output: stuffmaksLargeRendition,
                    outputToDownload: `${getEncodedURI(stuffmaskPrimaryUrl)}/?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`
                })
            }

            dispatch({
                type: 'skycity-success',
                payload: {
                    files: files,
                    response: foreground.concat(background),
                    loading: false,
                    wait: false
                }
            })
        } catch (e) {
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        }
    }

    function run (e) {
        try {
            dispatch({ type: 'skycity-run', payload: {} })

            files = []
            const funcArray = []
            inputFiles.forEach(curFile => {
                funcArray.push(start(curFile))
            })
            axios.all(funcArray)
                .then((res) => {
                    // then
                })
                .catch((err) => {
                    const errMsg = `ERROR: ${JSON.stringify(err)}`
                    errorOccured(errMsg)
                })
                .finally(() => {
                    console.log('finally...')
                })
        } catch (e) {
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        }
    }

    function isRunDisabled () {
        if (inputFiles && inputFiles.length > 0) {
            return false
        }
        return true
    }

    function errorOccured (errMsg, res) {
        console.log(errMsg)
        error(errMsg.slice(0, errorOptions.wordSlice), {
            onClose: () => console.log('close'),
            timeout: errorOptions.msgTimeout
        })
        dispatch({ type: 'error-occured', payload: { errMsg: errMsg, response: res, loading: false, wait: false } })
    }

    function checkFileType (type) {
        if (type === 'jpg') {
            type = 'jpeg'
        }
        return type.startsWith('image/') ? type : `image/${type}`
    }

    try {
        return <div>
            <div className="adobe-skycity-button-right">
                <Button variant="cta" onClick={e => run(e)} disabled={isRunDisabled()}>{__('btn_run')}</Button>
            </div>
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
