const Desc = `smartObjectV2: try to replace multiple smart object layers. ${new Error('To get file path').stack.split('\n')[1].match(/Object(.+)\(https/)[1] || ''}`

const Payload = {
    inputs: [
        {
            href: 'files/_skycity_smoketest/t.psd',
            storage: 'adobe'
        }
    ],
    options: {
        layers: [
            {
                name: 'L1',
                input: {
                    href: 'files/_skycity_smoketest/adobelogo.png',
                    storage: 'adobe'
                }
            },
            {
                name: 'L2',
                input: {
                    href: 'files/_skycity_smoketest/adobelogo.png',
                    storage: 'adobe'
                }
            }
        ]
    },
    outputs: [
        {
            storage: 'adobe',
            type: 'image/png',
            overwrite: true,
            width: 300,
            compression: 'large'
        }
    ]
}

module.exports = { Payload, Desc }
