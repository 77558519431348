import { setParams } from './ssImagecutout/index.js'
const pHelper = require('./param_helper.js')

export function getParams (storages) {
    const testCases = []
    storages.forEach(storage => {
        const tc = getTestCases(storage.value)
        testCases.push(...tc)
    })
    return testCases
}

function getTestCases (storage) {
    const sharedParams = setParams(storage)
    const options = sharedParams.map(param => {
        param.payload.output.href = pHelper.getOutputHref('_ssImagecutout', param.payload.output)
        return {
            body: param.payload,
            desc: param.desc
        }
    })
    return options
}
