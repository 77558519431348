import React from 'react'
import Settings from '@react/react-spectrum/Icon/Settings'
import Button from '@react/react-spectrum/Button'
import ModalTrigger from '@react/react-spectrum/ModalTrigger'
import Dialog from '@react/react-spectrum/Dialog'
import Checkbox from '@react/react-spectrum/Checkbox'
import Textfield from '@react/react-spectrum/Textfield'
import { resetLocalStorageItem, getLocalStorageItem, setLocalStorageItem } from '../../lib/skycity'

export default function Preferences ({ className }) {
    function resetPreferences (e) {
        resetLocalStorageItem('comAdobediceSkycity')
        window.location.reload()
    }

    function save (e) {
        window.location.reload()
    }

    function setCcOutputDir (e) {
        setLocalStorageItem('ccOutputDir', e.target.value)
    }

    function setInfoInCode (e) {
        setLocalStorageItem('hideTokenInCode', e.toString())
    }

    function getInfoInCode (e) {
        return (getLocalStorageItem('hideTokenInCode') === 'true')
    }

    return (
        <div className="adobe-skycity-header-icon-div">
            <ModalTrigger>
                <Button variant="tool" icon={<Settings size="M"/>} aria-label="Settings" />
                <Dialog
                    title="Preferences"
                    confirmLabel="Save"
                    cancelLabel="Cancel"
                    keyboardConfirm
                    onConfirm={ e => save(e) } >
                    <div className="adobe-skycity-flex-column">
                        <div>
                            <Checkbox label="Use CC files/ directory for output" disabled/>
                            <Textfield defaultValue={ getLocalStorageItem('ccOutputDir') } onBlur={ e => setCcOutputDir(e) } disabled/>
                        </div>
                        <div>
                            <Checkbox label="Hide apiKey and token in the code" onChange={ e => setInfoInCode(e) } defaultChecked={ getInfoInCode() }/>
                        </div>
                    </div>
                    <br />
                    <br />
                    <Button variant="warning" onClick={ e => resetPreferences(e) } label="Reset All to Default"/>
                </Dialog>
            </ModalTrigger>
        </div>
    )
}
