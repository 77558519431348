// const AWS = require('aws-sdk')

import {
    getTempDirectory
} from '../../lib/skycity.js'

export async function getInputObj (input) {
    const storage = getStorage(input.storage)
    return {
        href: await getSignedUrl(storage, input.href),
        storage: storage
    }
}

export async function prepareBodyForExternal (options) {
    // TODO: check options.body.options.layers

    if (options.body.inputs) {
        options.body.inputs = [await getInputObj(options.body.inputs[0])]
    }
    if (options.body.outputs) {
        options.body.outputs = options.body.outputs.map(output => {
            if (output.storage === 'adobe') {
                output.href = `${getTempDirectory()}/${output.href}`
            }
            return output
        })
    }
    if (options.body.output) {
        options.body.output.href = `${getTempDirectory()}/${options.body.output.href}`
    }
    return options
}

export async function getSignedUrl (storage, input) {
    // TODO: if external, use aws piblic url
    switch (storage) {
    case 'external':
    case 'adobe':
        return input
    case 'aws':
        return `${process.env.REACT_APP_CONFIG_URL_SKYCITY_ASSET}/${input}`
    default:
        return input
    }

    // AWS.config.region = process.env.REACT_APP_CONFIG_TEST_AWS_REGION
    // AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    //     IdentityPoolId: process.env.REACT_APP_CONFIG_TEST_AWS_IDENTITYPOOLID
    // })
    // const s3 = new AWS.S3({
    //     apiVersion: '2006-03-01',
    //     params: { Bucket: process.env.REACT_APP_CONFIG_TEST_AWS_S3_BUCKET }
    // })

    // const signedUrl = new Promise((resolve, reject) => {
    //     const params = {
    //         Bucket: process.env.REACT_APP_CONFIG_TEST_AWS_S3_BUCKET,
    //         Key: input,
    //         Expires: 24 * 60 * 60
    //     }
    //     s3.getSignedUrl('getObject', params, (err, url) => {
    //         if (!err && url) {
    //             resolve(url)
    //         } else {
    //             reject(err)
    //         }
    //     })
    // })
    // const result = await signedUrl
    // return result
}

function getStorage (storage) {
    switch (storage) {
    case 'adobe':
        return 'adobe'
    default:
        return 'external'
    }
}
