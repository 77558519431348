const Desc = `imagecutout: aws jpg, soft. ${new Error('To get file path').stack.split('\n')[1].match(/Object(.+)\(https/)[1] || ''}`

const Payload = {
    input: {
        href: 'https://skycity-asset.adobedice.com/testing/pits/input/ico_portrait.jpg',
        storage: 'external'
    },
    output: {
        storage: 'adobe',
        type: 'image/png',
        overwrite: true,
        width: 300,
        compression: 'large',
        mask: {
            format: 'soft'
        }
    }
}

module.exports = { Payload, Desc }
