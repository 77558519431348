import React, { useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import Select from '@react/react-spectrum/Select'
import { error } from '@react/react-spectrum/Toast'

export default function LayerType ({
    layer
}) {
    const {
        page,
        reflowTaggings,
        layerMappings,
        dispatch
    } = useContext(SkycityContext)

    function setMapping (value, layer) {
        // ex:
        // let mappings = {"Border":[660],"Logo1":[320],"Headline1":[197],"Image1":[846]};

        if (value in layerMappings) {
            if (layerMappings[value].find(id => id === layer.id)) {
                return
            }
        }
        layerMappings[value] = []
        layerMappings[value].push(layer.id)
        dispatch({ type: 'set-layerMappings', payload: { mappings: layerMappings } })
    }

    function getDisabled () {
        if (page === 'ssgeneration') {
            return true
        }
        return !layer.visible || layer.mapDisabled
    }

    try {
        // if (!layer.mapping) {
        //     layer.mapping = reflowTaggings.find( tag => tag.label === layer.name ).label;
        // }

        return <div className="">
            <Select
                options={reflowTaggings}
                defaultValue={layer.name}
                onChange={ value => setMapping(value, layer)}
                disabled={ getDisabled() } />
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
