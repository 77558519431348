const ccParamFiles = [
    'ccParam01.js'
]

const awsParamFiles = [
    'awsParam01.js'
]

export function setParams (storage) {
    const paramFile = (storage === 'cc') ? ccParamFiles : awsParamFiles
    const params = paramFile.map(file => {
        const { Payload, Desc } = require(`./${file}`)
        return {
            payload: Payload,
            desc: Desc
        }
    })
    return params
}
