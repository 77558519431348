import {
    getHeaders,
    getStorage,
    getOptionsOutput,
    getActionFileToPlay,
    getInputFileFont
} from '../../lib/skycity.js'

import { getParams } from '../params/psPhotoshopActions.param.js'
import { prepareBodyForExternal } from './testHelper.js'

export async function getConfig (curFile, outputOptions, iccProfileOption, inputSecondFiles, psActionFile, inputFontFiles) {
    const payload = await getCodePayload(curFile, outputOptions, iccProfileOption, inputSecondFiles, psActionFile, inputFontFiles)
    const config = {
        url: `${process.env.REACT_APP_CONFIG_ENDPOINT_PIE}/psdService/photoshopActions`,
        method: 'POST',
        timeout: 180000,
        // timeout: 5000,
        headers: getHeaders(),
        payload: payload,
        body: JSON.stringify(payload)
    }
    return config
}

export async function getConfigLinux (curFile, outputOptions, iccProfileOption, inputSecondFiles, psActionFile, inputFontFiles) {
    const payload = await getCodePayload(curFile, outputOptions, iccProfileOption, inputSecondFiles, psActionFile, inputFontFiles)
    const config = {
        url: `${process.env.REACT_APP_CONFIG_ENDPOINT_PIE}/psdService/photoshopActionsLinux`,
        method: 'POST',
        timeout: 180000,
        // timeout: 5000,
        headers: getHeaders(),
        payload: payload,
        body: JSON.stringify(payload)
    }
    return config
}

async function getCodePayload (curFile, outputOptions, iccProfileOption, inputSecondFiles, psActionFile, inputFontFiles) {
    const codePayload = {
        inputs: [
            {
                href: curFile.path || curFile.href,
                storage: getStorage(curFile)
            }
        ],
        options: {
            actions: getActionFileToPlay(inputSecondFiles, psActionFile)
        },
        outputs: await getOptionsOutput(curFile, outputOptions, iccProfileOption)
    }
    if (inputFontFiles && inputFontFiles.length > 0) {
        codePayload.options.fonts = getInputFileFont(inputFontFiles)
    }
    return codePayload
}

// ************************************************************************
// Test Cases
// ************************************************************************
export async function getTestCases (storages) {
    const testCases = await getParams(storages)
    const newTestCases = await Promise.all(testCases.map(async (testCase) => {
        testCase = await prepareBodyForExternal(testCase)
        testCase.area = 'Photoshop API'
        testCase.feature = 'psActions'
        return testCase
    }))
    return newTestCases
}
