import React, { useContext } from 'react'

import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import { error } from '@react/react-spectrum/Toast'
import { TreeView } from '@react/react-spectrum/TreeView'
import Wait from '@react/react-spectrum/Wait'
import Tooltip from '@react/react-spectrum/Tooltip'
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger'
import Layers from '@react/react-spectrum/Icon/Layers'
import Heading from '@react/react-spectrum/Heading'

import LayerType from '../Shared/LayerType'
import LayerTag from '../Shared/LayerTag'
import LayerVisibility from '../Shared/LayerVisibility'
import LayerLock from '../Shared/LayerLock'

export default function ReflowLayerInfo () {
    const {
        wait,
        dispatch,
        inputFiles,
        selectedInputFile,
        showLayerInfoPane,
        layerDs
    } = useContext(SkycityContext)

    function renderLayerInfo (layer) {
        if (layer) {
            return <OverlayTrigger placement="right">
                <div className="sc-main-tag-layer-component-contents">
                    <div className="sc-main-tag-layer-component-content">
                        <LayerVisibility layer={layer} />
                        <LayerType layer={layer} />
                        <div>{layer.name}</div>
                    </div>
                    <div className="sc-main-tag-layer-component-content">
                        <LayerTag layer={layer} />
                        <LayerLock layer={layer} />
                    </div>
                </div>
                <Tooltip variant="info">{__('layerinfo_tooltip_id')} {layer.id}</Tooltip>
            </OverlayTrigger>
        } else {
            return <div>{__('layer_error_layer_not_found')}</div>
        }
    }

    function onLayerSelectionChange (layers) {
        dispatch({ type: 'set-selected-layers', payload: { layers: layers } })
    }

    function toggleView () {
        dispatch({ type: 'toggle-layer-info-pane', payload: { showLayerInfoPane: !showLayerInfoPane } })
    }

    try {
        if (wait) {
            return <center><Wait size="S" /></center>
        } else if (selectedInputFile && selectedInputFile.documentManifest && selectedInputFile.documentManifest.outputs && selectedInputFile.documentManifest.outputs[0].layers) {
            return <React.Fragment>
                <div className="adobe-skycity-icon-button" onClick={ e => toggleView(e) }>
                    <Layers size="S" />
                    <Heading size={5} id="treeview-heading-id" className={showLayerInfoPane ? 'adobe-skycity-reflow-layer-info-wrapper-show' : 'adobe-skycity-layer-info-wrapper-hide'}>{__('layer_info_title')}</Heading>
                </div>
                <div className={showLayerInfoPane ? 'adobe-skycity-reflow-layer-info-wrapper-show' : 'adobe-skycity-layer-info-wrapper-hide'}>
                    {inputFiles && inputFiles.length !== 0 && <div className={showLayerInfoPane ? 'adobe-skycity-reflow-layer-info-wrapper-show' : 'adobe-skycity-layer-info-wrapper-hide'}>
                        <TreeView
                            aria-labelledby="gridview-heading-id"
                            className="ps-cep-reflow-treeview"
                            dataSource={ layerDs }
                            renderItem={ renderLayerInfo }
                            onSelectionChange={ selectedItems => onLayerSelectionChange(selectedItems)}
                            allowsSelection
                            allowsMultipleSelection />
                    </div>}
                </div>
            </React.Fragment>
        } else {
            return <div>{__('layer_error_layerinfo_not_found')}</div>
        }
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
        return <div>{__('error_msg_title')}</div>
    }
}
