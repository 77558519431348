import React, { useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import Heading from '@react/react-spectrum/Heading'
import Well from '@react/react-spectrum/Well'
import { error } from '@react/react-spectrum/Toast'

import FileFormatOption from './FileFormatOption'
import IccProfileOption from './IccProfileOption'

import { findCommonType } from '../../lib/skycity.js'

export default function OutputOptions (props) {
    const {
        page,
        outputOptions,
        outputOptionsLr,
        dispatch
    } = useContext(SkycityContext)

    function isLrFeature () {
        const regex = /^lr/gi
        if (page.match(regex)) {
            return true
        } else {
            return false
        }
    }

    function isProductCrop () {
        const regex = /^psproductcrop/gi
        if (page.match(regex)) {
            return true
        } else {
            return false
        }
    }

    function isSocialMediaRendition () {
        const regex = /^pssocialmediarendition/gi
        if (page.match(regex)) {
            return true
        } else {
            return false
        }
    }

    try {
        if (isLrFeature()) {
            if (outputOptionsLr && outputOptionsLr.length === 1 && !findCommonType(outputOptionsLr, props.imageTypes)) {
                dispatch({ type: 'set-outputOptions-lr', payload: { outputOptions: [] } })
            }
        } else {
            if (outputOptions && outputOptions.length === 1 && !findCommonType(outputOptions, props.imageTypes)) {
                dispatch({ type: 'set-outputOptions', payload: { outputOptions: [] } })
            }
        }
        return <div className="adobe-skycity-output-options">
            <Well>
                <Heading variant="pageTitle">{__('skycity_comp_title_output_options')}</Heading>
                <FileFormatOption imageTypes={props.imageTypes} isLrFeature={isLrFeature()}/>
                {isLrFeature() || isProductCrop() || isSocialMediaRendition() ? null : <IccProfileOption />}
            </Well>
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
