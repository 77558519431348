import React from 'react'
import { __ } from 'i18n-for-browser'
import '../config/i18n'

import '../style/App.css'
import '../style/CcBrowser.css'
import '../style/PsEdit.css'
import '../style/OutputOptions.css'
import '../style/Spectrum.css'
import '../style/LayerProperty.css'

import Header from './Shared/Header'
import Main from './Shared/Main'

import { Toast } from '@react/react-spectrum/Toast'

export default function App () {
    if (navigator.onLine) {
        return <div className="adobe-skycity-app">
            <Header />
            <Main />
        </div>
    } else {
        return (
            <div className="adobe-skycity-app">
                <Header />
                <Toast variant="error">{__('error_offline')}</Toast>
            </div>
        )
    }
}
