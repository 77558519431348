import React, { useContext, useState } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'
import SenseiParams from './SenseiParams'

import { error } from '@react/react-spectrum/Toast'
import Button from '@react/react-spectrum/Button'

import { ssApiCall, getSenseiStatus } from '../../lib/ccApi.js'
import {
    getAssetUrn,
    getFilename,
    getFilenameWithoutExt,
    getEncodedURI,
    getTempDirectory,
    getSenseiHeaders
} from '../../lib/skycity.js'

import axios from 'axios'

const uuid = require('uuid/v4')

export default function Operation (files) {
    const {
        inputFiles,
        inputSecondFiles,
        errorOptions,
        dispatch
    } = useContext(SkycityContext)

    const [params, setParams] = useState({
        human_model: false,
        shadow_brightness: 0.5,
        x: 0,
        y: 1.0,
        radius: 0.5
    })

    function formatParams (rawParams) {
        const human_model = rawParams.human_model
        const shadow_brightness = Number.parseFloat(rawParams.shadow_brightness)
        return {
            human_model: human_model,
            shadow_brightness: shadow_brightness
        }
    }

    function formatInputs (rawParams) {
        const x = Number.parseFloat(rawParams.x)
        const y = Number.parseFloat(rawParams.y)
        const radius = Number.parseFloat(rawParams.radius)
        return {
            x: x,
            y: y,
            radius: radius
        }
    }

    async function start (curFile, curSecondFile) {
        const formattedParams = formatParams(params)
        const inputLightMap = formatInputs(params)
        // replaced with RAPI
        const tempURN = curFile.tempUrn
        const curAssetURN = curFile.curFileUrn
        const secondAssetURN = curSecondFile.curFileUrn
        const renditionPath = curFile.renditionPath
        const outputFileName = `shadowgenerator_${uuid()}_${curFile.shortName}`

        const codePayload = {
            contentAnalyzerRequests: {
                'sensei:name': 'Shadow Generator',
                'sensei:invocation_mode': 'asynchronous',
                'sensei:invocation_batch': false,
                'sensei:in_response': false,
                'sensei:engines': [
                    {
                        'sensei:execution_info': {
                            'sensei:engine': 'Feature:autocrop:Service-ebcfd459af3c41a5bc63905feb720f08'
                        },
                        'sensei:params': formattedParams,
                        'sensei:inputs': {
                            mask_in: {
                                'dc:format': checkFileType(curFile.type),
                                'sensei:repoType': 'RAPI',
                                'repo:id': curAssetURN
                            },
                            light_map_in: inputLightMap
                        },
                        'sensei:outputs': {
                            shadow_out: {
                                'repo:id': tempURN,
                                'dc:format': 'image/png',
                                'sensei:repoType': 'RAPI',
                                'repo:resource': {
                                    reltype: 'http://ns.adobe.com/adobecloud/rel/create',
                                    path: `${outputFileName}_shadow.png`
                                }
                            },
                            metadata_out: {
                                'repo:id': tempURN,
                                'dc:format': 'application/json',
                                'sensei:repoType': 'RAPI',
                                'repo:resource': {
                                    reltype: 'http://ns.adobe.com/adobecloud/rel/create',
                                    path: `${outputFileName}.json`
                                }
                            }
                        }
                    }
                ]
            }
        }

        if (secondAssetURN) {
            codePayload.contentAnalyzerRequests['sensei:engines'][0]['sensei:inputs'].image_in = {
                'dc:format': checkFileType(curSecondFile.type),
                'sensei:repoType': 'RAPI',
                'repo:id': secondAssetURN
            }
        }
        if (secondAssetURN) {
            codePayload.contentAnalyzerRequests['sensei:engines'][0]['sensei:outputs'].image_out = {
                'repo:id': tempURN,
                'dc:format': 'image/png',
                'sensei:repoType': 'RAPI',
                'repo:resource': {
                    reltype: 'http://ns.adobe.com/adobecloud/rel/create',
                    path: `${outputFileName}_image.png`
                }
            }
        }

        const formData = new FormData()
        formData.append('contentAnalyzerRequests', JSON.stringify(codePayload.contentAnalyzerRequests))
        const config = {
            file: curFile,
            url: `${process.env.REACT_APP_CONFIG_ENDPOINT_MLSENSEI}/predict`,
            method: 'POST',
            mode: 'cors',
            timeout: 90000,
            headers: getSenseiHeaders(),
            payload: codePayload,
            body: {
                formData: formData
            }
        }

        /* *********************************** */
        dispatch({ type: 'set-codes', payload: { config: config } })

        const response = await ssApiCall(config)
        if (response && response.status < 400) {
            const senseiStatusResponse = await getSenseiStatus(response.getResponseHeader('location'))
            if (senseiStatusResponse && senseiStatusResponse.status === '200') {
                const outputHref = `${getTempDirectory()}/${outputFileName}_shadow.png`

                const response = { ...senseiStatusResponse }

                files.push({
                    inputFile: curFile,
                    name: getFilename(outputHref),
                    shortName: getFilenameWithoutExt(outputHref),
                    path: outputHref,
                    outputThumbnail: `${renditionPath}/${getEncodedURI(outputHref)};size=300?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`,
                    output: `${renditionPath}/${getEncodedURI(outputHref)}?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`,
                    outputToDownload: `${curFile.url}/${getEncodedURI(outputHref)}?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`
                })

                if (secondAssetURN) {
                    const outputHref2 = `${getTempDirectory()}/${outputFileName}_image.png`
                    files.push({
                        secondInput: curSecondFile,
                        name: getFilename(outputHref2),
                        shortName: getFilenameWithoutExt(outputHref2),
                        path: outputHref2,
                        outputThumbnail: `${renditionPath}/${getEncodedURI(outputHref2)};size=300?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`,
                        output: `${renditionPath}/${getEncodedURI(outputHref2)}?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`,
                        outputToDownload: `${curSecondFile.url}/${getEncodedURI(outputHref2)}?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`
                    })
                }

                dispatch({
                    type: 'sensei-success',
                    payload: {
                        files: files,
                        response: response
                    }
                })
            } else {
                const errMsg = senseiStatusResponse.errors ? `${JSON.stringify(senseiStatusResponse.errors, null, 2)}` : `ERROR with ${config.url}`
                errorOccured(`${JSON.stringify(curFile)} - ${errMsg}`, senseiStatusResponse)
            }
        } else {
            const errMsg = response ? `${JSON.stringify(response.response, null, 2)}` : `ERROR with ${config.url}`
            errorOccured(`${errMsg}`, response)
        }
    }

    function run (e) {
        try {
            dispatch({ type: 'skycity-run', payload: {} })

            files = []
            const funcArray = []

            inputFiles.forEach(inputFile => {
                if (inputSecondFiles.length > 0) {
                    inputSecondFiles.forEach(inputSecondFile => {
                        funcArray.push(start(inputFile, inputSecondFile))
                    })
                } else {
                    funcArray.push(start(inputFile, null))
                }
            })
            console.log('number of functions: ' + funcArray.length)

            axios.all(funcArray)
                .then((res) => {
                    // then
                })
                .catch((err) => {
                    console.log(`err: ${err}`)
                    const errMsg = `ERROR: ${JSON.stringify(err)}`
                    errorOccured(errMsg)
                })
                .finally(() => {
                    console.log('finally...')
                    dispatch({
                        type: 'sensei-end-wait'
                    })
                })
        } catch (e) {
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        }
    }

    function isRunDisabled () {
        if (inputFiles && inputFiles.length > 0) {
            return false
        }
        return true
    }

    function checkFileType (type) {
        if (type.toLowerCase() === 'jpg') {
            type = 'jpeg'
        }
        return type.startsWith('image/') ? type : `image/${type}`
    }

    function errorOccured (errMsg, res) {
        error(errMsg.slice(0, errorOptions.wordSlice), {
            onClose: () => console.log('close'),
            timeout: errorOptions.msgTimeout
        })
        dispatch({ type: 'error-occured', payload: { errMsg: errMsg, response: res, loading: false, wait: false } })
    }

    try {
        return <div>
            <SenseiParams params={params} setParams={setParams} />
            <div className="adobe-skycity-button-right">
                <Button variant="cta" onClick={e => run(e)} disabled={isRunDisabled()}>{__('btn_run')}</Button>
            </div>
        </div>
    } catch (e) {
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
