const Desc = `smartObjectV2: same instance, aws. ${new Error('To get file path').stack.split('\n')[1].match(/Object(.+)\(https/)[1] || ''}`

const Payload = {
    inputs: [
        {
            href: 'https://skycity-asset.adobedice.com/testing/pits/input/4so.psd',
            storage: 'external'
        }
    ],
    options: {
        layers: [
            {
                id: 2,
                input: {
                    href: 'https://as2.ftcdn.net/jpg/02/16/53/79/500_F_216537946_ODYbV4mHvMYtcpS6zUQ1nilKBkHnaazp.jpg',
                    storage: 'external'
                }
            }
        ]
    },
    outputs: [
        {
            storage: 'adobe',
            type: 'image/png',
            overwrite: true,
            width: 300,
            compression: 'small'
        }
    ]
}

module.exports = { Payload, Desc }
