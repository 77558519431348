import React, { useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import Checkbox from '@react/react-spectrum/Checkbox'
import Heading from '@react/react-spectrum/Heading'
import Well from '@react/react-spectrum/Well'
import Select from '@react/react-spectrum/Select'
import FieldLabel from '@react/react-spectrum/FieldLabel'
import { error } from '@react/react-spectrum/Toast'

export default function LocaleOptions (props) {
    // const stepNum = props.stepNum ? `${props.stepNum} ` : '';
    const {
        locales,
        dispatch
    } = useContext(SkycityContext)

    function changeSelection (e, curLocale) {
        const newLocales = locales.map(locale => {
            if (locale.locale === curLocale.locale) {
                locale.selected = e
            }
            return locale
        })
        dispatch({ type: 'set-locales', payload: { localeOptions: newLocales } })
    }

    function getLocaleOptions () {
        const localesOptionsComp = locales.map(locale => {
            // return <div>{locale.language}</div>;
            return <Checkbox value={locale.selected}
                defaultChecked={locale.selected}
                key={locale.locale}
                id={locale.locale}
                onChange={ e => changeSelection(e, locale)}>{locale.language}</Checkbox>
        })
        return <div id="localTo">{localesOptionsComp}</div>
    }

    function changeLocaleFrom (e) {
        dispatch({ type: 'set-locFrom', payload: { locale: e } })
    }

    try {
        return <div className="adobe-skycity-output-options">
            <Well>
                <Heading variant="pageTitle">{__('skycity_comp_title_locale_options')}</Heading>
                <br />
                <div className="adobe-skycity-output-options-file-format">
                    <FieldLabel label="Translate From: " labelFor="localFrom" />
                    <Select
                        id="localFrom"
                        options={locales}
                        onChange={ e => changeLocaleFrom(e) }
                        required />
                </div>
                <br />
                <div className="adobe-skycity-output-options-file-format">
                    <FieldLabel label="Translate To: " labelFor="localTo" />
                    {getLocaleOptions()}
                </div>
            </Well>
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
