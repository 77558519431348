import React, { useContext } from 'react'

import ShowMenu from '@react/react-spectrum/Icon/ShowMenu'

import { SkycityContext } from '../../lib/SkycityProvider'
import SkycityNav from './SkycityNav'
import AppInfo from '../Shared/AppInfo'

export default function Nav () {
    const { page, showNavPane, dispatch } = useContext(SkycityContext)
    const isHome = (page === 'home')

    function toggleView () {
        dispatch({ type: 'toggle-nav-pane', payload: { showNavPane: !showNavPane } })
    }
    return (
        <div className={isHome ? 'adobe-skycity-nav-wrapper-home' : 'adobe-skycity-nav-wrapper'}>
            <div className="adobe-skycity-icon-button" onClick={ e => toggleView(e) }>
                <ShowMenu size="S" />
            </div>
            <div className={showNavPane ? 'adobe-skycity-nav-wrapper-show' : 'adobe-skycity-nav-wrapper-hide'}>
                <div className="adobe-skycity-app-nav">
                    <SkycityNav className="adobe-skycity-app-nav" />
                </div>
                <AppInfo className="adobe-skycity-app-info" />
            </div>
        </div>
    )
}
