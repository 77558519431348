import React from 'react'
import CreativeCloud from '@react/react-spectrum/Icon/CreativeCloud'

export default function CreativeCloudLink ({ className }) {
    function goToCC () {
        window.open(process.env.REACT_APP_CONFIG_URL_CCASSET_FILES)
    }

    return (
        <div className="adobe-skycity-header-icon-div" onClick={ e => goToCC(e) }>
            <CreativeCloud className="adobe-skycity-header-icon" size="M"/>
        </div>
    )
}
