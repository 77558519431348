import React, { useContext, useState } from 'react'
import useFetch from '../../hooks/useFetch'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'

import SyntaxHighlighter from 'react-syntax-highlighter'
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs'

import Button from '@react/react-spectrum/Button'
import Pagination from '@react/react-spectrum/Pagination'
import { success } from '@react/react-spectrum/Toast'

import { SkycityContext } from '../../lib/SkycityProvider'

import { downloadFile, copyText, getCodeLineToken, getCodeLineApiKey } from '../../lib/skycity.js'

export default function CodeSnippetCPFEngine ({
    language,
    outputs,
    style = atomOneDark,
    lineNumberStyle = { color: 'rgba(112, 112, 112, 0.8)' },
    showLineNumbers = true
}) {
    const {
        codes,
        inputFiles
    } = useContext(SkycityContext)

    const [currentPage, setPage] = useState(1)
    let textFile = null
    const url = createCode(codes)
    const text = useFetch({ url, defaultValue: '', json: false })

    function getAsyncCurl () {
        let text = 'read STATUS_URL < <( curl --location --request $method -H "Authorization: Bearer $token" -H "Content-Type:multipart/form-data" -H "x-api-key: $apiKey" -H "x-analyzer-id: h" --form "$payload" "$endpoint" -i  | awk \'/^location/ { STATUS = $2 } END { printf("%s", STATUS) }\')\n'
        text += 'STATUS_URL=${STATUS_URL%?}\n'
        text += 'echo "Status URL: $STATUS_URL"\n\n'
        text += '# ************************************\n'
        text += '# Check Status\n'
        text += '# ************************************\n'
        text += 'while [ "x$jobstatus" != "x200" ] && [ "x$jobstatus" != "x500" ]; do\n'
        text += '\tread jobstatus < <( curl -Is -H "Authorization: Bearer $token" -H "Content-Type:application/json" -H "x-api-key: $apiKey" -X GET $STATUS_URL | awk \'/^HTTP/ { jobstatus = $2 } END { printf("%s", jobstatus) }\')\n'
        text += '\toutput=$(curl -Ss -H "Authorization: Bearer $token" -H "Content-Type:application/json" -H "x-api-key: $apiKey" -X GET "$STATUS_URL" | jq -r \'.statuses[0].invocations[0]\')\n'
        text += '\techo "JOBSTATUS: $jobstatus"\n'
        text += 'done\n\n'
        text += '# ************************************\n'
        text += '# Result\n'
        text += '# ************************************\n'
        text += 'echo "************************************"\n'
        text += 'echo "RESULT"\n'
        text += 'echo ""\n'
        text += 'echo $output | jq\n'
        text += 'echo "************************************"\n'

        return text
    }

    function getSyncCurl () {
        let text = 'curl --location --request $method -H "Authorization: Bearer $token" -H "Content-Type:multipart/form-data" -H "x-api-key: $apiKey" -H "Prefer: respond-sync, wait=5" -H "x-analyzer-id: h" --form "$payload" "$endpoint" -i > out.txt\n'
        text += 'echo "Response output saved to file `pwd`/out.txt"'
        return text
    }

    function getPollingCode () {
        return text
    }

    // text += `\n`;
    function createCode (codes) {
        if (codes && codes.length > 0) {
            // Update output file names since RAPI doesn't support overwrite; adding a randomized variable in the script
            const contentRequest = codes[currentPage - 1].codePayload.contentAnalyzerRequests

            // Check if the request is synchrounous or asynchrous
            const is_synchronous = contentRequest['sensei:invocation_mode'] === 'synchronous'

            // Update random Reference output path if it's async call
            if (!is_synchronous) {
                for (let i = 0; i < outputs.length; i++) {
                    // Check if outputs[i] is empty or not
                    if (contentRequest['sensei:engines'][0]['sensei:outputs'][outputs[i]]) {
                        const path = contentRequest['sensei:engines'][0]['sensei:outputs'][outputs[i]]['repo:resource'].path
                        contentRequest['sensei:engines'][0]['sensei:outputs'][outputs[i]]['repo:resource'].path =
                            path.startsWith('$randomInt_')
                                ? path : '$randomInt_' + path
                    }
                }
            }

            let text = '#!/bin/bash\n\n'
            text += '# ************************************\n'
            text += '# Check if jq is installed\n'
            text += '# ************************************\n'
            text += 'if [ ! `which jq` ]; then\n'
            text += '\techo "************************************"\n'
            text += '\techo "Please install jq: brew install jq"\n'
            text += '\techo "************************************"\n'
            text += '\texit 1\n'
            text += 'fi\n\n'
            text += '# ************************************\n'
            text += '# Set variables\n'
            text += '# ************************************\n'
            text += '# set -x\n'
            text += `${getCodeLineToken()}`
            text += `${getCodeLineApiKey(process.env.REACT_APP_CONFIG_APP_KEY)}`
            text += `endpoint='${codes[currentPage - 1].codeEndpoint}'\n`
            text += `method='${codes[currentPage - 1].codeMethod}'\n\n`
            text += '# Please update the output path of image_out\n'
            text += `payload='contentAnalyzerRequests=${JSON.stringify(contentRequest, null, 2)}'\n\n`
            text += 'payload="${payload//\\$randomInt/$RANDOM}"\n\n'
            text += '# ************************************\n'
            text += '# Call API\n'
            text += '# ************************************\n'
            text += is_synchronous ? getSyncCurl() : getAsyncCurl()

            var data = new Blob([text], { type: 'text/plain' })
            if (textFile !== null) {
                window.URL.revokeObjectURL(textFile)
            }
            textFile = window.URL.createObjectURL(data)
            return textFile
        } else {
            return ''
        }
    }

    function onPageInputChange (value) {
        // console.log(value);
        setPage(value)
    }

    function getText () {
        const newLine = copyText(text)
        success('The code is copied.', {
            actionLabel: 'Log in console',
            onAction: (e) => console.log(newLine),
            timeout: 5000
        })
    }

    if (inputFiles && inputFiles.length > 0 && codes && codes.length > 0) {
        return <React.Fragment>
            <center>
                <div className="adobe-skycity-code-pagination">
                    <Pagination
                        variant="explicit"
                        mode="primary"
                        onChange={onPageInputChange}
                        onNext={onPageInputChange}
                        onPrevious={onPageInputChange}
                        totalPages={codes.length}
                        defaultPage={currentPage} />
                </div>
            </center>
            <div className="adobe-skycity-code-preview">
                <SyntaxHighlighter lineNumberStyle={lineNumberStyle} showLineNumbers={showLineNumbers} language={language} style={style}>
                    {text}
                </SyntaxHighlighter>
            </div>
            <div className="adobe-skycity-button-right">
                <Button variant="cta" onClick={e => getText(e)} disabled={text === ''}>{__('btn_api_copy')}</Button>
                <Button variant="cta" onClick={e => downloadFile(textFile, __('batch_filename'))} disabled={text === ''}>{__('btn_api_download')}</Button>
            </div>
        </React.Fragment>
    } else {
        return null
    }
}
