const Desc = `documentOperations: insert png image from cc. ${new Error('To get file path').stack.split('\n')[1].match(/Object(.+)\(https/)[1] || ''}`

const Payload = {
    inputs: [
        {
            href: 'https://skycity-asset.adobedice.com/testing/pits/input/adobe1.png',
            storage: 'external'
        }
    ],
    options: {
        layers: [
            {
                add: {
                    insertTop: true
                },
                type: 'smartObject',
                input: {
                    href: 'https://skycity-asset.adobedice.com/testing/pits/input/adobelogo_red.png',
                    storage: 'external'
                }
            }
        ]
    },
    outputs: [
        {
            storage: 'adobe',
            type: 'image/png',
            overwrite: true,
            width: 500,
            compression: 'large'
        }
    ]
}
module.exports = { Payload, Desc }
