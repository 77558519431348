import React, { createContext, useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import { TableView } from '@react/react-spectrum/TableView'
import Well from '@react/react-spectrum/Well'
import Button from '@react/react-spectrum/Button'
import Textfield from '@react/react-spectrum/Textfield'
import Heading from '@react/react-spectrum/Heading'

export default function TextLayerTable () {
    const {
        dispatch,
        txtLayers,
        txtLayersDs
    } = useContext(SkycityContext)

    const LayerInfoContext = createContext()
    const TextLayerTableProvider = ({ children }) => {
        return (
            <LayerInfoContext.Provider>
                {children}
            </LayerInfoContext.Provider>
        )
    }

    function changeValue (e, data, locale) {
        e.preventDefault()
        txtLayers.find(layer => layer.id === data.id).locales.find(curLocale => curLocale.locale === locale).content = e.target.value
        dispatch({
            type: 'set-text-layer-table',
            payload: {
                txtLayers: txtLayers
            }
        })
    }

    function getContentCell (data, curLocale) {
        if (data.locales) {
            return data.locales.find(locale => locale.locale === curLocale).content
        }
        return ''
    }

    function renderCell (column, data) {
        switch (column.key) {
        case 'locale':
            return <span><Textfield
                defaultValue={ getContentCell(data, column.locale) }
                onBlur={ e => changeValue(e, data, column.locale)} /></span>
        default:
            return <span>{data[column.key]}</span>
        }
    }

    function isSaveDisabled () {
        return !(txtLayersDs && txtLayersDs.data && txtLayersDs.data.length > 0)
    }

    function save () {
        const text = JSON.stringify(txtLayers, null, 4)
        const blob = new Blob([text], { type: 'text/plain' })
        const anchor = document.createElement('a')

        anchor.download = 'loc.json'
        anchor.href = (window.webkitURL || window.URL).createObjectURL(blob)
        anchor.dataset.downloadurl = ['text/plain', anchor.download, anchor.href].join(':')
        anchor.click()
    }

    function LayerApp () {
        const columns = [
            {
                title: 'id',
                key: 'id',
                width: 50,
                sortable: true,
                divider: true
            },
            {
                title: 'Layer Name',
                key: 'name',
                minWidth: 200,
                sortable: true,
                divider: true
            },
            {
                title: 'Text Content',
                key: 'content',
                minWidth: 200,
                sortable: true,
                divider: true
            }
        ]

        txtLayers.forEach(txtLayer => {
            if (txtLayer.locales) {
                txtLayer.locales.forEach(locale => {
                    if (locale.selected) {
                        columns.push({
                            title: locale.language,
                            locale: locale.locale,
                            key: 'locale',
                            minWidth: 230,
                            sortable: true,
                            divider: true
                        })
                    }
                })
            }
        })

        return (
            <TextLayerTableProvider>
                <div className="adobe-skycity-text-layer-table-container">
                    <Well>
                        <Heading variant="pageTitle">{__('skycity_comp_title_text_layer_table')}</Heading>
                        <br />
                        { txtLayersDs && txtLayersDs.data && txtLayersDs.data.length > 0 && <div className="adobe-skycity-text-layer-table">
                            <TableView
                                columns={ columns }
                                dataSource={ txtLayersDs }
                                rowHeight={ 36 }
                                renderCell={ renderCell } /></div>}
                    </Well>
                    <div className="adobe-skycity-flex-row-buttons">
                        <div>
                            <Button variant="cta" onClick={e => save(e, 'csv')} disabled>{__('btn_save_csv')}</Button>
                            <Button variant="cta" onClick={e => save(e, 'xls')} disabled>{__('btn_save_xls')}</Button>
                            <Button variant="cta" onClick={e => save(e, 'json')} disabled={isSaveDisabled()}>{__('btn_save_json')}</Button>
                        </div>
                    </div>
                </div>
            </TextLayerTableProvider>
        )
    }

    return <LayerApp />
}
