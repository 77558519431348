import { useEffect, useState, useContext } from 'react'
import { SkycityContext } from '../lib/SkycityProvider'

export default function useFetch ({ url, defaultValue = {}, json = true }) {
    const { cache, dispatch } = useContext(SkycityContext)
    const cachedUrlContents = cache[url]
    const [data, updateData] = useState(cachedUrlContents || defaultValue)

    useEffect(() => {
        async function _fetch () {
            if (!url || url === '') {
                return
            }

            const resp = await fetch(url)
            let value

            if (json) {
                value = await resp.json()
            } else {
                value = await resp.text()
            }

            // dispatch({type: 'set-cache', payload:{ key: url, value}})
            updateData(value)
        }

        if (cachedUrlContents) {
            updateData(cachedUrlContents)
        } else {
            _fetch()
        }
    }, [url, json, cachedUrlContents, dispatch])

    return data
}
