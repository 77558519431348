import React, { useContext } from 'react'

import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import { FormItem } from '@react/react-spectrum/Form'
import Switch from '@react/react-spectrum/Switch'
import { error } from '@react/react-spectrum/Toast'

import { getFilename, getFilenameWithoutExt, getDocumentOperationsConfig, postDocumentOperations, getPreviewFileDocumentManifest } from '../../lib/skycity.js'

export default function FormLayerLocked (props) {
    const {
        previewFiles,
        errorOptions,
        nonPreviewOptions,
        previewEdit,
        dispatch
    } = useContext(SkycityContext)

    const actionType = 'set-input-preview'
    const previewFileDocumentManifest = getPreviewFileDocumentManifest(previewFiles)

    function errorOccured (errMsg, res) {
        console.log(errMsg)
        error(errMsg.slice(0, errorOptions.wordSlice), {
            onClose: () => console.log('close'),
            timeout: errorOptions.timeout
        })
        dispatch({ type: 'error-occured', payload: { errMsg: errMsg, response: res, loading: false, wait: false } })
    }

    function getSelectedLayers (newDocumentManifest, cb) {
        return newDocumentManifest.forEach(function iter (a) {
            if ([props.curLayer.id].includes(a.id)) {
                cb(a)
            }
        })
    }

    function changeLocked (e, curLayer) {
        const editedLayer = {
            edit: {},
            id: curLayer.id,
            locked: e
        }
        if (!previewEdit) {
            if (nonPreviewOptions.layers) {
                nonPreviewOptions.layers.push(editedLayer)
            } else {
                nonPreviewOptions.layers = [editedLayer]
            }
            return
        }
        dispatch({
            type: 'skycity-psedit-run',
            payload: {
                loading: true,
                wait: true
            }
        })

        getDocumentOperationsConfig(previewFiles[0], { layers: [editedLayer] }, config => {
            if (config) {
                dispatch({ type: 'set-codes', payload: { config } })
                postDocumentOperations(previewFiles[0], previewFileDocumentManifest, config, (res, err, files) => {
                    if (res && !err) {
                        getSelectedLayers(files[0].documentManifest.outputs[0].layers, newLayers => {
                            files[0].name = getFilename(files[0].href)
                            files[0].shortName = getFilenameWithoutExt(files[0].href)
                            dispatch({
                                type: actionType,
                                payload: {
                                    files: files,
                                    loading: false,
                                    wait: false,
                                    response: res,
                                    layers: [newLayers]
                                }
                            })
                        })
                    } else {
                        errorOccured(`${__('browser_cc_get_document_manifest_error')}: ${err}`)
                    }
                })
            }
        })
    }

    return <FormItem label={props.label}>
        <Switch defaultChecked={props.curLayer.locked} onChange={ e => changeLocked(e, props.curLayer)}/>
    </FormItem>
}
