import {
    getHeaders,
    getStorage,
    getOptionsOutput,
    getActionFileToPlay,
    getInputFileFont,
    getFilename,
    getExtensionFromPath,
    getTempDirectory
} from '../../lib/skycity.js'

import { getParams } from '../params/psPhotoshopActions.param.js'
import { getInputObj } from './testHelper.js'

export function getConfig (curFile, outputOptions, iccProfileOption, inputSecondFiles, inputFontFiles) {
    const payload = getCodePayload(curFile, outputOptions, iccProfileOption, inputSecondFiles, inputFontFiles)
    const config = {
        url: `${process.env.REACT_APP_CONFIG_ENDPOINT_PIE}/psdService/photoshopActions`,
        method: 'POST',
        timeout: 180000,
        // timeout: 5000,
        headers: getHeaders(),
        payload: payload,
        body: JSON.stringify(payload)
    }
    return config
}

function getCodePayload (curFile, outputOptions, iccProfileOption, inputSecondFiles, inputFontFiles) {
    const codePayload = {
        inputs: [
            {
                href: curFile.path || curFile.href,
                storage: getStorage(curFile)
            }
        ],
        options: {
            uxp: getActionFileToPlay(inputSecondFiles)
        },
        outputs: getOptionsOutput(curFile, outputOptions, iccProfileOption)
    }
    if (inputFontFiles && inputFontFiles.length > 0) {
        codePayload.options.fonts = getInputFileFont(inputFontFiles)
    }
    return codePayload
}

// ************************************************************************
// Test Cases
// ************************************************************************
export async function getTestCases (storages) {
    const testCases = await getParams(storages)
    const newTestCases = await Promise.all(testCases.map(async (testCase) => {
        const inputFileTypes = testCase.body.inputs.map(input => {
            return getExtensionFromPath(input.href)
        })
        const actionNames = testCase.body.options.actions.map(action => {
            return getFilename(action.href)
        })
        const outputFileTypes = testCase.body.outputs.map(output => {
            return output.format.toUpperCase()
        })
        testCase = await prepareBodyForExternal(testCase)
        testCase.area = 'Photoshop API'
        testCase.feature = 'psActions'
        testCase.desc = `${actionNames} (${inputFileTypes} to ${outputFileTypes})`
        return testCase
    }))
    return newTestCases
}

export async function prepareBodyForExternal (options) {
    options.body.inputs = [await getInputObj(options.body.inputs[0])]
    options.body.options.actions = [await getInputObj(options.body.options.actions[0])]
    options.body.outputs = options.body.outputs.map(output => {
        if (output.storage === 'adobe') {
            output.href = `${getTempDirectory()}/${output.href}`
        }
        return output
    })
    return options
}
