import React from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'

import { error } from '@react/react-spectrum/Toast'

export default function Main (props) {
    try {
        const token = window.adobeIMS.getAccessToken().token
        const psd = props.ccFile.path // "files/_CreativeCloud.psd";
        const env = process.env.REACT_APP_CONFIG_APP_DEV
        const apikey = process.env.REACT_APP_CONFIG_APP_KEY
        const userid = window.adobeIMS.getUserProfile().userId

        return <div className="adobe-skycity-main-pane">
            <div className="adobe-skycity-main">
                <div className="adobe-skycity-main-iframe-div">
                    <div className="adobe-skycity-main-iframe">
                        <iframe title="iframeReflow" id="iframeReflow" src={`${props.url}/?token=${token}&psd=${psd}&env=${env}&apikey=${apikey}&userid=${userid}`} width="100%" height="100%" />
                    </div>
                </div>
            </div>
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
