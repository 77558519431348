/* eslint-disable */
import en_US from '../locales/en_US.json'
// import ja_JP from '../locales/ja_JP.json';

import i18n from 'i18n-for-browser'

i18n.configure({
    locales: {
        en: en_US
    }
})

// i18n.setLocale(window.navigator.language.substring(0,2) || 'en');
i18n.setLocale('en')
