import '@marketingtech/launch-code/dist/main.min.js'

// const env = "%REACT_APP_CONFIG_APP_DEV%";
const env = (window.location.hostname === 'skycity.adobedice.com') ? 'production' : 'stage'

async function setDefaultValue () {
    window.digitalData._set(
        'digitalData.page.pageInfo.siteSection',
        `com.adobedice.skycity:${env}`
    )
    const lang = navigator.language.toLowerCase()
    window.digitalData._set(
        'digitalData.page.pageInfo.language',
        lang
    )
    window.digitalData._set(
        'digitalData.page.pageInfo.legacyMarketSegment',
        `environment:${env}`
    )
    window.digitalData._set(
        'digitalData.page.pageInfo.productName',
        `Adobe Skycity ${env}`
    )
    window.digitalData._set(
        'digitalData.page.pageInfo.productVersion',
        'v.0.4.1'
    )
    if (window.adobeIMS && window.adobeIMS.getProfile) {
        const userProfile = await window.adobeIMS.getProfile()
        const userId = userProfile.userId
        const displayName = userProfile.displayName
        const email = userProfile.email
        window.digitalData._set(
            'digitalData.user.profile.profileInfo.profileID',
            userId || ''
        )
        window.digitalData._set(
            'digitalData.user.profile.profileInfo',
            {
                profileID: userId || '',
                userName: displayName || '',
                email: email || ''
            }
        )
    }
}

function sendPageName (page) {
    setDefaultValue()

    window.digitalData._delete(
        'digitalData.page.pageInfo.pageName'
    )
    window.digitalData._set(
        'digitalData.page.pageInfo.pageName',
        `com.adobedice.skycity:${page}`
    )
    window._satellite.track('state', {
        digitalData: window.digitalData._snapshot()
    })
    return true
}

function sendEventName (type, action) {
    setDefaultValue()

    window.digitalData._delete(
        'digitalData.primaryEvent.eventInfo.eventName'
    )
    window.digitalData._set(
        'digitalData.primaryEvent.eventInfo.eventName',
        `${type} - ${action}`
    )
    window._satellite.track('event', {
        digitalData: window.digitalData._snapshot()
    })
    return true
}

export default { sendPageName, sendEventName }
