import React from 'react'

export default function Profile ({ className }) {
    // if (!window.adobeIMS) {
    //     return null
    // }
    // if (window.adobeIMS.isReady()) {
    //     initProfile()
    // } else {
    //     window.adobeIMS.addEventListener(window.adobeIMS.Events.READY, (event) => {
    //         initProfile()
    //     })
    // }
    // function initProfile () {
    //     if (window.adobeIMS.isSignedInUser() && window.adobeProfile) {
    //         window.adobeProfile.init({
    //             target: document.getElementById('adobe-user'), // example value
    //             locale: 'en'
    //         })
    //     } else {
    //         console.log('network connection error')
    //     }
    // }
    return (
        <div className="adobe-skycity-header-icon-div">
            <div id="adobe-user" className={className}/>
        </div>
    )
}
